import React, { useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, message, Modal } from "antd";
import { ColumnsType } from "antd/lib/table/interface";

import { useTypedDispatch } from "store";
import { IDepartment } from "../../store/departments/departments.reducer";
import { removeDepartment, updateDepartment } from "../../store/departments/departments.reducer";
import { DepartmentsAPI } from "../../api/departments.api";
import { treeDepartments } from "../../utils/treeDepartments";
import TableContainer from "../../containers/TableContainer";
import { SelectDepartmentType } from "../dropdown/SelectDepartmentType";

type DepartmentsTableProps = {
  departments: IDepartment[];
};

const { confirm } = Modal;

const DepartmentsTable: React.FC<DepartmentsTableProps> = ({ departments }) => {
  const dispatch = useTypedDispatch();
  const [changeableName, setChangeableName] = useState<number | undefined>();
  const [changeableCount, setChangeableCount] = useState<number | undefined>();
  const [changeableHead, setChangeableHead] = useState<number | undefined>();
  const [changeableFunction, setChangeableFunction] = useState<number | undefined>();

  const handleDelete = async (id: number) => {
    const isDeleted = await DepartmentsAPI.remove(id);
    isDeleted && dispatch(removeDepartment(id));
  };

  const dropdownMenu = (id: number) => (
    <Menu>
      <Menu.Item key="changeName" onClick={() => setChangeableName(id)}>
        Изменить имя
      </Menu.Item>
      <Menu.Item key="changeEmployees" onClick={() => setChangeableCount(id)}>
        Изменить количество сотрудников
      </Menu.Item>
      <Menu.Item key="changeHead" onClick={() => setChangeableHead(id)}>
        Изменить руководителя
      </Menu.Item>
      <Menu.Item key="changeFunction" onClick={() => setChangeableFunction(id)}>
        Изменить функциональность
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() =>
          confirm({
            title: "Вы уверены, что хотите удалить этот департамент?",
            okText: "Да",
            cancelText: "Нет",
            onOk: () => handleDelete(id),
          })
        }
      >
        Удалить
      </Menu.Item>
    </Menu>
  );

  const handleChangeName = async (id: number, name: string) => {
    try {
      const updatedDepartment = await DepartmentsAPI.update(id, { name });
      if (updatedDepartment) {
        dispatch(updateDepartment(updatedDepartment));
        message.success(`Название департамента успешно изменено`);
      }
    } catch (error) {}
  };

  const handleChangeEmployeeCount = async (id: number, employee_count: number) => {
    try {
      const updatedDepartment = await DepartmentsAPI.update(id, {
        employee_count,
      });
      if (updatedDepartment) {
        dispatch(updateDepartment(updatedDepartment));
        message.success(`Количество сотрудников успешно изменено`);
      }
    } catch (error) {}
  };

  const handleChangeHead = async (id: number, head: string) => {
    try {
      const updatedDepartment = await DepartmentsAPI.update(id, { head });
      if (updatedDepartment) {
        dispatch(updateDepartment(updatedDepartment));
        message.success(`Руководитель департамента успешно изменен`);
      }
    } catch (error) {}
  };

  const handleChangeFunction = async (id: number, func: string) => {
    try {
      const updatedDepartment = await DepartmentsAPI.update(id, { func });
      if (updatedDepartment) {
        dispatch(updateDepartment(updatedDepartment));
        message.success(`Функциональность департамента успешно изменена`);
      }
    } catch (error) {}
  };

  const handleBlurInputName = (name: string, department: IDepartment) => {
    setChangeableName(undefined);
    return department.name !== name && handleChangeName(department.id, name);
  };

  const handleBlurInputCount = (employee_count: number, department: IDepartment) => {
    setChangeableCount(undefined);
    return (
      department.employee_count !== employee_count &&
      handleChangeEmployeeCount(department.id, Number(employee_count))
    );
  };

  const handleBlurInputHead = (head: string, department: IDepartment) => {
    setChangeableHead(undefined);
    return department.head !== head && handleChangeHead(department.id, head);
  };

  const handleBlurInputFunction = (func: string, department: IDepartment) => {
    setChangeableFunction(undefined);
    return department.func !== func && handleChangeFunction(department.id, func);
  };

  const columns: ColumnsType<IDepartment> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Название",
      dataIndex: "name",
      render: (_, department) => {
        return changeableName === department.id ? (
          <Input
            defaultValue={department.name}
            bordered={false}
            onBlur={(e) => handleBlurInputName(e.target.value, department)}
            onPressEnter={(e) => handleBlurInputName(e.currentTarget.value, department)}
          />
        ) : (
          department.name
        );
      },
    },
    {
      title: "Тип",
      dataIndex: "type",
      render: (_, department) => {
        return <SelectDepartmentType department_id={department.id} type={department.type} />;
      },
    },
    {
      title: "Количество сотрудников",
      dataIndex: "employee_count",
      render: (_, department) => {
        return changeableCount === department.id ? (
          <Input
            defaultValue={department.employee_count}
            bordered={false}
            onBlur={(e) => handleBlurInputCount(+e.target.value, department)}
            onPressEnter={(e) => handleBlurInputCount(+e.currentTarget.value, department)}
          />
        ) : (
          department.employee_count
        );
      },
    },
    {
      title: "Руководитель",
      dataIndex: "head",
      render: (_, department) => {
        return changeableHead === department.id ? (
          <Input
            defaultValue={department.head}
            bordered={false}
            onBlur={(e) => handleBlurInputHead(e.target.value, department)}
            onPressEnter={(e) => handleBlurInputHead(e.currentTarget.value, department)}
          />
        ) : (
          department.head
        );
      },
    },
    {
      title: "Функциональность",
      dataIndex: "func",
      render: (_, department) => {
        return changeableFunction === department.id ? (
          <Input
            defaultValue={department.func}
            bordered={false}
            onBlur={(e) => handleBlurInputFunction(e.target.value, department)}
            onPressEnter={(e) => handleBlurInputFunction(e.currentTarget.value, department)}
          />
        ) : (
          department.func
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_, account) => {
        return (
          <Dropdown
            overlay={dropdownMenu(account.id)}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <Button shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <TableContainer
      dataSource={treeDepartments(departments)}
      columns={columns}
      gridTemplateColumns="0.5fr 3fr 1fr 2fr 2fr 1fr 0.5fr"
    />
  );
};

export default DepartmentsTable;

import { Gauge } from "@ant-design/charts";
import React from "react";

type GaugePanelProps = {
  percent: number;
  color: string;
  title: (datum?: any, data?: any) => string;
};

const GaugePanel: React.FC<GaugePanelProps> = ({ percent, color, title }) => {
  const config = {
    height: 200,
    width: 200,
    indicator: undefined,
    startAngle: 0.7 * Math.PI,
    endAngle: 2.3 * Math.PI,
    animation: undefined,
  };

  const percentFormatter = (_ref: any) => {
    const percent = _ref.percent;
    return (percent * 100).toFixed(0) + "%";
  };

  return (
    <Gauge
      range={{ color }}
      percent={percent}
      statistic={{
        content: {
          offsetY: -20,
          formatter: percentFormatter,
          style: {
            color: "#fff",
            backgroundColor: color,
            padding: "8px 15px",
            borderRadius: "8px",
            fontSize: "1rem",
          },
        },
        title: {
          offsetY: -120,
          formatter: title,
        },
      }}
      {...config}
    />
  );
};

export default GaugePanel;

import { ApartmentOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import Tooltip from "antd/lib/tooltip";
import { MyRadioGroup, MyRadioButton } from "../../shared/Filter";

interface IFilterModeSelector {
  handleChange: (event: any) => void;
  defaultValue: string;
}

const FilterModeSelector = ({ handleChange, defaultValue }: IFilterModeSelector) => {
  return (
    <>
      <MyRadioGroup
        onChange={handleChange}
        value={defaultValue}
        optionType="button"
        buttonStyle="solid"
      >
        <Tooltip title="Департаменты" color={"#2c2c2c"}>
          <MyRadioButton value={"department"}>
            <ApartmentOutlined style={{ fontSize: "1rem" }} />
          </MyRadioButton>
        </Tooltip>
        <Tooltip title="Руководители" color={"#2c2c2c"}>
          <MyRadioButton value={"head"}>
            <UserOutlined style={{ fontSize: "1rem" }} />
          </MyRadioButton>
        </Tooltip>
        <Tooltip title="Функциональное назначение" color={"#2c2c2c"}>
          <MyRadioButton value={"func"}>
            <SettingOutlined style={{ fontSize: "1rem" }} />
          </MyRadioButton>
        </Tooltip>
      </MyRadioGroup>
    </>
  );
};

export default FilterModeSelector;

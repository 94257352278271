import axios from "axios";
import { IDeclaredSurvey } from "../store/declared/declared.reducer";
import { IDepartment } from "../store/departments/departments.reducer";
import { IIndicator } from "../store/indicators/indicators.reducer";
import { IOcaiState } from "../store/ocai/ocai.reducer";
import { IAnswer } from "../store/questions/questions.reducer";
import {
  ECompareMode,
  EFilterMode,
  IReport,
  IReportFilter,
} from "../store/reports/reports.reducer";
import { ISurvey } from "../store/surveys/surveys.reducer";

const fetch = async (company_id?: number): Promise<ISurvey[]> => {
  try {
    const response = await axios(`/surveys`, { params: { company_id } });
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки опросов");
  }
};

const fetchTemplate = async (): Promise<ISurvey> => {
  try {
    const response = await axios(`/surveys`, { params: { is_template: true } });
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки опросов");
  }
};

const fetchById = async (survey_id: string) => {
  try {
    const response = await axios.get<ISurvey>(`/surveys/${survey_id}`);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки опроса");
  }
};

const create = async (values: any) => {
  try {
    const response = await axios.post<ISurvey>(`/surveys`, values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось создать опрос");
  }
};

const remove = async (id: string): Promise<boolean> => {
  try {
    const response = await axios.delete(`/surveys/${id}`);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const update = async (id: string, options: any) => {
  try {
    const response = await axios.put(`/surveys/${id}`, options);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить опрос");
  }
};

const saveAnswers = async (survey_id: string, filled_survey_id: string, answers: IAnswer[]) => {
  try {
    const response = await axios.post(`/surveys/${survey_id}/answers`, {
      filled_survey_id,
      answers,
    });
    return response.status === 201;
  } catch (error) {
    throw new Error("Не получилось сохранить ваши ответы. Попробуйте позже.");
  }
};

const getReport = async (survey_id: string): Promise<Blob> => {
  try {
    const req = await axios({
      method: "get",
      url: `/surveys/${survey_id}/excel`,
      responseType: "blob",
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });

    return blob;
  } catch (error) {
    throw new Error("Ошибка генерации отчета");
  }
};

const getOCAIReport = async (companyId: number, companyIds: number[]): Promise<IOcaiState> => {
  try {
    // Формируем строку параметров
    const queryString = companyIds.reduce((acc, id, i) => {
      return i === 0 ? acc + `?company_id=${id}` : acc + `&company_id=${id}`;
    }, ``);
    const response = await axios(`/companies/${companyId}/ocai${queryString}`);
    return response.data;
  } catch (error) {
    throw new Error("");
  }
};

type MacrolevelOptions = {
  survey_id: string;
  macrolevel_id: number;
  departments: IDepartment[];
  indicators: IIndicator[];
  filters: IReportFilter;
  compareMode: ECompareMode;
};

const getMacrolevelReport = async (options: MacrolevelOptions) => {
  const { survey_id, macrolevel_id, departments, filters, indicators, compareMode } = options;

  // Формируем строку параметров департаментов
  const departmentQueryString = departments.reduce((acc, department) => {
    if (department.checked) {
      return acc + `&department=${department.id}`;
    } else {
      return acc;
    }
  }, ``);

  // Формируем строку фильтрации параметров
  const indicatorQueryString = indicators.reduce((acc, indicator) => {
    if (!indicator.checked) {
      return acc + `&skip_indicator=${indicator.id}`;
    } else {
      return acc;
    }
  }, ``);

  // Формируем строку глобальной фильтрации
  const filtersQueryString = Object.keys(filters).reduce((acc, key) => {
    const property = key as keyof IReportFilter;
    const params = filters[property];
    return (acc += params.length > 0 ? `&${key}=` + params.join(`&${key}=`) : "");
  }, ``);

  try {
    const response = await axios.get<IReport>(
      `/surveys/${survey_id}/macrolevels?compare_mode=${compareMode}&macrolevel=${macrolevel_id}${departmentQueryString}${indicatorQueryString}${filtersQueryString}`
    );

    if (response.status === 204) {
      return {
        boxplots: [],
        filters,
        compareMode,
        filterMode: EFilterMode.department,
      } as IReport;
    }
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки отчета");
  }
};

const fetchDeclareByCompanyId = async (company_id: number) => {
  try {
    const response = await axios.get<IDeclaredSurvey>(`/companies/${company_id}/declared_survey`);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки декларируемого опроса");
  }
};

export const SurveysAPI = {
  fetch,
  fetchById,
  fetchDeclareByCompanyId,
  fetchTemplate,
  update,
  create,
  remove,
  saveAnswers,
  getReport,
  getMacrolevelReport,
  getOCAIReport,
};

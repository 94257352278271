import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../api/companies.api";

type Answers = {
  "1": number;
  "2": number;
  "3": number;
  "4": number;
  "5": number;
};

export interface IGoalLine {
  x: number;
  y: number;
  width: number;
}

export interface IGoal {
  answers: Answers;
  company_id: number;
  description: string;
  indicator_id: number;
  is_important: boolean;
  survey_id: string;
  value: number;
  x_bottom: string;
  x_top: string;
  x_type: string;
}

export interface IGoalState {
  entities: IGoal[];
  loading: boolean;
  enabled: boolean;
}

const initialState: IGoalState = {
  entities: [],
  loading: false,
  enabled: false,
};

export const fetchCompanyGoal = createAsyncThunk(
  "companies/fetchGoal",
  async (company_id: number, { dispatch }) => {
    dispatch(loadingGoal(true));
    try {
      const companyGoal = await CompaniesAPI.fetchGoals(company_id);
      if (!Array.isArray(companyGoal.declared_culture)) {
        throw new Error(companyGoal.declared_culture);
      }
      return companyGoal.declared_culture;
    } catch (error) {
      return [];
    } finally {
      dispatch(loadingGoal(false));
    }
  }
);

const companyGoalSlice = createSlice({
  name: "companyGoal",
  initialState,
  reducers: {
    resetCompanyGoal(state) {
      return { ...state, entities: [] };
    },
    loadingGoal(state, action: PayloadAction<boolean>) {
      return { ...state, loading: action.payload };
    },
    toggleGoal(state, action: PayloadAction<boolean>) {
      return { ...state, enabled: action.payload };
    },
    updateGoal(state, action: PayloadAction<Partial<IGoal>>) {
      return {
        ...state,
        entities: state.entities.map((item) =>
          item.indicator_id === action.payload.indicator_id ? { ...item, ...action.payload } : item
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyGoal.fulfilled, (state, action: PayloadAction<IGoal[]>) => {
      return { ...state, entities: action.payload };
    });
  },
});

const { actions, reducer: companyGoalReducer } = companyGoalSlice;

export const { updateGoal, resetCompanyGoal, loadingGoal, toggleGoal } = actions;

export default companyGoalReducer;

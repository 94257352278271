import React, { useState, useEffect } from "react";
import { treeDepartments } from "utils/treeDepartments";
import { useTranslation } from "react-i18next";
import useClipboard from "react-use-clipboard";

import { useTypedDispatch, useTypedSelector } from "store";
import { fetchSurveyDepartments } from "store/departments/departments.reducer";
import { selectDepartments } from "store/departments/departments.selector";

import { Modal, TreeSelect, message, Input, Space, Button } from "antd";
import styled from "styled-components";

interface ModalShareSurveyLinkProps {
  company_id?: number;
  isVisible: boolean;
  surveyId: string;
  onOk?: (values: any) => void;
  onCancel: () => void;
}

interface ShareLinkProps {
  link: string;
  handleCopy: () => void;
}

const StyledSpace = styled(Space)`
  margin-top: 1.5rem;
  width: 100%;
  justify-content: space-between;

  .ant-space-item:first-of-type {
    min-width: 70%;
  }
`;

const ShareLink: React.VFC<ShareLinkProps> = ({ link, handleCopy }) => {
  const { t } = useTranslation();

  return (
    <StyledSpace>
      <Input disabled value={link} />
      <Button type="primary" onClick={handleCopy}>
        {t("buttons.copy")}
      </Button>
    </StyledSpace>
  );
};

export const ModalShareSurveyLink: React.FC<ModalShareSurveyLinkProps> = ({
  isVisible,
  surveyId,
  onCancel,
}) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    surveyId && dispatch(fetchSurveyDepartments(surveyId));
  }, [surveyId, dispatch]);

  const departments = treeDepartments(useTypedSelector(selectDepartments));
  const { t } = useTranslation();

  // значение department.id выбранного department
  const [departmentId, setDepartmentId] = useState<string | null>(null);

  // собранная ссылка для копирования
  const [, setCopied] = useClipboard(
    `${window.location.origin}/survey/${surveyId}/?department=${departmentId}`
  );

  const [surveyLink, setSurveyLink] = useState(`Survey link`);

  useEffect(() => {
    setSurveyLink(
      `${window.location.origin}/survey/${surveyId}/?department=${departmentId}`
    );
  }, [surveyId, departmentId]);

  const handleCopy = () => {
    setCopied();
    message.success(t("surveys.copy_link_success"));
  };

  // складывает departmentId в state при выборе/смене департамента
  const handleSelect = (value: string) => {
    setDepartmentId(value);
  };

  return (
    <Modal
      onOk={onCancel}
      onCancel={onCancel}
      title={t("templates.choose-department")}
      visible={isVisible}
      okText="OK"
      cancelText={t("buttons.cancel")}
      closable
      maskClosable
    >
      <TreeSelect
        onChange={(value) => handleSelect(value)}
        placeholder={t("templates.choose-department")}
        treeData={departments}
        treeDefaultExpandAll
        autoFocus
      />
      <ShareLink link={surveyLink} handleCopy={handleCopy} />
    </Modal>
  );
};

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectCompanies = (state: RootState) => state.companies;

export const selectCompanyById = (company_id?: number) =>
  createSelector(selectCompanies, (state) => state.find((item) => item.id === company_id));

export const selectOptionsCompanies = createSelector(selectCompanies, (companies) =>
  companies.map((company) => ({ label: company.name, value: company.id }))
);

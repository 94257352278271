import axios from "axios";
import { ISocdem } from "../store/socdem/socdem.reducer";

const fetch = async (): Promise<ISocdem[]> => {
  try {
    const response = await axios(`/socdems`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const SocdemAPI = { fetch };

import styled from "styled-components";
import { device } from "../utils/mediaQueries";
import lamp from "../assets/lamp.svg";
export const WarningAlert = styled.div`
  background: #faf9f7;
  border-radius: 12px;
  background-image: url(${lamp});
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 15px 15px 15px 50px;
  margin-bottom: 30px;
  color: #72677d;
  font-size: 16px;
  line-height: 22px;

  @media ${device.mobileS} {
    max-width: 270px;
  }
  @media ${device.mobileM} {
    max-width: 320px;
  }
  @media ${device.mobileL} {
    max-width: 365px;
  }
  @media ${device.tablet} {
    max-width: 630px;
  }
  @media ${device.laptop} {
    max-width: 800px;
  }
  @media ${device.laptopM} {
    max-width: 1100px;
  }
  @media ${device.laptopL} {
    max-width: 1250px;
  }
  @media ${device.desktop} {
    max-width: 1400px;
  }
`;

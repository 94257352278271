import { Radio } from "antd";
import styled, { css } from "styled-components";

export const FilterWrapper = styled.div<{
  background?: string;
  height?: number;
  floating?: boolean;
}>`
  background: ${({ background }) => background || "#ffffff"};
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 0 0 15px 15px;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  ${({ floating }) =>
    floating &&
    css`
      display: inline-block;
      position: relative;
    `}
`;

export const FilterTitle = styled.h4`
  color: #2c2c2c;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 10px 0;
`;

export const FilterForm = styled.div`
  max-height: 400px;
  overflow-y: auto;
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

export const FilterFormItem = styled.div`
  margin-left: 10px;
`;

export const FilterBody = styled.div`
  margin-right: 15px;
`;

export const FilterScrolledBody = styled.div<{ height: number }>`
  max-height: ${({ height }) => height}px;
  overflow-y: auto;
`;

export const MyRadioButton = styled(Radio.Button)`
  background: #fff;
  color: #2c2c2c;
  font-size: 1rem !important;
  width: 100%;
  text-align: center;

  :hover {
    color: #72677d;
  }
`;

export const MyRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem 1rem 0 0;

  label {
    ::before {
      background: #d9d9d9 !important;
    }
  }
  .ant-radio-button-wrapper-checked {
    background: #2c2c2c !important;
    color: #fff !important;
    border-color: #575757 !important;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  .ant-radio-button-wrapper-checked {
    background: #2c2c2c !important;
    border-color: #575757 !important;
  }
`;

import { Row, Col, Button } from "antd";
import React, { useEffect, useState } from "react";

import { Stage as StageRef } from "konva/lib/Stage";

import { useTypedDispatch, useTypedSelector } from "store";
import { ISurvey } from "store/surveys/surveys.reducer";
import { checkAllIndicators } from "store/indicators/indicators.reducer";
import { fetchReportData } from "store/reports/reports.reducer";
import { selectMacrolevel } from "store/macrolevels/macrolevels.selector";
import { selectReports } from "store/reports/reports.selector";
import { selectDepartments } from "store/departments/departments.selector";
import { selectIndicators } from "store/indicators/indicators.selector";
import { ICompany } from "store/companies/companies.reducer";

import MacrolevelFilter from "../filters/MacrolevelFilter";
import Macrolevels from "../Macrolevels";
import SwitchParameters from "./SwitchParameters";
import { HorBoxPlots } from "components/boxplots/HorBoxPlots";

type BoxReportProps = {
  company: ICompany;
  survey: ISurvey;
  stageRef: React.RefObject<StageRef>;
};

export const NewBoxReport: React.FC<BoxReportProps> = ({
  survey,
  company,
  stageRef,
}) => {
  const dispatch = useTypedDispatch();

  const [isVisibleParameters, setIsVisibleParameters] = useState(false);
  const { boxplots, filters, compareMode, filterMode } =
    useTypedSelector(selectReports);
  const macrolevel = useTypedSelector(selectMacrolevel);
  const departments = useTypedSelector(selectDepartments);
  const indicators = useTypedSelector(selectIndicators);

  // useEffect(() => {
  //   console.log(
  //     "departments",
  //     departments.filter((d) => d.checked === true)
  //   );
  // }, [departments]);

  useEffect(() => {
    if (macrolevel && departments && indicators) {
      dispatch(
        fetchReportData({
          survey_id: survey.id,
          macrolevel_id: macrolevel.id,
          departments,
          indicators,
          filters,
          compareMode,
        })
      );
    }
  }, [
    dispatch,
    departments,
    filters,
    indicators,
    macrolevel,
    survey.id,
    compareMode,
  ]);

  const handleCheckAll = () => dispatch(checkAllIndicators(false));

  return (
    <Row gutter={[24, 8]}>
      <Col span={24}>
        <Macrolevels />
      </Col>
      <Col span={7}>
        <MacrolevelFilter
          companyId={survey.company_id}
          goalEnabled={company.use_target_corporate_culture}
          departments={departments}
          compareMode={compareMode}
          filterMode={filterMode}
        />
      </Col>
      <Col
        span={isVisibleParameters ? 11 : 15}
        style={{
          flexShrink: 0,
          marginLeft: "60px",
          paddingLeft: 0,
          paddingRight: 0,
          flexWrap: "wrap",
          minWidth: "1100px",
          // border: "2px solid red",
        }}
      >
        {/* marginLeft: "60px", paddingLeft: 0, paddingRight: 0, */}
        <HorBoxPlots boxplots={boxplots} />
      </Col>
      <Col
        span={isVisibleParameters ? 6 : 1}
        style={{
          display: "none",
        }}
      >
        {macrolevel && (
          <SwitchParameters
            className="hor-boxplots"
            macrolevel={macrolevel.name}
            isVisible={isVisibleParameters}
            onToggle={() => setIsVisibleParameters(!isVisibleParameters)}
          />
        )}
        {isVisibleParameters && (
          <Button type="default" block onClick={handleCheckAll}>
            Сбросить
          </Button>
        )}
      </Col>
    </Row>
  );
};

import { IDepartment } from "../store/departments/departments.reducer";

/**
 * Функция преобразует список департаментов в дерево
 * @param items список департаментов
 * @param id родительский ID
 */
export const treeDepartments = (items?: IDepartment[], id: number | null = null): IDepartment[] => {
  if (!items) return [];
  return items
    .filter((item) => item.parent_id === id)
    .map((item) => {
      const tree = treeDepartments(items, item.id);
      return {
        ...item,
        key: item.id,
        title: item.name,
        value: item.id,
        children: tree.length > 0 ? tree : undefined,
      };
    });
};

/**
 * Функция преобразует список с разорванными департаментами в единое целое
 * @param items список департаментов
 */
export const treeDepartmentsWithOrphans = (items: IDepartment[]): IDepartment[] => {
  // Получаем семейное дерево
  let tree: IDepartment[] = treeDepartments(items);
  // Получаем все id'шники
  const ids = items.map((item) => item.id);
  // Ищем все деп-ты у которых указан родитель, но его нет
  const orphans = items.filter((item) => item.parent_id && !ids.includes(item.parent_id));
  // Объединяем семьи с сиротами
  return [...tree, ...orphans];
};

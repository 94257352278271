import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SocdemAPI } from "../../api/socdems.api";

export interface ISocdem {
  id: number;
  name: string;
}

const initialState: ISocdem[] = [];

export const fetchSocdems = createAsyncThunk("socdem/fetch", async () => {
  const socdems = await SocdemAPI.fetch();
  return socdems;
});

const socdemSlice = createSlice({
  name: "socdem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSocdems.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { reducer: socdemReducer } = socdemSlice;

export default socdemReducer;

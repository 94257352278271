import axios from "axios";
import { IQuestion } from "../store/questions/questions.reducer";

const fetch = async (survey_id: string) => {
  try {
    const response = await axios(`/surveys/${survey_id}/questions`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const create = async (survey_id: string, values: any) => {
  try {
    const response = await axios.post(`/surveys/${survey_id}/questions`, values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось добавить вопрос");
  }
};

const remove = async (question_id: number): Promise<boolean> => {
  try {
    const response = await axios.delete(`/questions/${question_id}`);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const update = async (question: IQuestion, values: any): Promise<IQuestion> => {
  try {
    const response = await axios.put(`/questions/${question.id}`, values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить вопрос");
  }
};

export const QuestionsAPI = { fetch, create, remove, update };

import React from "react";
import { Group, Line } from "react-konva";

type ArrowProps = {
  x: number;
  y: number;
  size?: number;
  stroke?: string;
  strokeWidth?: number;
  rotation?: number;
};

const Arrow: React.FC<ArrowProps> = ({
  x = 0,
  y = 0,
  size = 10,
  stroke = "#B7B7B7",
  strokeWidth = 1,
  rotation = 0,
}) => {
  return (
    <Group x={x} y={y} width={size} height={size} rotation={rotation}>
      <Line points={[0, 0, size, size / 2]} stroke={stroke} strokeWidth={strokeWidth} />
      <Line points={[0, size, size, size / 2]} stroke={stroke} strokeWidth={strokeWidth} />
    </Group>
  );
};

export default Arrow;

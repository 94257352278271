import React, { useEffect } from "react";
import groupBy from "lodash/groupBy";

import { useTypedSelector, useTypedDispatch } from "store";
import { IReportBoxPlot } from "store/reports/reports.reducer";
import { selectDepartments } from "store/departments/departments.selector";
import { setInterpretation } from "store/interpretation/interpretation.reducer";

import styled from "styled-components";
import { Layout } from "antd";
import { HorBoxGroup } from "./HorBoxGroup";

import { selectCompanyGoal } from "store/goal/goal.selector";

const ContentWrapper = styled(Layout)`
  flex-direction: column;
  min-height: 500px;
`;

interface HorBoxPlotsProps {
  boxplots: IReportBoxPlot[];
}

export const HorBoxPlots: React.FC<HorBoxPlotsProps> = ({ boxplots }) => {
  const dispatch = useTypedDispatch();

  const goals = useTypedSelector(selectCompanyGoal).entities;

  const boxplotsWithGoals = boxplots.map((boxplot) => {
    const boxplotGoal = goals.find(
      (goal) => goal.x_bottom === boxplot.x_bottom
    );
    return {
      ...boxplot,
      value: boxplotGoal?.value ? boxplotGoal.value : 0,
      description: `${boxplot.x_top} vs ${boxplot.x_bottom}`,
    };
  });

  useEffect(() => {
    dispatch(setInterpretation(boxplotsWithGoals));
  }, [boxplotsWithGoals]);

  const departments = useTypedSelector(selectDepartments);
  const groups = Object.values(
    groupBy(boxplotsWithGoals, (item) => item.x_top)
  );

  // Получаем минимальное / максимальное значение
  const min = boxplots.length > 0 ? Math.min(...boxplots.map((b) => b.low)) : 0;
  const max =
    boxplots.length > 0 ? Math.max(...boxplots.map((b) => b.high)) : 0;

  return (
    <ContentWrapper>
      {groups.map((group, key) => {
        return (
          <HorBoxGroup
            key={key}
            groupKey={key + 1}
            group={group}
            max={max}
            min={min}
            isOdd={key % 2 === 0}
            departments={departments}
            declaredCulture={4}
          />
        );
      })}
    </ContentWrapper>
  );
};

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  AnyAction,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";

import appReducer from "./app/app.reducer";
import authReducer from "./auth/auth.reducer";
import accountsReducer from "./accounts/accounts.reducer";
import companiesReducer from "./companies/companies.reducer";
import companyGoalReducer from "./goal/goal.reducer";
import declaredReducer from "./declared/declared.reducer";
import departmentsReducer from "./departments/departments.reducer";
import interpretationReducer from "./interpretation/interpretation.reducer";
import indicatorsReducer from "./indicators/indicators.reducer";
import surveysReducer from "./surveys/surveys.reducer";
import socdemReducer from "./socdem/socdem.reducer";
import macrolevelsReducer from "./macrolevels/macrolevels.reducer";
import questionReducer from "./questions/questions.reducer";
import reportsReducer from "./reports/reports.reducer";
import ocaiReducer from "./ocai/ocai.reducer";
import guestReducer from "./guest/guest.reducer";

export const rootReducer = {
  auth: authReducer,
  questions: questionReducer,
  companies: companiesReducer,
  companyGoal: companyGoalReducer,
  declared: declaredReducer,
  departments: departmentsReducer,
  interpretation: interpretationReducer,
  indicators: indicatorsReducer,
  accounts: accountsReducer,
  surveys: surveysReducer,
  socdem: socdemReducer,
  reports: reportsReducer,
  app: appReducer,
  ocai: ocaiReducer,
  macrolevels: macrolevelsReducer,
  guest: guestReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: true,
      thunk: true,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const storeWithInitial = (preloadedState?: RootState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: true,
        serializableCheck: true,
        thunk: true,
      }),
    devTools: process.env.NODE_ENV !== "production",
  });

export type RootState = ReturnType<typeof store.getState>;

/* Types */
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveysAPI } from "../../api/surveys.api";

export interface ISurvey {
  id: string;
  name: string;
  company_id: number;
  status: "started" | "stopped" | "not_started";
  employee_count: number;
  finished_count: number;
  started_count: number;
  start_date: string;
  finish_date: string;
  report_created_date: string | null;
  is_template: boolean;
  is_archived?: boolean;
}

export interface ISurveyByDepartment {
  name: string;
  employee_count: number;
  started_count: number;
  finished_count: number;
}

export interface IFilledSurvey {
  id: string;
  survey_id: string;
  department_id: number;
  status: string;
}

export const fetchSurveys = createAsyncThunk("surveys/fetch", async () => {
  const surveys = await SurveysAPI.fetch();
  return surveys;
});

export const fetchSurveyById = createAsyncThunk("surveys/fetchById", async (survey_id: string) => {
  try {
    const survey = await SurveysAPI.fetchById(survey_id);
    return survey;
  } catch (err: any) {
    throw new Error(err);
  }
});

const initialState: ISurvey[] = [];

const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    addSurvey(state, action: PayloadAction<ISurvey>) {
      return [...state, action.payload];
    },
    setSurveys(_, action: PayloadAction<ISurvey[]>) {
      return action.payload;
    },
    updateSurvey(state, action) {
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    },
    removeSurvey(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveys.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(fetchSurveyById.fulfilled, () => {});
  },
});

const { actions, reducer: surveysReducer } = surveysSlice;

export const { addSurvey, setSurveys, updateSurvey, removeSurvey } = actions;

export default surveysReducer;

import { useEffect, useState } from "react";

import { useTypedDispatch, useTypedSelector } from "store";
import { Link, Navigate } from "react-router-dom";
import { message } from "antd";

import { selectCompanies } from "../../store/companies/companies.selector";
import { selectQuestions } from "../../store/questions/questions.selector";
import { selectAuth } from "../../store/auth/auth.selector";
import { selectDeclared } from "../../store/declared/declared.selector";
import { ICompany } from "../../store/companies/companies.reducer";
import { loading } from "../../store/app/app.reducer";
import { setDeclaredStatus, setDeclaredSurvey } from "../../store/declared/declared.reducer";
import { fetchQuestions, IQuestion, setQuestions } from "../../store/questions/questions.reducer";
import { SurveysAPI } from "../../api/surveys.api";

import { GoalBanner, CompanyList, CompanyListItem } from "./goal.styled";
import Survey from "../Survey";

type GoalSurveyProps = {
  company?: ICompany;
};

const GoalSurvey: React.FC<GoalSurveyProps> = ({ company }) => {
  const dispatch = useTypedDispatch();
  const companies = useTypedSelector(selectCompanies);
  const { role, company_id } = useTypedSelector(selectAuth);
  const { survey_id, filled_survey_id, status } = useTypedSelector(selectDeclared);
  const [page, setPage] = useState(0);
  const declaredQuestions = useTypedSelector(selectQuestions);

  /**
   * Отправляем ответы на вопросы декларативной культуры
   */
  const handleSubmitAnswers = async () => {
    if (!survey_id || !filled_survey_id) return;
    // Формируем массив ответов
    const answers = declaredQuestions.map((q) => {
      return {
        question_id: q.id,
        value: q.answer,
      };
    });
    dispatch(loading(true));
    try {
      // Отправляем на сервер и устанавливаем статус пройденного опросника
      const isSent = await SurveysAPI.saveAnswers(survey_id, filled_survey_id, answers);
      isSent && dispatch(setDeclaredStatus("finished"));
    } catch (error) {
      message.warning("Не удалось сохранить ваши ответы. Попробуйте позднее.");
    } finally {
      dispatch(loading(false));
    }
  };

  // Проверить, заполнена ли анкета декларируемого образа
  useEffect(() => {
    if (!company) return;
    (async () => {
      const targetSurvey = await SurveysAPI.fetchDeclareByCompanyId(company.id);
      dispatch(setDeclaredSurvey(targetSurvey));
      if (targetSurvey.status === "created") {
        // Проверяем есть ли в хранилище вопросы
        const questions = localStorage.getItem(targetSurvey.filled_survey_id!);
        if (questions) {
          const answers: IQuestion[] = JSON.parse(questions);
          dispatch(setQuestions(answers));
          // получаем последнюю страницу
          const lastPage = answers.findIndex((a) => a.answer === undefined);
          setPage(lastPage);
        } else {
          dispatch(fetchQuestions({ survey_id: targetSurvey.survey_id, is_declared: true }));
        }
      }
    })();
  }, [company, dispatch, status]);

  // Записываем прогресс в локальное хранилище
  useEffect(() => {
    if (declaredQuestions.length > 0 && filled_survey_id) {
      localStorage.setItem(filled_survey_id.toString(), JSON.stringify(declaredQuestions));
    }
  }, [filled_survey_id, declaredQuestions]);

  // Если HR попал на страницу без id компании перекидываем на его компанию
  if (role === "hr" && company?.id !== company_id) {
    return <Navigate to={`/goal/${company_id}`} />;
  }

  // Проверяем указана ли компания для админа
  if (role === "admin" && !company) {
    return (
      <GoalBanner>
        <CompanyList>
          {companies.map((company) => (
            <CompanyListItem key={company.id}>
              <Link to={`/goal/${company.id}`}>{company.name}</Link>
            </CompanyListItem>
          ))}
        </CompanyList>
      </GoalBanner>
    );
  }
  // Проверяем включена ли настройка
  if (!company?.use_target_corporate_culture) {
    return (
      <GoalBanner>
        <h2>
          Для компании &laquo;{company?.name}&raquo; не включена возможность определения целевой
          культуры. Обратитесь к администратору за помощью.
        </h2>
      </GoalBanner>
    );
  }

  // Проверяем статус заполнения ОДК
  if (status === "finished") {
    return (
      <GoalBanner data-testid="declared-survey-success">
        <h2>Вы уже заполнили образ целевой культуры! Спасибо!</h2>
      </GoalBanner>
    );
  }

  // Проверяем есть ли вопросы в опроснике
  if (declaredQuestions.length === 0) return null;

  return (
    <Survey
      questions={declaredQuestions}
      page={page}
      onBack={() => setPage(page - 1)}
      onNext={() => setPage(page + 1)}
      onSubmit={handleSubmitAnswers}
    />
  );
};

export default GoalSurvey;

import React from "react";
import { IDepartment } from "../../store/departments/departments.reducer";

import { IReportBoxPlot } from "store/reports/reports.reducer";

import styled from "styled-components";
import { NewHorBox } from "./NewHorBox";
import { useTypedSelector } from "store";
import { IIndicator } from "store/indicators/indicators.reducer";
import { selectIndicators } from "store/indicators/indicators.selector";

import { Layout } from "antd";

const HorBoxGroupWrapper = styled(Layout)`
  flex-direction: column !important;
  gap: 2rem;
  padding: 1rem 0 2rem;
  background: #fff;
  align-items: center;
  // margin-left: 60px;

  border-bottom: 2px solid #72677d60 !important;

  :first-of-type {
    border-top: 2px solid #72677d60 !important;
  }

  // :last-of-type {
  //   border: 0 !important;
  //   // background: red;
  // }
`;

interface BoxReportProps {
  departments: IDepartment[];
  group: IReportBoxPlot[];
  groupKey: number;
  min: number;
  max: number;
  isOdd: boolean;
  declaredCulture: number;
}

export const HorBoxGroup: React.FC<BoxReportProps> = ({
  departments,
  group,
  groupKey,
  min,
  max,
  isOdd,
  declaredCulture,
}) => {
  const indicators: IIndicator[] = useTypedSelector(selectIndicators);

  return (
    <HorBoxGroupWrapper>
      {departments &&
        group.map((box, key) => {
          return (
            <NewHorBox
              key={key}
              box={box}
              min={min}
              max={max}
              q1={box.q1}
              q3={box.q3}
              departments={departments}
              width={500}
              declaredCulture={declaredCulture}
            />
          );
        })}
    </HorBoxGroupWrapper>
  );
};

import { Button, message, Popover } from "antd";
import styled from "styled-components";
import useClipboard from "react-use-clipboard";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { ISurvey } from "../../store/surveys/surveys.reducer";
import { WarningAlert } from "../../shared/alert.styled";
import { InfoCircleOutlined } from "@ant-design/icons";

type StatusInformationProps = {
  survey: ISurvey;
};

const StatusInformationContent = styled.div``;
const StatusInformationHint = styled.div`
  color: #72677d;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  b {
    color: #200e32;
  }
`;

const StatusInformationText = styled.div`
  color: #200e32;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 1rem;
  .ant-btn-link {
    margin-left: 2px;
    span {
      text-decoration: underline;
    }
  }
`;

const StatusInformation: React.FC<StatusInformationProps> = ({ survey }) => {
  const { t } = useTranslation();
  const [, setCopied] = useClipboard(
    `https://${
      process.env.NODE_ENV === "development" && "test."
    }survey.corpculture.2035.university?uuid=${survey.id}`
  );

  const handleCopyToClipboard = () => {
    setCopied();
    message.success(t("surveys.copy_link_success"));
  };

  if (survey.status === "started")
    return (
      <StatusInformationContent>
        <StatusInformationText>
          Ссылка на опросник отправлена на ваш адрес электронной почты. Ее необходимо распространить
          среди сотрудников. Также вы можете скопировать ссылку в буфер, кликнув
          <Button type="link" onClick={handleCopyToClipboard}>
            Скопировать
          </Button>
          .
        </StatusInformationText>
        <StatusInformationHint>
          Опрос завершится автоматически <b>{moment(survey.finish_date).fromNow()}</b>{" "}
          <Popover
            placement="bottom"
            content={
              <p style={{ maxWidth: 300 }}>
                Вы можете завершить текущий опрос в любой момент. Пожалуйста, учитывайте, что
                завершение с неполными данными снизит репрезентативность исследования.
              </p>
            }
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </StatusInformationHint>
      </StatusInformationContent>
    );
  if (survey.status === "not_started")
    return (
      <StatusInformationContent>
        <StatusInformationText>
          Исследование стартует{" "}
          <b>{moment(survey.start_date).format("Do MMMM (dddd) YYYY года в hh:mm")}</b>. Ссылку на
          него вы получите в этот день.
        </StatusInformationText>
        <StatusInformationHint>
          Данные не собираются, т.к. исследование пока не запущен
        </StatusInformationHint>
      </StatusInformationContent>
    );

  if (survey.status === "stopped") {
    return (
      <StatusInformationContent>
        <WarningAlert>
          Результаты данного исследования размещены на соответствующих вкладках.
        </WarningAlert>
      </StatusInformationContent>
    );
  }

  return <StatusInformationContent>Нет данных</StatusInformationContent>;
};

export default StatusInformation;

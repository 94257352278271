import styled from "styled-components";
import { Checkbox } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { useTypedDispatch, useTypedSelector } from "store";
import { selectIndicatorsByMacrolevel } from "../../store/indicators/indicators.selector";
import { toggleIndicator } from "../../store/indicators/indicators.reducer";

import {
  FilterTitle,
  FilterWrapper,
  FilterScrolledBody,
} from "../../shared/Filter";

const ParameterItem = styled(Checkbox)`
  padding: 5px 0 0 0px;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CircleButton = styled.button<{ isVisible?: boolean }>`
  width: 20px;
  height: 20px;
  background: #72677d;
  border-radius: 50%;
  color: #fff;
  font-size: 0.7rem;
  border: none;
  padding: 0;
  margin: ${({ isVisible }) => (isVisible ? "10px 15px 0 0" : "0 18px 0 0")};
  cursor: pointer;
  span {
    display: block;
  }
`;

const RotateTitle = styled(FilterTitle)`
  position: absolute;
  top: 45%;
  left: -15px;
  transform: rotate(90deg);
  font-size: 14px;
  color: #72677d;
`;

type SwitchParametersProps = {
  isVisible: boolean;
  onToggle: () => void;
  macrolevel: string;
  className?: string;
};
const SwitchParameters: React.FC<SwitchParametersProps> = ({
  isVisible,
  onToggle,
  macrolevel,
}) => {
  const dispatch = useTypedDispatch();
  const indicators = useTypedSelector(selectIndicatorsByMacrolevel(macrolevel));

  const handleSwitch = (id: number) => {
    dispatch(toggleIndicator(id));
  };

  if (!isVisible) {
    return (
      <FilterWrapper height={600} floating>
        <CircleButton isVisible={isVisible} onClick={onToggle}>
          <LeftOutlined />
        </CircleButton>
        <RotateTitle>Параметры</RotateTitle>
      </FilterWrapper>
    );
  }

  return (
    <FilterWrapper>
      <FilterHeader>
        <FilterTitle>Параметры</FilterTitle>
        <CircleButton onClick={onToggle}>
          <RightOutlined />
        </CircleButton>
      </FilterHeader>
      <FilterScrolledBody height={500}>
        {indicators.map((indicator) => (
          <ParameterItem
            checked={indicator.checked}
            key={indicator.id}
            onClick={() => handleSwitch(indicator.id)}
          >
            {indicator.general_axis}
          </ParameterItem>
        ))}
      </FilterScrolledBody>
    </FilterWrapper>
  );
};

export default SwitchParameters;

import React, { useState } from "react";
import { Input, Form, Radio, Button, Space, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import QuestionHeader from "../QuestionHeader";
import { IQuestionText } from "../../store/questions/questions.reducer";

interface TextTypeProps extends IQuestionText {
  position: number;
  onSave: (values: object) => Promise<boolean>;
}

const TextType: React.VFC<TextTypeProps> = (question) => {
  const { onSave, position } = question;
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  /**
   * Функция отправляет данные формы на сохранение,
   * в случае успеха - закрывает редактирование
   * @param values Значения полей формы
   */
  const handleSave = (values: any) => {
    onSave(values).then((status) => setIsEditable(!status));
  };

  const ListChecker = (): JSX.Element => {
    const { validation, min, max } = question.parameters;
    return validation === "number" ? (
      <p>
        {t("templates.validations")}: {t("app.number")}; min={min}; max={max}
      </p>
    ) : (
      <p>{t("templates.only_string")}</p>
    );
  };

  return (
    <div className="question">
      <QuestionHeader
        position={position}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        question={question}
      />
      <div className="question__body">
        {isEditable ? (
          <Form layout="vertical" initialValues={question} onFinish={handleSave}>
            <Form.Item label={t("templates.title")} name="text">
              <Input placeholder={t("templates.title")} />
            </Form.Item>
            <Form.Item label={t("templates.hint")} name={["parameters", "hint"]}>
              <Input placeholder={t("templates.hint")} />
            </Form.Item>
            <Row>
              <Col>
                <Form.Item label={t("templates.validations")} name={["parameters", "validation"]}>
                  <Radio.Group className="radio-vertical">
                    <Radio value="string">{t("templates.only_string")}</Radio>
                    <Radio value="number">{t("app.number")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col offset={1}>
                <Form.Item name={["parameters", "min"]} label="min" style={{ marginBottom: 0 }}>
                  <Input type="number" size="small" />
                </Form.Item>
                <Form.Item name={["parameters", "max"]} label="max">
                  <Input type="number" size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button type="default" onClick={() => setIsEditable(false)}>
                  {t("app.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("app.save")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <div className="question__body-preview">
            <p>{question.text}</p>
            <p>{question.parameters.hint}</p>
            <ListChecker />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextType;

import styled from "styled-components";

export const GoalWrapper = styled.div`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;

export const GoalBanner = styled.div`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
`;

export const CompanyList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const CompanyListItem = styled.li`
  border: 1px solid #e0dbd1;
  margin-bottom: 1rem;
  border-radius: 8px;
  cursor: pointer;
  a {
    display: block;
    color: #72677d;
    padding: 8px 0;
  }
  &:hover {
    background: #faf9f7;
    border: 1px solid #200e32;
    a {
      color: #200e32;
    }
  }
`;

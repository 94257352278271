import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
export const selectQuestions = (state: RootState) => state.questions;

export const selectQuestionsByPage = (page?: number | string, pageSize: number = 1) =>
  createSelector(selectQuestions, (questions) => {
    if (typeof page === "undefined") throw new Error("Не указана страница вопроса");
    const index = typeof page === "string" ? Number(page) : page;
    // Сортируем вопросы
    const sortedQuestions = questions.slice().sort((a, b) => a.order_number - b.order_number);
    // Получаем номер последнего вопроса
    const lastElement = sortedQuestions.slice().pop();
    const nextPosition = lastElement ? lastElement.order_number + 1 : 1;
    // Считаем количество отвеченных вопросов
    const answeredCount = sortedQuestions.filter((question) => !!question.answer).length;
    // Считаем прогресс
    const progress = Math.round((answeredCount / sortedQuestions.length) * 100);
    // Проверяем является ли текущая страница последняя
    const isLastPage = sortedQuestions.length === index + 1;
    // Получаем статус ответа на текущий вопрос
    const isAnswered = sortedQuestions[index]?.answer !== undefined;
    // Собираем ответы в массив
    const answers = sortedQuestions.map((question) => ({
      question_id: question.id,
      value: question.answer,
    }));
    // Устанавливаем параметры start/end для slice
    let sliceOptions = [0, 1];
    // Для выборки из нескольких вопросов
    if (pageSize > 1) {
      if (index > 0) {
        sliceOptions = [(index - 1) * pageSize, index * pageSize];
      } else {
        sliceOptions = [index, index * pageSize];
      }
    } else {
      // Для выборки из одного вопроса
      sliceOptions = [index, index + 1];
    }

    // Собираем результаты
    return {
      index,
      progress,
      nextPosition,
      answeredCount,
      isLastPage,
      isAnswered,
      answers,
      questions: sortedQuestions,
      questionsByPage: sortedQuestions.slice(...sliceOptions),
    };
  });

import { Button, Typography } from "antd";
import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type NavigationProps = {
  children: ReactNode;
};

type NavigationItemProps = {
  icon: ReactNode;
  hint: string;
  style?: React.CSSProperties;
  to: string;
  isActive?: boolean;
  isHidden?: boolean;
};

const NavigationWrapper = styled.div`
  display: flex;
  background: #ededed;
  border-radius: 12px;
  margin: 5px;
  padding: 0 5px;
  align-content: center;
  justify-content: space-around;
  align-items: center;
`;

const NavigationItem = styled.div<{ isHovering?: boolean; isActive?: boolean }>`
  padding: 8px 10px;
  margin: 5px;
  line-height: 100%;
  cursor: pointer;
  position: relative;
  border-radius: ${({ isHovering }) => (isHovering ? "12px 12px 0 0" : "12px")};
  background-color: ${({ isHovering, isActive }) =>
    isHovering || isActive ? "#C0BBC5" : "transparent"};
  //transition: background-color 0.1s, border-radius 0s;
`;

const MobileNavigationItem = styled(NavigationItem)`
  margin: 0;
  padding: 0;

  button {
    padding: 0;
    border-radius: 12px;
  }
`;

const NavigationItemIcon = styled.div``;

const NavigationItemHint = styled.div<{ isHovering: boolean }>`
  position: absolute;
  background-color: #c0bbc5;
  opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px 15px;
  border-radius: 0 12px 12px;
  left: 0;
  top: 36px;
  transition: opacity 0.1s linear;
`;

const NavigationItemLink = styled(Link)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #200e32;
  :hover {
    color: #200e32;
  }
`;

const MobileNavButton = styled(Button)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  fontsize: 1rem;

  // контейнер иконки и текста
  div a {
    display: flex;
    flex-direction: row;

    // текст
    .ant-typography {
      padding: 0 0.25rem;
    }
  }
`;

const Item: React.FC<NavigationItemProps> = ({
  icon,
  hint,
  to,
  isActive,
  isHidden,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  if (isHidden) return null;

  return (
    <NavigationItem
      isHovering={isHovering}
      isActive={isActive}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <NavigationItemIcon>
        <Link to={to}>{icon}</Link>
      </NavigationItemIcon>
      <NavigationItemHint isHovering={isHovering}>
        <NavigationItemLink to={to}>{hint}</NavigationItemLink>
      </NavigationItemHint>
    </NavigationItem>
  );
};

const MobileItem: React.FC<NavigationItemProps> = ({
  icon,
  to,
  hint,
  isActive,
  isHidden,
}) => {
  if (isHidden) return null;

  return (
    <MobileNavigationItem isActive={isActive}>
      <MobileNavButton>
        <NavigationItemIcon>
          <Link to={to}>
            {icon} <Typography.Text>{hint}</Typography.Text>
          </Link>
        </NavigationItemIcon>
      </MobileNavButton>
    </MobileNavigationItem>
  );
};

export interface INavigation extends React.FC<NavigationProps> {
  Item: typeof Item;
  MobileItem: typeof Item;
}

const Navigation: INavigation = ({ children }) => {
  const length = React.Children.count(children);

  if (length === 0) return <></>;

  const renderMenu = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return Item(child.props);
    } else {
      return child;
    }
  });

  return <NavigationWrapper>{renderMenu}</NavigationWrapper>;
};

Navigation.Item = Item;
Navigation.MobileItem = MobileItem;

export default Navigation;

import React, { useEffect, useMemo } from "react";
import { Layer, Line, Stage } from "react-konva";
import { Provider } from "react-redux";
import groupBy from "lodash/groupBy";
import { Stage as StageRef } from "konva/lib/Stage";

import { store, useTypedSelector } from "store";
import { IReportBoxPlot } from "store/reports/reports.reducer";
import { selectDepartments } from "store/departments/departments.selector";
import { selectCompanyGoal } from "store/goal/goal.selector";
import { IGoalLine } from "store/goal/goal.reducer";

import BoxGroup from "./BoxGroup";
import AxisY from "./AxisY";

type BoxPlotsProps = {
  boxplots: IReportBoxPlot[];
  stageRef: React.RefObject<StageRef>;
  width?: number;
  height?: number;
  tick?: number;
};

const BoxPlots: React.FC<BoxPlotsProps> = ({
  boxplots,
  stageRef,
  width = 800,
  height = 600,
  tick = 0.25,
}) => {
  const departments = useTypedSelector(selectDepartments);
  const goal = useTypedSelector(selectCompanyGoal);

  // Длинна текстовой метки
  const labelWidth = 40;
  // Длинна коробки
  const boxWidth = 30;
  // Высота полей для верхней и нижних надписей в процентах
  const padding = 0.15;
  // Группируем данные по типу
  const groups = Object.values(groupBy(boxplots, (item) => item.x_top));
  // Записываем длинну (количество боксов) каждого блока в массив
  const groupsWidth = groups.map((group) => group.length);
  // Общая длинна всего графика
  const commonWidth = groupsWidth.reduce(
    (acc, item) => (acc += item * boxWidth * 2 + boxWidth),
    boxWidth * 2
  );
  // Получаем минимальное / максимальное значение
  const min = boxplots.length > 0 ? Math.min(...boxplots.map((b) => b.low)) : 0;
  const max =
    boxplots.length > 0 ? Math.max(...boxplots.map((b) => b.high)) : 0;
  // Получаем количество линий
  const tickCount = (max - min) / tick || 0;
  // Высота полей в пикселях
  const paddingHeight = height * padding;
  // Координаты по y для верхней и нижней границы
  const [py1, py2] = [height * padding, height - height * padding];
  // Считаем высоту доски в пикселях
  const deskHeight = height - paddingHeight * 2;

  // Генерируем точки отрисвки линии целевой культуры
  const goalLines = useMemo(() => {
    // Если результатов ДК нет, возвращаем пустой массив
    if (goal.entities.length === 0) return [];
    // Собираем хеш-таблицу значений индикаторов целевой культуры
    const hashmap = goal.entities.reduce<Record<string, number>>(
      (acc, item) => {
        acc[item.x_top] = item.value;
        return acc;
      },
      {}
    );

    // Формируем точки x и y для каждой группы
    const xyGroup = groups.reduce<IGoalLine[]>((acc, group, index) => {
      // Получаем название верхнего индикатора
      const x_top = group.find((x) => x.x_top)?.x_top!;
      // Получаем значение из хеш-таблицы
      const value = hashmap[x_top] - 1;
      // Рассчитываем общую ширину группы
      const groupWidth = group.length * boxWidth * 2 + boxWidth;
      // x - левая граница группы - начальная точка отрисовки
      const x = groupWidth * index;
      // Высота одного деления
      const tickHeight = deskHeight / tickCount;
      const tickGroupHeight = (tickCount / 4) * tickHeight;
      // y - конвертируем значение от 1 до 5
      const y = py2 - value * tickGroupHeight;
      return acc.concat({ x, y, width: groupWidth });
    }, []);

    return xyGroup;
  }, [deskHeight, goal.entities, groups, py2, tickCount]);

  if (boxplots.length === 0) return <p>Нет данных для отображения</p>;

  return (
    <Stage
      className="stage-content"
      width={commonWidth}
      height={height}
      style={{ overflow: "auto" }}
      ref={stageRef}
    >
      <Provider store={store}>
        <AxisY
          width={commonWidth > width ? commonWidth : width}
          labelWidth={labelWidth}
          height={height}
          paddingHeight={paddingHeight}
          pY={py1}
          tick={tick}
          tickCount={tickCount}
          max={max}
        />
        <Layer id="goal" x={labelWidth} listening={false}>
          {goalLines.map((line, key) => (
            <Line
              key={key}
              points={[line.x, line.y, line.x + line.width, line.y]}
              stroke="#000"
              strokeWidth={3}
            />
          ))}
        </Layer>
        <Layer id="groups" x={labelWidth}>
          {groups.map((group, key) => (
            <BoxGroup
              key={key}
              groupKey={key + 1}
              group={group}
              pY1={py1}
              pY2={py2}
              tickCount={tickCount}
              max={max}
              min={max}
              height={height}
              deskHeight={deskHeight}
              groupsWidth={groupsWidth}
              boxWidth={boxWidth}
              isOdd={key % 2 === 0}
              departments={departments}
            />
          ))}
        </Layer>
      </Provider>
    </Stage>
  );
};

export default React.memo(BoxPlots);

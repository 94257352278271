import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionsAPI } from "../../api/questions.api";
import { SurveysAPI } from "../../api/surveys.api";

export interface IAnswer {
  question_id: number;
  value: any;
}

export type RadioOptions = {
  label: string;
  value?: string;
  checked?: boolean;
  require_comment: boolean;
};

export type DistributionOptions = {
  text: string;
  key: string;
  score?: number;
};

export interface IBaseQuestion {
  id: number;
  text: string;
  order_number: number;
  survey_id: string;
}

export interface IQuestionComment extends IBaseQuestion {
  type: "comment";
  answer?: string;
}

export interface IQuestionText extends IBaseQuestion {
  type: "text";
  answer?: string | number;
  parameters: {
    hint?: string;
    validation: "string" | "number";
    min?: number;
    max?: number;
  };
}

export interface IQuestionHtml extends IBaseQuestion {
  type: "html";
  answer?: string;
  parameters: {
    html: string;
  };
}

export interface IQuestionDistribution extends IBaseQuestion {
  type: "distribution";
  answer?: { [key: string]: number };
  parameters: {
    score: number;
    ocai: string;
    options: DistributionOptions[];
  };
}

export interface IQuestionRadio extends IBaseQuestion {
  type: "radio";
  answer?: string;
  socdem_id?: number;
  parameters: {
    options: RadioOptions[];
    is_lie_scale?: boolean;
  };
}

export interface IQuestionMultiple extends IBaseQuestion {
  type: "multiple";
  answer?: string;
  socdem_id?: number;
  parameters: {
    hint?: string;
    options: RadioOptions[];
  };
}

export interface IQuestionScale extends IBaseQuestion {
  type: "scale";
  answer?: number;
  indicator_id: number;
  parameters: {
    hint?: string;
    reverse_scale: boolean;
  };
}

export type IQuestion =
  | IQuestionComment
  | IQuestionText
  | IQuestionHtml
  | IQuestionDistribution
  | IQuestionRadio
  | IQuestionMultiple
  | IQuestionScale;

type FetchQuestions = {
  survey_id?: string;
  is_declared?: boolean;
};
export const fetchQuestions = createAsyncThunk(
  "questions/fetch",
  async ({ survey_id, is_declared }: FetchQuestions) => {
    let questions: IQuestion[] = [];
    try {
      if (survey_id) {
        questions = await QuestionsAPI.fetch(survey_id);
        if (is_declared) {
          questions = questions.filter(
            (item) => item.type === "scale" || item.type === "distribution"
          );
        }
      } else {
        const template = await SurveysAPI.fetchTemplate();
        questions = await QuestionsAPI.fetch(template.id);
      }
    } catch (error) {
      return [];
    }

    return questions;
  }
);

export const initialState: IQuestion[] = [];

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions(_, action: PayloadAction<IQuestion[]>) {
      return action.payload;
    },
    removeQuestion(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
    updateQuestion(state, { payload }: PayloadAction<IQuestion>) {
      return state.map((item) => (item.id === payload.id ? payload : item));
    },
    addQuestion(state, { payload }: PayloadAction<IQuestion>) {
      return [...state, payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer: questionsReducer } = questionsSlice;

export const { setQuestions, removeQuestion, updateQuestion, addQuestion } = actions;

export default questionsReducer;

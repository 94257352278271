import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Menu, Dropdown } from "antd";
import { FolderFilled, MenuOutlined } from "@ant-design/icons";

import { device } from "utils/mediaQueries";
import { useTypedSelector, useTypedDispatch } from "store";
import { selectAuth } from "../store/auth/auth.selector";
import { logout } from "../store/auth/auth.reducer";

import { ReactComponent as DiscoveryIcon } from "../assets/Menu/Discovery.svg";
import { ReactComponent as GraphIcon } from "../assets/Menu/Graph.svg";
import { ReactComponent as PaperIcon } from "../assets/Menu/PaperPlus.svg";
import logo from "../assets/Logotype.svg";

import Navigation from "./Navigation";

const MobileMenu = styled(Menu)`
  @media ${device.mobileS} {
    width: 290px;
  }
  @media ${device.mobileM} {
    width: 342px;
  }
  @media ${device.mobileL} {
    width: 390px;
  }
`;

export const MobileHeader = () => {
  const { t, i18n } = useTranslation();
  const { username, role, company_id } = useTypedSelector(selectAuth);
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("corpgen-token");
    dispatch(logout());
    navigate("/");
  };

  const mobileMenu = (
    <MobileMenu>
      {role === "admin" && (
        <Menu.Item>
          <Navigation.MobileItem
            to="/companies"
            hint="Компании"
            key={1}
            icon={
              <FolderFilled
                style={{
                  color: "#200E32",
                  fontSize: "1.5rem",
                  opacity: 0.6,
                }}
              />
            }
            isActive={pathname === "/companies"}
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <Navigation.MobileItem
          to="/researches"
          key={1}
          icon={<DiscoveryIcon />}
          hint="Исследования"
          isActive={pathname.startsWith("/researches")}
        />
      </Menu.Item>
      <Menu.Item>
        <Navigation.MobileItem
          to={company_id ? `/goal/${company_id ? company_id : ""}` : `/goal`}
          key={2}
          icon={<GraphIcon />}
          hint="Образ целевой культуры"
          isActive={pathname.startsWith("/goal")}
        />
      </Menu.Item>
      <Menu.Item>
        <Navigation.MobileItem
          to="/template"
          key={3}
          icon={<PaperIcon />}
          hint="Шаблон опросника"
          isHidden={role !== "admin"}
          isActive={pathname.startsWith("/template")}
        />
      </Menu.Item>

      <Menu.Divider />
      <Menu.ItemGroup title={username}>
        <Menu.Item key="logout" onClick={handleLogout}>
          Выйти из аккаунта
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Язык">
        <Menu.Item key="ru" onClick={() => i18n.changeLanguage("ru")}>
          Русский
        </Menu.Item>
        <Menu.Item key="en" onClick={() => i18n.changeLanguage("en")}>
          Английский
        </Menu.Item>
      </Menu.ItemGroup>
    </MobileMenu>
  );

  const MobileHeaderDropdown = () => {
    return (
      <Dropdown overlay={mobileMenu} trigger={["click"]}>
        <MenuOutlined
          style={{
            fontSize: "2rem",
          }}
        />
      </Dropdown>
    );
  };

  return (
    <div style={{ padding: "1rem 1rem 0 1rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <img src={logo} alt={t("header.title")} />
        <MobileHeaderDropdown />
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDeclaredSurvey {
  status: "finished" | "created";
  filled_survey_id?: string;
  survey_id?: string;
}

const initialState: IDeclaredSurvey = {
  status: "created",
};

const declaredSlice = createSlice({
  name: "declared",
  initialState,
  reducers: {
    setDeclaredSurvey(_, action: PayloadAction<IDeclaredSurvey>) {
      return action.payload;
    },
    setDeclaredStatus(state, action: PayloadAction<"finished" | "created">) {
      return { ...state, status: action.payload };
    },
  },
});

const { actions, reducer: declaredReducer } = declaredSlice;

export const { setDeclaredSurvey, setDeclaredStatus } = actions;

export default declaredReducer;

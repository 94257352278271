import styled from "styled-components";
import { device } from "../utils/mediaQueries";

import { LeftOutlined } from "@ant-design/icons";
import { Col, PageHeader, Row } from "antd";
import React from "react";

type ContentContainerProps = {
  title: string;
  className?: string;
  extra?: React.ReactNode;
  onBack?: () => void;
  headerContent?: React.ReactNode;
};

const AdaptiveRow = styled(Row)`
  @media ${device.mobileS} {
    max-width: 320px;
    // .ant-col {
    //   padding: 0 10px;
    // }
    .ant-page-header,
    content__head {
      padding: 0.5rem 0 !important;
    }
  }
  @media ${device.mobileM} {
    max-width: 375px;
    .ant-col {
      padding: 0 10px;
    }
  }
  @media ${device.mobileL} {
    max-width: 425px;
    .ant-col {
      padding: 0 10px;
    }
  }
  @media ${device.tablet} {
    max-width: 768px;
  }
  @media ${device.laptop},
    ${device.laptopM},
    ${device.laptopL},
    ${device.desktop} {
    max-width: 100%;
    .ant-col {
      padding: 10px 25px;
    }
  }
`;

const ContentContainer: React.FC<ContentContainerProps> = ({
  title,
  extra,
  onBack,
  className,
  headerContent,
  children,
}) => {
  return (
    <AdaptiveRow className="content">
      <Col span={22} offset={1}>
        <PageHeader
          className="content__head"
          title={title}
          extra={extra}
          onBack={onBack}
          backIcon={<LeftOutlined />}
          footer={headerContent}
        />
      </Col>
      <Col span={22} offset={1} className={`content__body ${className}`}>
        {children}
      </Col>
    </AdaptiveRow>
  );
};

export default ContentContainer;

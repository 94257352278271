import styled from "styled-components";
import { device } from "../utils/mediaQueries";

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  @media ${device.mobileL} {
    padding: 0 1rem;
  }

  @media ${device.tablet} {
    max-width: 600px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    max-width: 800px;
  }
`;

export const SurveyFooter = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 0;
  padding: 1rem;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    // Mobile-first
    order: 1;
    width: 100%;
    // Increase device resolution...
    @media ${device.tablet} {
      order: 2;
      width: auto;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    max-width: 600px;
  }

  @media ${device.laptop} {
    max-width: 800px;
    margin: 2rem 10px;
  }
`;

export const SurveyProgress = styled.div<{ percent: number }>`
  background: #ededed;
  height: 6px;
  flex-basis: 100%;
  margin: 1rem 0;
  border-radius: 6px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #200e32;
    width: ${({ percent }) => percent}%;
    height: 100%;
    border-radius: 6px;
  }
`;

export const SurveyInfo = styled.div`
  text-align: center;
  color: #72677d;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  order: 1;
  flex-direction: row;
  margin: 0.25rem 0;

  @media ${device.mobileL} {
    max-width: 390px;
  }
  @media ${device.tablet} {
    max-width: 800px;
  }
`;

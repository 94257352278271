// import "./wdyr";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import ReactDOM from "react-dom";
import "moment/locale/ru";
import moment from "moment";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import { store } from "./store";
import i18next from "./i18n";

// Локализуем даты
moment.locale("ru");

// Указываем дефолтный урл для запросов
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

// Перехватываем запросы и добавляем туда токен из хранилища
axios.interceptors.request.use(function (config) {
  const { language } = i18next;
  const token = store.getState().auth.token;
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
    config.headers!["Accept-Language"] = language;
  }
  return config;
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

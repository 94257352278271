import { useEffect } from "react";

import { useTypedDispatch, useTypedSelector } from "store";
import styled from "styled-components";

import { fetchMacrolevels, selectMacrolevel } from "../store/macrolevels/macrolevels.reducer";
import { selectMacrolevels } from "../store/macrolevels/macrolevels.selector";

const MacrolevelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #faf9f7;
  border: 1px solid #ededed;
  border-radius: 6px;
  margin-bottom: 2rem;
`;

const MacrolevelButton = styled.button<{ isActive: boolean }>`
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 18px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  background: ${({ isActive }) => (isActive ? "#ffffff" : "transparent")};
  color: ${({ isActive }) => (isActive ? "#200E32" : "#72677D")};
  box-shadow: ${({ isActive }) => (isActive ? "5px 5px 12px -5px rgba(0, 16, 44, 0.15)" : "none")};
`;

const Macrolevels = () => {
  const dispatch = useTypedDispatch();
  const macrolevels = useTypedSelector(selectMacrolevels);

  useEffect(() => {
    macrolevels.length === 0 && dispatch(fetchMacrolevels());
  }, [dispatch, macrolevels.length]);

  if (!macrolevels) return null;

  return (
    <MacrolevelsWrapper>
      {macrolevels.map((macrolevel) => (
        <MacrolevelButton
          key={macrolevel.id}
          isActive={macrolevel.checked}
          onClick={() => dispatch(selectMacrolevel(macrolevel.id))}
        >
          {macrolevel.name}
        </MacrolevelButton>
      ))}
    </MacrolevelsWrapper>
  );
};

export default Macrolevels;

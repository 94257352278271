import React, { useState } from "react";
import { IReportBoxPlot } from "store/reports/reports.reducer";
import { IDepartment } from "../../store/departments/departments.reducer";

import styled from "styled-components";

import { Layout, Typography } from "antd";

const BoxWrapper = styled(Layout)`
  flex-direction: column;
  background: #fff;
  max-height: 120px;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
`;

const Box = styled.div<{ width: number }>`
  display: flex;
  border-radius: 3px;
  background: #c0bbc5;
  height: 24px;
  width: ${({ width }) => (width ? `${width}px` : "500px")};
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
`;

const BoxRange = styled.div<{
  color: string | undefined;
  width: number;
  lMargin: number;
  rMargin: number;
}>`
  display: inline-flex;
  box-sizing: border-box;
  background: ${({ color }) =>
    color ? `${color} !important` : "#1890ff !important"};
    width: 100%;
  height: 100%;
  margin: 0;
  margin-left: ${({ lMargin }) => `${lMargin}px`};
  margin-right: ${({ rMargin }) => `${rMargin}px`}};
`;

const QMark = styled(Layout)<{
  q1: number;
  q3: number;
  median?: number;
  declaredCulture?: number;
}>`
  background: #fff !important;
  outline: 1px solid #000;
  box-shadow: 0 0 2px 1px #000;
  justify-content: space-between;
  flex: 0;
  min-width: 3px;
  :before {
    position: relative;
    top: 25px;
    // left: -5px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  // корректировка положения медианы для симметрии
  &.median {
    position: relative;
    left: -5px;
  }

  //? маркер q1
  &.q1-marker {
    :before {
      content: ${({ q1 }) => `"${q1}"`};
    }
  }
  //? маркер median
  &.median {
    :before {
      content: ${({ median }) => `"${median}"`};
    }
  }

  //? маркер q3
  &.q3-marker {
    :before {
      content: ${({ q3 }) => `"${q3}"`};
    }
  }

  &.declared-culture {
    :before {
      visibility: ${({ declaredCulture }) =>
        declaredCulture ? "visible" : "hidden"};
      content: ${({ declaredCulture }) => `"${declaredCulture}"`};
    }
  }
`;

const DCMark = styled.span<{ declaredCulture?: number }>`
  display: ${({ declaredCulture }) => (declaredCulture ? "flex" : "none")};
  // visibility: visible;
  // position: relative;
  // width: 4px;

  :before {
    position: relative;
    height: 77px;
    width: 4px;
    content: ${() => `""`};
    top: -15px;
    left: ${({ declaredCulture }) =>
      declaredCulture ? `${declaredCulture}px` : 0};
    background: #000;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
`;

const BoxLegend = styled(Typography.Text)`
  :first-of-type {
    justify-content: flex-start;
  }
  display: flex;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  color: #72677d;
  width: 250px;
`;

interface BoxItemProps {
  departments: IDepartment[];
  box: IReportBoxPlot;
  min: number;
  max: number;
  q1: number;
  q3: number;
  width: number;
  declaredCulture: number;
}

export const NewHorBox: React.FC<BoxItemProps> = ({
  departments,
  box,
  min,
  max,
  q1,
  q3,
  width,
}) => {
  // длина диапазона в единицах измерения
  const trackLength = max - min; // 2 - (-2) = 4
  const rangeLength = q3 - q1; // 1 - (-1) = 2
  // длина одной единицы в px
  const lengthOfOneInPx = width / trackLength; // 500 / 4 = 125
  // отступы для диапазона от границ plot
  const lMargin = Math.abs(min - q1) * lengthOfOneInPx; // | -2 - (-1) | * 125 = 125
  const rMargin = (max - Math.abs(q3)) * lengthOfOneInPx; // 2 - | 1 | * 125 = 125
  // отступ для медианы
  const mMargin = Math.abs(box.median - q1) * lengthOfOneInPx; // | 0 - (-1) | * 125 = 125

  const color = departments.find((d) => d.id === box.department_id)?.color;

  // console.log("box", box.value);

  //? --------------------------------------------------------------------------------------------------------
  const plotLOne = 5; // длина отрезка в первой системе координат (0 - 5)
  const plotLTwo = 4; // длина отрезка во второй системе координат (-2 - 2)
  const commonDenominator = plotLOne * plotLTwo; // наименьший общий делитель: произведение длин отрезков в разных системах координат

  // приведём входящее значение к общему знаменателю, приведём к доле от общей длины и затем умножим на общую длину в px
  const declaredCulture = box.value
    ? Math.round(box.value * plotLTwo * (1 / commonDenominator) * width)
    : 0; // 5 * 4(20/20) * 1/20 * 500
  //? --------------------------------------------------------------------------------------------------------

  return (
    <BoxWrapper>
      <BoxLegend>{box.x_bottom}</BoxLegend>

      <Box width={width}>
        <DCMark declaredCulture={declaredCulture} />

        <BoxRange
          className="range"
          color={color}
          width={rangeLength}
          lMargin={lMargin}
          rMargin={rMargin}
        >
          {box.median === q3 ? (
            <>
              <QMark
                className="q1-marker"
                q1={q1}
                q3={q3}
                style={{ marginRight: "auto" }}
              />
              <QMark
                className="q3-marker"
                q1={q1}
                q3={q3}
                style={{ marginLeft: "auto" }}
              />
            </>
          ) : (
            <>
              <QMark className="q1-marker" q1={q1} q3={q3} />
              <QMark
                className="median"
                q1={q1}
                q3={q3}
                median={box.median}
                style={{ margin: `auto`, marginLeft: `${mMargin}px` }}
              />
              <QMark className="q3-marker" q1={q1} q3={q3} />
            </>
          )}
        </BoxRange>
      </Box>

      <BoxLegend>{box.x_top}</BoxLegend>
    </BoxWrapper>
  );
};

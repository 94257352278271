import axios from "axios";
import { IMacrolevel } from "../store/macrolevels/macrolevels.reducer";

const fetch = async (): Promise<IMacrolevel[]> => {
  try {
    const response = await axios(`/macrolevels`);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка загрузки списка макроуровней");
  }
};

export const MacrolevelsAPI = { fetch };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  isLoading: boolean;
  language: string;
}

const initialState: AppState = {
  isLoading: false,
  language: localStorage.getItem("language") || navigator.language,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
  },
});

const { actions, reducer: appReducer } = appSlice;

export const { loading } = actions;

export default appReducer;

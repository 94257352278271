import React, { useState } from "react";

import { Button, Checkbox, Form, Input, Space, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "store";
import { selectIndicators } from "../../store/indicators/indicators.selector";
import { IQuestionScale } from "../../store/questions/questions.reducer";

import QuestionHeader from "../QuestionHeader";

interface ScaleTypeProps extends IQuestionScale {
  position: number;
  onSave: (values: object) => Promise<boolean>;
}

const ScaleType: React.VFC<ScaleTypeProps> = (question) => {
  const { onSave, position } = question;
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const indicators = useTypedSelector(selectIndicators);

  /**
   * Функция отправляет данные формы на сохранение,
   * в случае успеха - закрывает редактирование
   * @param values Значения полей формы
   */
  const handleSave = (values: any) => {
    onSave(values).then((status) => setIsEditable(!status));
  };

  const getNameIndicator = (id: number): string | undefined => {
    return indicators.find((indicator) => indicator.id === id)?.pole;
  };

  return (
    <div className="question">
      <QuestionHeader
        position={position}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        question={question}
      />
      <div className="question__body">
        {isEditable ? (
          <Form layout="vertical" initialValues={question} onFinish={handleSave}>
            <Form.Item label={t("templates.title")} name="text" rules={[{ required: true }]}>
              <Input placeholder={t("templates.title")} />
            </Form.Item>
            <Form.Item
              label={t("templates.assigned_to_indicator")}
              name="indicator_id"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                optionFilterProp="label"
                options={indicators.map((item) => {
                  return { label: item.pole, value: item.id };
                })}
              />
            </Form.Item>
            <Form.Item name={["parameters", "reverse_scale"]} valuePropName="checked">
              <Checkbox>{t("templates.inversion_of_scale")}</Checkbox>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="default" onClick={() => setIsEditable(false)}>
                  {t("app.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("app.save")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <div className="question__body-preview">
            <p>{question.text}</p>
            <p>{question.parameters.hint}</p>
            <p>
              {t("templates.inversion")}: {question.parameters.reverse_scale ? "Да" : "Нет"}
            </p>
            <p>
              {t("templates.indicator")}: {getNameIndicator(question.indicator_id)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScaleType;

import React, { useEffect, useRef } from "react";
import { Input, InputNumber } from "antd";
import { useTypedDispatch } from "store";

import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";
import { IQuestionText, updateQuestion } from "../../store/questions/questions.reducer";

const TextType: React.FC<IQuestionText> = (question) => {
  const { text, parameters, answer } = question;
  const dispatch = useTypedDispatch();
  const input = useRef<any>(null);

  // Перехватываем Enter и Backspace
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Backspace") {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    input.current?.focus();
  }, []);

  const updateValue = (answer?: string | number) => {
    if (answer !== "") {
      dispatch(updateQuestion({ ...question, answer }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
    }
  };

  const handleChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateValue(value);
  };

  const handleChangeNumber = (value: number) => {
    updateValue(value);
  };

  return (
    <QuestionWrapper>
      <QuestionTitle>{text}</QuestionTitle>
      <QuestionBody>
        {parameters.validation === "number" ? (
          <InputNumber
            ref={input}
            value={answer as number}
            onKeyDownCapture={handleKeyDown}
            onChange={handleChangeNumber}
          />
        ) : (
          <Input
            ref={input}
            value={answer as string}
            onKeyDownCapture={handleKeyDown}
            onChange={handleChangeString}
          />
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default TextType;

import { Row, Col, Result, Button } from "antd";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Row justify="space-around" align="middle" className="h100">
      <Col span={12}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/researches">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </Col>
    </Row>
  );
};

export default NotFound;

import { DatePicker, Form, FormInstance, Input, Select } from "antd";
import React from "react";
import locale from "antd/es/date-picker/locale/ru_RU";

type FormCreateResearchProps = {
  form: FormInstance;
  companies: any[];
  values: any;
  onFinish: (values: any) => void;
  isHiddenDatetime?: boolean;
};

const FormCreateResearch: React.FC<FormCreateResearchProps> = ({
  companies,
  form,
  values,
  onFinish,
  isHiddenDatetime = false,
}) => {
  return (
    <Form role="form" form={form} layout="vertical" initialValues={values} onFinish={onFinish}>
      <Form.Item label="Название исследования" name="name">
        <Input placeholder="Название исследования" />
      </Form.Item>
      <Form.Item label="Компания" name="company_id" hidden={!!values.company_id}>
        <Select id="company_id" placeholder="Выберите компанию" options={companies} />
      </Form.Item>
      <Form.Item label="Дата и время начала" name="start_date" hidden={isHiddenDatetime}>
        <DatePicker locale={locale} showTime format="DD.MM.YYYY HH:mm" />
      </Form.Item>
    </Form>
  );
};

export default FormCreateResearch;

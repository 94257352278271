import { configResponsive, useResponsive } from "ahooks/lib/useResponsive";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

const Header = () => {
  configResponsive({
    desktop: 768,
  });

  const responsive = useResponsive();
  const isDesktop = responsive.desktop;

  return isDesktop ? <DesktopHeader /> : <MobileHeader />;
};

export default Header;

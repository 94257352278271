import styled from "styled-components";
import { device } from "../../utils/mediaQueries";

export const QuestionWrapper = styled.div`
  width: 100%;

  @media ${device.tablet} {
    max-width: 600px;
  }
  @media ${device.laptop} {
    max-width: 800px;
  }
`;

export const QuestionTitle = styled.div`
  color: #200e32;
  font-weight: bold;
  font-size: clamp(12px, 16px, 20px);
  line-height: 26px;
  margin: 1rem;
  width: 100%;

  @media ${device.tablet} {
    max-width: 600px;
  }
  @media ${device.laptop} {
    max-width: 800px;
  }
`;

export const QuestionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  .ant-slider:hover {
    .ant-slider-track {
      background: transparent;
    }
  }
  .ant-slider-track {
    background: transparent;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    .ant-radio-button-wrapper {
      &::before {
        display: none;
      }
      &.ant-radio-button-wrapper-checked {
        background: transparent;
        border: 1px solid #200e32 !important;
        span {
          color: #200e32 !important;
        }
      }
      border: 1px solid #e0dbd1;
      border-radius: 8px;
      margin-bottom: 6px;
      height: 52px;
      padding: 9px 16px;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #72677d;
      }
    }
  }

  @media ${device.tablet} {
    max-width: 600px;
  }
  @media ${device.laptop} {
    max-width: 800px;
  }
`;

export const QuestionBoldText = styled.div<{ color?: string }>`
  color: ${({ color }) => color || "#72677D"};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;

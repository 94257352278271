import axios from "axios";
import { ICompany } from "../store/companies/companies.reducer";
import { IGoal } from "../store/goal/goal.reducer";

const fetch = async (): Promise<ICompany[]> => {
  try {
    const response = await axios(`/companies`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const fetchGoals = async (company_id: number) => {
  try {
    const response = await axios.get<{ declared_culture: IGoal[] }>(
      `/companies/${company_id}/goal`
    );
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить декларативную культуру");
  }
};

const updateGoals = async (company_id: number, goals: IGoal[]): Promise<IGoal[]> => {
  const goalsPartial = goals.map((item) => {
    return {
      indicator_id: item.indicator_id,
      is_important: item.is_important,
      value: item.value,
    };
  });
  try {
    const response = await axios.put(`/companies/${company_id}/goal`, goalsPartial);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить целевую культуру");
  }
};

const create = async (values: any) => {
  try {
    const response = await axios.post<ICompany>(`/companies`, values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось создать новую компанию");
  }
};

const update = async (company: ICompany) => {
  try {
    const response = await axios.put<ICompany>(`/companies/${company.id}`, company);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить компанию");
  }
};

export const CompaniesAPI = { fetch, fetchGoals, create, update, updateGoals };

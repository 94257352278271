import { Form, message, Modal, Tabs } from "antd";
import React, { useState } from "react";
import moment, { Moment } from "moment";

import { useTypedDispatch, useTypedSelector } from "store";
import { useTranslation } from "react-i18next";

import { selectOptionsCompanies } from "../../store/companies/companies.selector";
import { addSurvey } from "../../store/surveys/surveys.reducer";
import { SurveysAPI } from "../../api/surveys.api";

import FormCreateResearch from "../forms/FormCreateResearch";

const { TabPane } = Tabs;

type ModalCreateResearchProps = {
  isVisible: boolean;
  company_id?: number;
  onOk?: (name: string, startDate: Moment, company_id: number) => void;
  onCancel: () => void;
};

const ModalCreateResearch: React.FC<ModalCreateResearchProps> = ({
  company_id,
  isVisible,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [form] = Form.useForm();
  const [okText, setOkText] = useState("Начать");
  const companiesOptions = useTypedSelector(selectOptionsCompanies);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Обработываем создание нового исследования
   * @param name Название исследования
   * @param startDate Дата начала
   * @param company_id ID компании
   */
  const handleAddResearch = async (values: any) => {
    try {
      setIsLoading(true);
      const survey = await SurveysAPI.create(values);
      if (survey) {
        dispatch(addSurvey(survey));
        onCancel();
      }
    } catch (error) {
      message.error(t("app.wrong"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="schedule-modal"
      title="Провести новое исследование"
      cancelText="Отмена"
      okText={okText}
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Tabs
        defaultActiveKey="now"
        onChange={(key) =>
          setOkText(key === "now" ? "Начать" : "Запланировать")
        }
      >
        <TabPane tab="Начать сейчас" key="now">
          <FormCreateResearch
            companies={companiesOptions}
            form={form}
            values={{ company_id, start_date: moment() }}
            onFinish={handleAddResearch}
            isHiddenDatetime={true}
          />
        </TabPane>
        <TabPane tab="Запланировать на будущее" key="schedule">
          <FormCreateResearch
            companies={companiesOptions}
            form={form}
            values={{ company_id, start_date: moment() }}
            onFinish={handleAddResearch}
          />
        </TabPane>
      </Tabs>
      <p>
        Вы получите на электронную почту ссылку на опросник. Этой ссылкой
        необходимо поделиться со всеми сотрудниками вашей организации. Система
        будет отслеживать ход заполнения анкет и информировать вас о дальнейших
        шагах.
      </p>
    </Modal>
  );
};

export default ModalCreateResearch;

import axios from "axios";
import { IAccount } from "../store/accounts/accounts.reducer";

const fetch = async (company_id: number): Promise<IAccount[]> => {
  try {
    const response = await axios(`/companies/${company_id}/users`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const remove = async (account_id: number): Promise<boolean> => {
  try {
    const response = await axios.delete(`/users/${account_id}`);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const create = async (company_id: number, values: any): Promise<IAccount | undefined> => {
  try {
    const response = await axios.post(`/companies/${company_id}/users`, values);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.result);
  }
};

const update = async (id: number, values: any): Promise<IAccount | undefined> => {
  try {
    const response = await axios.put(`/users/${id}`, values);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.result);
  }
};

export const AccountsAPI = { fetch, remove, update, create };

import styled from "styled-components";
import { Checkbox, Slider, Space } from "antd";

import { useTypedDispatch } from "store";
import { IGoal, updateGoal } from "store/goal/goal.reducer";

import { Cell } from "../shared/Grid";

const MyCell = styled(Cell)`
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 15px;
  grid-area: ${({ area }) => area};
  h5 {
    color: #2c2c2c;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;

const MySlider = styled(Slider)`
  margin-top: 0 !important;
  .ant-slider-track {
    background: none;
  }
`;

interface IDeclaredCultureScale {
  goal: IGoal;
}

export const DeclaredCultureScale: React.FC<IDeclaredCultureScale> = ({ goal }) => {
  const dispatch = useTypedDispatch();

  const handleCheckBox = () => {
    // отправим новые значения шкалы при установке чекбокса
    dispatch(
      updateGoal({
        indicator_id: goal.indicator_id,
        is_important: !goal.is_important,
      })
    );
  };

  const handleSlider = (value: number) => {
    // отправим новые значения шкалы по завершении изменения значения
    dispatch(updateGoal({ indicator_id: goal.indicator_id, value: value }));
  };

  return (
    <MyCell>
      <Space>
        <Checkbox checked={!!goal.is_important} onChange={handleCheckBox} />
        <h5>{`${goal.x_bottom} VS ${goal.x_top}`}</h5>
      </Space>
      <MySlider
        min={1}
        max={5}
        step={1}
        marks={goal.answers}
        defaultValue={goal.value}
        onAfterChange={(value) => handleSlider(value)}
      />
    </MyCell>
  );
};

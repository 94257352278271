import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";

import { ISurvey, ISurveyByDepartment } from "../../store/surveys/surveys.reducer";
import { DepartmentsAPI } from "../../api/departments.api";

import DepartmentReport from "./DepartmentReport";
import GaugePanel from "./GaugePanel";
import StatusMessage from "./StatusMessage";

type CommonReportProps = {
  survey: ISurvey;
};

const LegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: #939393;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 10px 10px 0;
  &::before {
    content: "";
    display: block;
    min-width: 15px;
    min-height: 15px;
    background: ${({ color }) => color};
    margin-right: 10px;
    border-radius: 2px;
  }
  span {
    color: ${({ color }) => color};
    display: contents;
  }
`;

const CommonReport: React.FC<CommonReportProps> = ({ survey }) => {
  const [data, setData] = useState<ISurveyByDepartment[]>([]);

  useEffect(() => {
    (async () => {
      const response = await DepartmentsAPI.getStatistics(survey.id);
      response && setData(response);
    })();
  }, [survey.id]);

  const percentLevel = (percent: number): string => {
    if (percent >= 0 && percent < 0.3) {
      return "Низкий";
    } else if (percent >= 0.3 && percent < 0.6) {
      return "Средний";
    } else if (percent >= 0.6) {
      return "Высокий";
    } else {
      return "";
    }
  };

  // Если не указано общее количество сотрудников, берем за 100%

  const finishedPercent =
    survey.employee_count > survey.finished_count
      ? survey.finished_count / survey.employee_count
      : 1;

  const startedPercent =
    survey.employee_count > survey.started_count ? survey.started_count / survey.employee_count : 1;

  return (
    <Row className="report__common">
      <Col span={8}>
        <StatusMessage survey={survey} />
      </Col>
      <Col span={15} offset={1}>
        <Row>
          <Col span={8}>
            <h3>Статистика заполняемости анкет</h3>
            <LegendItem color="#25BA84">
              Процент анкет, <span>заполненных полностью</span>
            </LegendItem>
            <LegendItem color="#2C75F7">
              Процент анкет, <span>заполнение которых начато</span>
            </LegendItem>
          </Col>
          <Col span={7} offset={1}>
            <GaugePanel
              percent={finishedPercent}
              color="#25BA84"
              title={() =>
                `<div class="gauge">
                  <div class="gauge__title green">Заполненные</div>
                  <div class="gauge__subtitle">${percentLevel(finishedPercent)} уровень</div>
                </div>`
              }
            />
          </Col>
          <Col span={7} offset={1}>
            <GaugePanel
              percent={startedPercent}
              color="#2C75F7"
              title={() =>
                `<div class="gauge">
                  <div class="gauge__title blue">Начатые</div>
                  <div class="gauge__subtitle">${percentLevel(startedPercent)} уровень</div>
                </div>`
              }
            />
          </Col>
        </Row>
      </Col>
      <Divider style={{ marginTop: "2.5rem" }} />
      <Col span={24}>
        <DepartmentReport title="Статистика заполнения анкет по подразделениям" data={data} />
      </Col>
    </Row>
  );
};

export default CommonReport;

import React, { useState } from "react";
import { Input, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useTypedDispatch } from "store";
import { useTranslation } from "react-i18next";

import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";
import { IQuestionRadio, updateQuestion } from "../../store/questions/questions.reducer";

const RadioType: React.VFC<IQuestionRadio> = (question) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [isRequiredComment, setIsRequiredComment] = useState(false);

  const options = question.parameters.options || [];

  const handleAnswer = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (!value.require_comment) {
      setIsRequiredComment(false);
      dispatch(updateQuestion({ ...question, answer: value.value }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
      setIsRequiredComment(true);
    }
  };

  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      dispatch(updateQuestion({ ...question, answer: event.target.value }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
    }
  };

  return (
    <QuestionWrapper data-testid="radio">
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        <Radio.Group buttonStyle="solid" onChange={handleAnswer}>
          {options.map((option, key: number) => (
            <Radio.Button key={key} value={option}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        {isRequiredComment && (
          <Input
            required
            placeholder={t("questions.types.comment")}
            onChange={handleComment}
            style={{ marginTop: "1rem" }}
          />
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default RadioType;

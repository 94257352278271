import { Button } from "antd";
import { useCallback, useEffect } from "react";
import {
  SurveyFooter,
  SurveyInfo,
  SurveyProgress,
  SurveyWrapper,
} from "../shared/survey.styled";
import { IQuestion } from "../store/questions/questions.reducer";
import QuestionForm from "./questions/QuestionForm";

type SurveyProps = {
  page: number;
  questions: IQuestion[];
  onNext: () => void;
  onBack: () => void;
  onSubmit: () => void;
};

const Survey: React.FC<SurveyProps> = ({
  questions,
  page,
  onNext,
  onBack,
  onSubmit,
}) => {
  // Считаем количество отвеченных вопросов
  const answeredCount = questions.filter(
    (question) => question.answer !== undefined
  ).length;
  // Считаем прогресс
  const progress = Math.round((answeredCount / questions.length) * 100);
  // Проверяем является ли текущая страница последняя
  const isLastPage = questions.length - 1 === page;
  // Статус ответа
  const isAnswered = questions[page]?.answer !== undefined;
  // Перехватываем Enter и Backspace
  const handleKeyDown = useCallback(
    (ev) => {
      if (ev.code === "Enter" && isAnswered) {
        if (isLastPage) {
          onSubmit();
        } else {
          onNext();
        }
      }
      if (ev.code === "Backspace" && page > 0) {
        onBack();
      }
    },
    [isAnswered, isLastPage, onBack, onNext, onSubmit, page]
  );

  // Ловим ответы с клавиатуры
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <SurveyWrapper>
      {questions
        .concat()
        .sort((a, b) => a.order_number - b.order_number)
        .slice(page, page + 1)
        .map((question, key) => (
          <QuestionForm key={key} {...question} />
        ))}
      <SurveyFooter className="survey-footer">
        <SurveyProgress percent={progress} />
        <Button disabled={page === 0} onClick={onBack}>
          Предыдущий
        </Button>
        <SurveyInfo data-testid="survey-info">
          Вопрос {page + 1} из {questions.length}
          <br />
          <b>Заполнено на {progress}%</b>
        </SurveyInfo>
        {isLastPage ? (
          <Button type="primary" onClick={onSubmit} data-testid="submit-button">
            Отправить
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={!isAnswered}
            onClick={onNext}
            data-testid="next-button"
          >
            Следующий
          </Button>
        )}
      </SurveyFooter>
    </SurveyWrapper>
  );
};

export default Survey;

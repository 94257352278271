import axios from "axios";
import { IDepartment } from "../store/departments/departments.reducer";
import { ISurveyByDepartment } from "../store/surveys/surveys.reducer";

type DepartmentFetchParams = {
  company_id?: number;
  survey_id?: string;
};

const fetch = async (params: DepartmentFetchParams): Promise<IDepartment[]> => {
  try {
    const response = await axios(`/departments`, { params });
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getStatistics = async (
  survey_id: string
): Promise<ISurveyByDepartment[]> => {
  try {
    const response = await axios(`/surveys/${survey_id}/departments`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось загрузить департаменты");
  }
};

const remove = async (id: number): Promise<boolean> => {
  try {
    const response = await axios.delete(`/departments/${id}`);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const update = async (id: number, values: any) => {
  try {
    const response = await axios.put(`/departments/${id}`, values);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить департаменты");
  }
};

const create = async (company_id: number, values: any) => {
  // если employee_count === 0, не передаём свойство на сервер
  const normalizedValues = values.employee_count
    ? { ...values }
    : (({ employee_count, ...values }: any) => values)(values);

  try {
    const response = await axios.post(
      `/companies/${company_id}/departments`,
      normalizedValues
    );
    return response.data;
  } catch (error) {
    throw new Error("Не удалось добавить новый департамент");
  }
};

export const DepartmentsAPI = { fetch, getStatistics, create, remove, update };

import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CompaniesAPI } from "../api/companies.api";

import { useTypedDispatch, useTypedSelector } from "store";
import { selectCompanyById } from "../store/companies/companies.selector";
import { selectCompanyGoal } from "../store/goal/goal.selector";
import { selectSurveysByCompanyId } from "../store/surveys/surveys.selector";
import { selectDepartments } from "../store/departments/departments.selector";
import { selectAccounts } from "../store/accounts/accounts.selector";
import { fetchAccounts } from "../store/accounts/accounts.reducer";
import { fetchDepartments } from "../store/departments/departments.reducer";

import { Button, Tabs, message } from "antd";
import ContentContainer from "../containers/ContentContainer";
import ResearchesTable from "../components/tables/ResearchesTable";
import AccountsTable from "../components/tables/AccountsTable";
import DepartmentsTable from "../components/tables/DepartmentsTable";
import ModalCreateDepartment from "../components/modals/ModalCreateDepartment";
import ModalCreateAccount from "../components/modals/ModalCreateAccount";
import ModalCreateResearch from "../components/modals/ModalCreateResearch";
import { CorporateCulture } from "../components/CorporateCulture";

const Company = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { company_id } = useParams<"company_id">();
  const accounts = useTypedSelector(selectAccounts);
  const departments = useTypedSelector(selectDepartments);
  const company = useTypedSelector(selectCompanyById(Number(company_id)));
  const goals = useTypedSelector(selectCompanyGoal);
  const filteredSurveys = useTypedSelector(selectSurveysByCompanyId(Number(company_id)));
  const [activeKey, setActiveKey] = useState("researches");
  const [selectedModal, setSelectedModal] = useState("");

  useEffect(() => {
    dispatch(fetchAccounts(Number(company_id)));
    dispatch(fetchDepartments(Number(company_id)));
  }, [company_id, dispatch]);

  if (!company) return null;

  interface ITab {
    name: string;
    single_name: string;
    key: string;
    cmp?: ReactElement;
    modal?: ReactElement;
    isDisabled?: boolean;
  }

  const tabs: ITab[] = [
    {
      name: "Исследования",
      single_name: "исследование",
      key: "researches",
      cmp: <ResearchesTable surveys={filteredSurveys} />,
      modal: (
        <ModalCreateResearch
          company_id={Number(company_id)}
          isVisible={selectedModal === activeKey}
          onCancel={() => setSelectedModal("")}
        />
      ),
    },
    {
      name: "Аккаунты",
      single_name: "аккаунт",
      key: "accounts",
      cmp: <AccountsTable accounts={accounts} />,
      modal: (
        <ModalCreateAccount
          company_id={Number(company_id)}
          isVisible={selectedModal === activeKey}
          onCancel={() => setSelectedModal("")}
        />
      ),
    },
    {
      name: "Отделы",
      single_name: "отдел",
      key: "departments",
      cmp: <DepartmentsTable departments={departments} />,
      modal: (
        <ModalCreateDepartment
          company_id={Number(company_id)}
          isVisible={selectedModal === activeKey}
          onCancel={() => setSelectedModal("")}
        />
      ),
    },
    {
      name: "Декларативная культура",
      single_name: "декларативная культура",
      key: "declarative_culture",
      isDisabled: !company.use_target_corporate_culture,
      cmp: <CorporateCulture company_id={Number(company_id)} />,
    },
  ];

  const selectedTab = tabs.find((t) => t?.key === activeKey)!;

  // handler для кнопки сохранить "Декларативной культуры"
  const updateGoals = () => {
    CompaniesAPI.updateGoals(Number(company_id), goals.entities);
    message.success("Изменения сохранены");
  };

  const tabBarExtraContent = {
    right:
      activeKey !== "declarative_culture" ? (
        <Button size="small" onClick={() => setSelectedModal(selectedTab.key)}>
          Добавить {selectedTab.single_name}
        </Button>
      ) : (
        <Button size="small" onClick={updateGoals}>
          Сохранить
        </Button>
      ),
  };

  return (
    <ContentContainer onBack={() => navigate(-1)} title={company.name} className="company">
      <Tabs activeKey={activeKey} onChange={setActiveKey} tabBarExtraContent={tabBarExtraContent}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key} disabled={tab.isDisabled}>
            {tab.cmp}
          </Tabs.TabPane>
        ))}
      </Tabs>
      {selectedTab.modal}
    </ContentContainer>
  );
};

export default Company;

import { useEffect, useRef, useState, useMemo } from "react";
import { Button, message, Space, Tabs, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "store";
import { Stage as StageRef } from "konva/lib/Stage";

import { ISurvey, updateSurvey } from "store/surveys/surveys.reducer";
import { selectCompanyById } from "store/companies/companies.selector";
import { selectAuth } from "store/auth/auth.selector";
import { fetchSurveyDepartments } from "store/departments/departments.reducer";
import { SurveysAPI } from "api/surveys.api";

import ContentContainer from "containers/ContentContainer";
import CommonReport from "components/research/CommonReport";
import OcaiReport from "components/research/OcaiReport";
import BoxReport from "components/research/BoxReport";
import { NewBoxReport } from "components/research/NewBoxReport";
import { resetCompanyGoal, toggleGoal } from "store/goal/goal.reducer";
import { Interpretations } from "components/research/Interpretations";

const Research = () => {
  const { survey_id } = useParams<"survey_id">();
  const stageRef = useRef<StageRef>(null);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<ISurvey>();
  const [activeKey, setActiveKey] = useState("common");
  const [isLoading, setIsLoading] = useState(false);
  const company = useTypedSelector(selectCompanyById(survey?.company_id));
  const { role } = useTypedSelector(selectAuth);

  const extra = [
    <Button key="new" className="extra-button" type="primary" size="small">
      Отменить исследование
    </Button>,
  ];

  // Загружаем дамп департаментов для исследования
  useEffect(() => {
    survey_id && dispatch(fetchSurveyDepartments(survey_id));
  }, [dispatch, survey_id]);

  // Получаем данные об исследовании
  useEffect(() => {
    if (!survey_id) return;
    (async () => {
      try {
        const survey = await SurveysAPI.fetchById(survey_id);
        setSurvey(survey);
      } catch (error) {
        message.error("Не удалось загрузить информацию о исследовании");
      }
    })();
  }, [survey_id]);

  // Отключаем декларативную культуру при выходе из отчета исследования
  useEffect(() => {
    return () => {
      dispatch(resetCompanyGoal());
      dispatch(toggleGoal(false));
    };
  }, [dispatch]);

  const handleDownloadReport = async () => {
    if (activeKey === "common" && survey_id) {
      // Получаем детальный отчет по компании
      const blob = await SurveysAPI.getReport(survey_id);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `report_${new Date().getTime()}.xlsx`;
      link.click();
    }
    // TODO: Скачать PDF отчет
  };

  const handleChangeTab = (key: string) => {
    setActiveKey(key);
  };

  const handlePublishReport = async () => {
    const report_created_date = new Date().toISOString();
    if (survey_id) {
      try {
        setIsLoading(true);
        const updatedSurvey = await SurveysAPI.update(survey_id, {
          report_created_date,
        });
        if (updatedSurvey) {
          dispatch(updateSurvey(updatedSurvey));
          message.success(`Отчет успешно опубликован`);
        }
      } catch (error) {
        message.error("Что-то пошло не так...");
      } finally {
        const survey = await SurveysAPI.fetchById(survey_id);
        setIsLoading(false);
        setSurvey(survey);
      }
    }
  };

  const checkIfTabIsDisabled = useMemo(() => {
    if (survey) {
      if (["not_started", "started"].includes(survey.status)) return true;
      if (role === "hr" && survey.report_created_date === null) return true;
    }
    return false;
  }, [role, survey]);

  // вынесем логику определения видимости кнопок для разных ролей и условий из TSX
  const adminViewable = survey?.status === "stopped" && role === "admin";
  const hrViewable =
    survey?.status === "stopped" &&
    role === "hr" &&
    !survey.report_created_date;

  const tabBarExtraContent = {
    right: (
      <Space direction={"horizontal"} size={"middle"}>
        {role === "admin" && survey?.status === "stopped" && (
          <Button
            size="small"
            disabled={survey && !!survey.report_created_date}
            loading={isLoading}
            onClick={handlePublishReport}
          >
            {survey && !!survey.report_created_date
              ? "Опубликовано"
              : "Опубликовать"}
          </Button>
        )}
        {adminViewable && (
          <Button size="small" onClick={handleDownloadReport}>
            Скачать {activeKey === "common" ? "полный отчет" : ""}
          </Button>
        )}
        {hrViewable && (
          <Button size="small" onClick={handleDownloadReport}>
            Скачать {activeKey === "common" ? "полный отчет" : ""}
          </Button>
        )}
      </Space>
    ),
  };

  if (!survey || !company) return <Spin size={"large"} />;

  return (
    <ContentContainer
      title={survey?.name}
      extra={extra}
      className="report"
      onBack={() => navigate(`/researches`)}
    >
      <Tabs
        activeKey={activeKey}
        onChange={handleChangeTab}
        tabBarExtraContent={tabBarExtraContent}
      >
        <Tabs.TabPane key="common" tab="Общая информация">
          <CommonReport survey={survey} />
        </Tabs.TabPane>

        <Tabs.TabPane key="graph" tab="График" disabled={checkIfTabIsDisabled}>
          <BoxReport survey={survey} company={company} stageRef={stageRef} />
        </Tabs.TabPane>
        <Tabs.TabPane key="new_graph" tab="Новый график">
          <NewBoxReport survey={survey} company={company} stageRef={stageRef} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="ocai"
          tab="Модель OCAI"
          disabled={checkIfTabIsDisabled}
        >
          <OcaiReport company={company} />
        </Tabs.TabPane>
        <Tabs.TabPane key="interpretations" tab="Интерпретации">
          <Interpretations />
        </Tabs.TabPane>
      </Tabs>
    </ContentContainer>
  );
};

export default Research;

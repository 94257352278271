import { Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import styled from "styled-components";

interface TableContainerProps<T = any> {
  dataSource: any[];
  columns: ColumnsType<T>;
  gridTemplateColumns?: string;
}

const StyledTableContainer = styled(Table)<TableContainerProps>`
  .ant-table-thead tr {
    display: grid;
    grid-template-columns: ${({ columns, gridTemplateColumns }) =>
      gridTemplateColumns
        ? gridTemplateColumns
        : `repeat(${columns?.length}, 1fr)`};
  }
  .ant-table-tbody tr {
    display: grid;
    grid-template-columns: ${({ columns, gridTemplateColumns }) =>
      gridTemplateColumns
        ? gridTemplateColumns
        : `repeat(${columns?.length}, 1fr)`};
    td:last-child {
      justify-content: flex-end;
    }
  }
`;

const TableContainer: React.FC<TableContainerProps> = ({
  dataSource,
  columns,
  gridTemplateColumns,
}) => {
  return (
    <StyledTableContainer
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={{ position: ["bottomCenter"] }}
      gridTemplateColumns={gridTemplateColumns}
    />
  );
};

export default TableContainer;

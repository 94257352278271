import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, RadioChangeEvent, Space, Switch } from "antd";

import { useTypedDispatch, useTypedSelector } from "store";

import DepartmentFilter from "./DepartmentFilter";
import FilterModeSelector from "./FilterModeSelector";

import ModalFilterReport from "../modals/ModalFilterReport";

import {
  IDepartment,
  setDepartments,
} from "store/departments/departments.reducer";
import {
  EFilterMode,
  ECompareMode,
  setFilterMode,
  setCompareMode,
} from "store/reports/reports.reducer";
import {
  fetchCompanyGoal,
  resetCompanyGoal,
  toggleGoal,
} from "store/goal/goal.reducer";
import { ICompany } from "store/companies/companies.reducer";

import {
  FilterBody,
  FilterTitle,
  FilterWrapper,
  RadioGroup,
} from "shared/Filter";

import legendImage from "assets/legend.png";
import { selectCompanyGoal } from "store/goal/goal.selector";

type MacrolevelFilterProps = {
  companyId: ICompany["id"];
  goalEnabled: ICompany["use_target_corporate_culture"];
  departments: IDepartment[];
  compareMode: ECompareMode;
  filterMode: EFilterMode;
};

const MacrolevelFilter: React.FC<MacrolevelFilterProps> = ({
  departments,
  compareMode,
  filterMode,
  companyId,
  goalEnabled,
}) => {
  const dispatch = useTypedDispatch();
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isVisibleLegend, setIsVisibleLegend] = useState(false);
  const { loading, enabled, entities } = useTypedSelector(selectCompanyGoal);

  // useEffect(() => {
  //   console.log("selectCompanyGoal", entities);
  // }, [entities]);

  const handleFilterModeChange = (event: any) =>
    dispatch(setFilterMode(event.target.value));

  const handleToggleGoal = (checked: boolean) => {
    dispatch(toggleGoal(checked));
    if (checked) {
      dispatch(fetchCompanyGoal(companyId));
    } else {
      dispatch(resetCompanyGoal());
    }
  };

  const handleChangeComparableMode = (e: RadioChangeEvent) => {
    const value = e.target.value;
    // Выбираем режим сравнения
    dispatch(setCompareMode(value));

    const updatedDepartments = departments.map((dep) => ({
      ...dep,
      disabled: value === "all" ? false : !dep.checked,
    }));

    dispatch(setDepartments(updatedDepartments));
  };

  // Считаем сколько департаментов имеют checked
  const checkedCount = departments.filter((dep) => dep.checked);
  const disabled = checkedCount.length !== 1;

  const comparableOptions = [
    { label: "Все", value: ECompareMode.all },
    { label: "Похожие", value: ECompareMode.similar, disabled },
    { label: "Непохожие", value: ECompareMode.dissimilar, disabled },
  ];

  return (
    <FilterWrapper background="#faf9f7">
      <FilterModeSelector
        handleChange={handleFilterModeChange}
        defaultValue={filterMode}
      />
      <FilterTitle>Подразделения</FilterTitle>
      <DepartmentFilter departments={departments} filterMode={filterMode} />
      <FilterBody>
        <RadioGroup
          value={compareMode}
          options={comparableOptions}
          optionType="button"
          buttonStyle="solid"
          onChange={handleChangeComparableMode}
        />

        <Divider />
        <Space direction="horizontal">
          <Switch
            className="goal-switch"
            checked={enabled}
            onChange={handleToggleGoal}
            disabled={!goalEnabled}
            loading={loading}
          />{" "}
          Декларируемая культура
        </Space>

        <Divider />

        <Button type="default" block onClick={() => setIsVisibleFilter(true)}>
          Фильтры
        </Button>
        <Divider />
        <Button
          block
          ghost
          size="small"
          onClick={() => setIsVisibleLegend(true)}
        >
          Легенда
        </Button>
      </FilterBody>
      <ModalFilterReport
        isVisible={isVisibleFilter}
        onOk={() => setIsVisibleFilter(false)}
        onCancel={() => setIsVisibleFilter(false)}
      />
      <Modal
        width={800}
        title="Легенда"
        visible={isVisibleLegend}
        onCancel={() => setIsVisibleLegend(false)}
        footer={null}
      >
        <img src={legendImage} alt="Легенда" />
      </Modal>
    </FilterWrapper>
  );
};

export default MacrolevelFilter;

import { useTranslation } from "react-i18next";

import { Menu, Layout, Avatar, Dropdown, Button } from "antd";
import { EllipsisOutlined, FolderFilled, UserOutlined } from "@ant-design/icons";
import { ReactComponent as DiscoveryIcon } from "../assets/Menu/Discovery.svg";
import { ReactComponent as GraphIcon } from "../assets/Menu/Graph.svg";
import { ReactComponent as PaperIcon } from "../assets/Menu/PaperPlus.svg";
import logo from "../assets/Logotype.svg";

import { useNavigate, useLocation } from "react-router-dom";
import { useTypedSelector, useTypedDispatch } from "store";
import { selectAuth } from "../store/auth/auth.selector";
import { logout } from "../store/auth/auth.reducer";

import Navigation from "./Navigation";

export const DesktopHeader = () => {
  const { t, i18n } = useTranslation();
  const { username, role, company_id } = useTypedSelector(selectAuth);
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("corpgen-token");
    dispatch(logout());
    navigate("/");
  };

  const dropdownMenu = (
    <Menu>
      <Menu.ItemGroup title="Аккаунт">
        <Menu.Item key="logout" onClick={handleLogout}>
          Выйти из аккаунта
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Язык">
        <Menu.Item key="ru" onClick={() => i18n.changeLanguage("ru")}>
          Русский
        </Menu.Item>
        <Menu.Item key="en" onClick={() => i18n.changeLanguage("en")}>
          Английский
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Layout.Header className="header">
      <div className="header__left">
        <div className="header__title">
          <img src={logo} alt={t("header.title")} />
        </div>
      </div>
      <div className="header__right">
        {role === "admin" && (
          <Navigation>
            <Navigation.Item
              to="/companies"
              key={1}
              icon={
                <FolderFilled
                  style={{
                    color: "#200E32",
                    fontSize: "24px",
                    opacity: 0.6,
                  }}
                />
              }
              hint="Компании"
              isActive={pathname === "/companies"}
            ></Navigation.Item>
          </Navigation>
        )}
        <Navigation>
          <Navigation.Item
            to="/researches"
            key={1}
            icon={<DiscoveryIcon />}
            hint="Исследования"
            isActive={pathname.startsWith("/researches")}
          ></Navigation.Item>
          <Navigation.Item
            to={company_id ? `/goal/${company_id ? company_id : ""}` : `/goal`}
            key={2}
            icon={<GraphIcon />}
            hint="Образ целевой культуры"
            isActive={pathname.startsWith("/goal")}
          ></Navigation.Item>
          <Navigation.Item
            to="/template"
            key={3}
            icon={<PaperIcon />}
            hint="Шаблон опросника"
            isHidden={role !== "admin"}
            isActive={pathname.startsWith("/template")}
          ></Navigation.Item>
        </Navigation>

        <div className="header__profile">
          <div className="header__profile-avatar">
            <Avatar icon={<UserOutlined />} />
          </div>
          <div className="header__profile-username">{username}</div>
          <Dropdown
            trigger={["click", "hover"]}
            overlay={dropdownMenu}
            placement="bottomRight"
            arrow
          >
            <Button
              data-testid="btn-dropdown"
              shape="circle"
              size="small"
              className="header__profile-dropdown"
              icon={<EllipsisOutlined />}
            />
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  );
};

import { Checkbox } from "antd";
import { FilterBody, FilterTitle, FilterWrapper } from "../../shared/Filter";
import { ICompany } from "../../store/companies/companies.reducer";

type OcaiFilterProps = {
  company: ICompany;
};

const OcaiFilter: React.VFC<OcaiFilterProps> = ({ company }) => {
  return (
    <FilterWrapper>
      <FilterTitle>Компании</FilterTitle>
      <FilterBody>
        <Checkbox checked={true} value={company.id}>
          {company.name}
        </Checkbox>
      </FilterBody>
    </FilterWrapper>
  );
};

export default OcaiFilter;

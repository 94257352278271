import React from "react";
import styled from "styled-components";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "store";

import { statusOptions } from "../../utils/statusOptions";
import { ISurvey, updateSurvey } from "../../store/surveys/surveys.reducer";
import { selectAuth } from "store/auth/auth.selector";
import { SurveysAPI } from "../../api/surveys.api";

type SelectStatusProps = {
  survey_id: string;
  status: ISurvey["status"];
  readOnly?: boolean;
};

const ColoredSelect = styled(Select)<{ readOnly: boolean }>`
  font-weight: bold;
  display: ${(props) => (props.readOnly ? "inline-block" : "block")};
  width: ${(props) => (props.readOnly ? "auto" : "100%")};
  color: ${(props) =>
    statusOptions.find((s) => s.value === props.value)?.color};
`;

const SelectStatus: React.FC<SelectStatusProps> = ({
  survey_id,
  status,
  readOnly = false,
}) => {
  const { role } = useTypedSelector(selectAuth);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  /**
   * Изменяем статус опроса
   * @param status Статус опроса: started, stopped, not_started
   */
  const handleChangeStatus = async (status: string) => {
    if (role === "hr") {
      return message.warning(t("app.insufficient_permissions"), 30);
    }
    // Отправляем запрос на сервер для изменения статуса
    const updatedSurvey = await SurveysAPI.update(survey_id, { status });
    // Если ответ от сервера отрицательный выводим сообщение
    if (!updatedSurvey) return message.error(t("app.wrong"));
    // Во всех остальных случаях, обновляем статус опроса
    dispatch(updateSurvey(updatedSurvey));
    // Выводим сообщение об успешно изменении
    message.success(t("surveys.status_changed"));
  };
  return (
    <ColoredSelect
      bordered={false}
      value={status}
      options={statusOptions}
      onChange={(value) => value && handleChangeStatus(value as string)}
      readOnly={readOnly}
      disabled={readOnly}
    />
  );
};

export default SelectStatus;

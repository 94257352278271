import { useCallback, useEffect, useState } from "react";
import { useTypedDispatch } from "store";
import { AuthAPI } from "../api/auth.api";
import { login } from "../store/auth/auth.reducer";

/**
 * Хук проверки авторизации
 */
export const useAuth = () => {
  const dispatch = useTypedDispatch();
  // Состояние проверки, по умолчанию включено
  const [isChecking, setIsChecking] = useState(true);
  // Асинхронная функция проверки
  const verifyAuth = useCallback(async () => {
    // Получаем токен
    const token = localStorage.getItem("corpgen-token");
    if (token) {
      // Если токен есть, получаем данные для юзера
      const user = await AuthAPI.whoami();
      // Если данные получены, записываем в стейт
      dispatch(login({ ...user, token }));
    }
    // В любом случае отключаем проверку
    setIsChecking(false);
  }, [dispatch]);

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  return {
    isChecking,
  };
};

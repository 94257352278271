import React, { useEffect, useRef } from "react";

import { useTypedDispatch, useTypedSelector } from "store";
import { Col, Row } from "antd";
import Konva from "konva";

import { ICompany } from "../../store/companies/companies.reducer";
import { fetchOcai } from "../../store/ocai/ocai.reducer";

import OcaiRadar from "../ocai/OcaiRadar";
import OcaiFilter from "../filters/OcaiFilter";
import { selectOcai } from "../../store/ocai/ocai.selector";

type OcaiReportProps = {
  company: ICompany;
};

const OcaiReport: React.FC<OcaiReportProps> = ({ company }) => {
  const stageRef = useRef<Konva.Stage>(null);
  const dispatch = useTypedDispatch();
  const ocai = useTypedSelector(selectOcai);

  // Загружаем данные отчета
  useEffect(() => {
    // TODO: в companyIds отправить список компаний для сравнения между собой OCAI
    dispatch(fetchOcai({ companyId: company.id, companyIds: [] }));
  }, [dispatch, company.id]);

  return (
    <Row>
      <Col span={6}>
        <OcaiFilter company={company} />
      </Col>
      <Col span={18}>
        <OcaiRadar data={ocai.plot} company={company} stageRef={stageRef} />
      </Col>
    </Row>
  );
};

export default OcaiReport;

import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import QuestionHeader from "../QuestionHeader";
import { IQuestionDistribution } from "../../store/questions/questions.reducer";

interface DistributionTypeProps extends IQuestionDistribution {
  position: number;
  onSave: (values: object) => Promise<boolean>;
}

const DistributionType: React.VFC<DistributionTypeProps> = (question) => {
  const { onSave, position } = question;

  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const keys = [
    { label: t("ocai.keys.K"), value: "К", disabled: false },
    { label: t("ocai.keys.A"), value: "А", disabled: false },
    { label: t("ocai.keys.R"), value: "Р", disabled: false },
    { label: t("ocai.keys.I"), value: "И", disabled: false },
  ];
  const ocaiNames = [
    t("ocai.names.dominant"),
    t("ocai.names.leadership"),
    t("ocai.names.managment"),
    t("ocai.names.glue"),
    t("ocai.names.emphases"),
    t("ocai.names.success"),
  ];
  const [keysOptions, setKeysOptions] = useState(keys);
  const { parameters } = question;
  const { options } = parameters;

  /**
   * Функция отключает уже использованные ключи
   * @param _ Измененное поле
   * @param values Все значения формы
   */
  const filterKeysOption = (_: any, values: any) => {
    if (values.parameters.options.length > 0) {
      setKeysOptions(
        keys.map((item) => {
          // Ищем в опциях такое значение
          const isExist = values.parameters.options.find((o: any) => o?.key === item.value);
          return isExist ? { ...item, disabled: true } : item;
        })
      );
    }
  };

  /**
   * Функция отправляет данные формы на сохранение,
   * в случае успеха - закрывает редактирование
   * @param values Значения полей формы
   */
  const handleSave = (values: any) => {
    onSave(values).then((status) => setIsEditable(!status));
  };

  return (
    <div className="question">
      <QuestionHeader
        position={position}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        question={question}
      />
      <div className="question__body">
        {isEditable ? (
          <Form
            layout="vertical"
            initialValues={question}
            onFinish={handleSave}
            onValuesChange={filterKeysOption}
          >
            <Form.Item label={t("templates.title")} name="text">
              <Input placeholder={t("templates.title")} />
            </Form.Item>
            <Form.Item label={t("templates.distribution_score")} name={["parameters", "score"]}>
              <Input type="number" min="0" max="1000" placeholder={t("templates.scores")} />
            </Form.Item>
            <Form.Item label={t("templates.ocai_param_name")} name={["parameters", "ocai"]}>
              <Select
                options={ocaiNames.map((name) => {
                  return { label: name, value: name };
                })}
              />
            </Form.Item>
            <Form.List name={["parameters", "options"]}>
              {(fields, { add, remove, move }) => (
                <>
                  {fields.map((field) => (
                    <Row gutter={16} key={field.key}>
                      <Col span={17}>
                        <Form.Item
                          {...field}
                          name={[field.name, "text"]}
                          fieldKey={field.key}
                          rules={[{ required: true, message: t("app.fill") }]}
                        >
                          <Input placeholder={`${field.key}. ${t("templates.judgment")}`} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item {...field} name={[field.name, "key"]} fieldKey={field.key}>
                          <Select options={keysOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={3} style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button
                          type="default"
                          danger
                          size="small"
                          icon={<DeleteOutlined />}
                          onClick={() => remove(field.name)}
                        />
                        <Button
                          disabled={fields.indexOf(field) === 0}
                          type="default"
                          size="small"
                          icon={<ArrowUpOutlined />}
                          onClick={() => move(fields.indexOf(field), fields.indexOf(field) - 1)}
                        />
                        <Button
                          disabled={fields.length === fields.indexOf(field) + 1}
                          type="default"
                          size="small"
                          icon={<ArrowDownOutlined />}
                          onClick={() => move(fields.indexOf(field), fields.indexOf(field) + 1)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      disabled={keysOptions.filter((item) => item.disabled === true).length > 3}
                    >
                      {t("templates.add_judgment")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Space>
                <Button type="default" onClick={() => setIsEditable(false)}>
                  {t("app.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("app.save")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <div className="question__body-preview">
            <p>{question.text}</p>
            <p>
              {t("templates.scores")}: {parameters.score}
            </p>
            <ul>
              {options.map((item) => (
                <li key={item.key}>
                  {item.key} {item.text}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DistributionType;

import { Group, Line, Shape } from "react-konva";
import { IDepartment } from "../../store/departments/departments.reducer";
import { IReportBoxPlot } from "../../store/reports/reports.reducer";

type BoxProps = {
  departments: IDepartment[];
  box: IReportBoxPlot;
  position: number;
  boxWidth: number;
  deskHeight: number;
  pY1: number;
  min: number;
  max: number;
};

const Box: React.FC<BoxProps> = ({
  departments,
  box,
  position,
  boxWidth,
  deskHeight,
  pY1,
  min,
  max,
}) => {
  // Центральная линия
  const center = deskHeight / 2;
  // Верхняя точка блока
  const top = center - (box.q3 / max) * center;
  // Нижняя точка
  const bottom = center - (box.q1 / min) * center;
  // Считаем медиану
  const median = center - (box.median / max) * center;
  const high = center - (box.high / max) * center;
  const low = center - (box.low / min) * center;

  const color = departments.find((d) => d.id === box.department_id)?.color;

  return (
    <Group x={(position - 1) * boxWidth * 2} y={pY1} offsetX={-boxWidth} height={deskHeight}>
      <Line points={[5, high, boxWidth - 5, high]} stroke="#000" strokeWidth={1} />
      <Line points={[boxWidth / 2, high, boxWidth / 2, top]} stroke="#000" strokeWidth={1} />
      <Line points={[5, low, boxWidth - 5, low]} stroke="#000" strokeWidth={1} />
      <Line points={[boxWidth / 2, low, boxWidth / 2, bottom]} stroke="#000" strokeWidth={1} />
      <Shape
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(5, top); // Create a starting point
          context.lineTo(boxWidth - 5, top); // Create a horizontal line
          context._context.arcTo(boxWidth, top, boxWidth, top + 5, 5); // Create an arc
          context.lineTo(boxWidth, bottom - 5);
          context._context.arcTo(boxWidth, bottom, boxWidth - 5, bottom, 5);
          context.lineTo(5, bottom);
          context._context.arcTo(0, bottom, 0, bottom - 5, 5);
          context.lineTo(0, top + 5);
          context._context.arcTo(0, top, 5, top, 5);
          context.fillStrokeShape(shape);
        }}
        fill={color}
        stroke="#200E32"
        strokeWidth={1.1}
      />
      <Line points={[1, median, boxWidth - 1, median]} stroke="#000" strokeWidth={4} />
    </Group>
  );
};

export default Box;

export type StatusOption = {
  label: string;
  color: string;
  value: "started" | "stopped" | "not_started";
};
export const statusOptions: StatusOption[] = [
  { label: "Активен", value: "started", color: "#72677D" },
  { label: "Завершен", value: "stopped", color: "#25BA84" },
  { label: "Запланирован", value: "not_started", color: "#F1BE42" },
];

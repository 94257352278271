import React from "react";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useTypedDispatch } from "store";
import {
  IDepartment,
  updateDepartment,
} from "../../store/departments/departments.reducer";
import { DepartmentsAPI } from "../../api/departments.api";

type SelectStatusProps = {
  department_id: number;
  type: IDepartment["type"];
};

interface DepartmentTypeOption {
  label: string;
  value: "department" | "group" | "region";
}

export const SelectDepartmentType: React.FC<SelectStatusProps> = ({
  department_id,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const handleChangeType = async (id: number, type: string | undefined) => {
    try {
      const updatedDepartment = await DepartmentsAPI.update(id, { type });
      if (updatedDepartment) {
        dispatch(updateDepartment(updatedDepartment));
        message.success(t("department.type_changed"));
      }
    } catch (error) {
      return message.error(t("app.wrong"));
    }
  };

  const departmentTypeOptions: DepartmentTypeOption[] = [
    { label: t(`department.types.department`), value: "department" },
    { label: t(`department.types.group`), value: "group" },
    { label: t(`department.types.region`), value: "region" },
  ];

  return (
    <Select
      bordered={false}
      value={type && t(`department.types.${type}`)}
      options={departmentTypeOptions}
      onChange={(value: any) => value && handleChangeType(department_id, value)}
    />
  );
};

import { Layer, Line, Text } from "react-konva";

type AxisYProps = {
  width: number;
  labelWidth: number;
  height: number;
  paddingHeight: number;
  tickCount: number;
  tick: number;
  max: number;
  pY: number;
};

const AxisY: React.FC<AxisYProps> = ({
  width,
  labelWidth,
  height,
  paddingHeight = 0,
  tickCount = 1,
  tick,
  max,
  pY,
}) => {
  // Получаем отступ между линиями
  const tickHeight = (height - paddingHeight * 2) / tickCount;
  // Формируем массив для рендеринга линий
  const ticks = new Array(tickCount + 1).fill(null);

  return (
    <Layer listening={false} x={0} y={0}>
      {ticks.map((_, i) => (
        <Text
          key={i}
          x={0}
          y={pY + i * tickHeight}
          offsetY={5}
          offsetX={10}
          width={labelWidth}
          fill="#72677D"
          text={`${max - i * tick}`}
          fontStyle="bold"
          fontSize={11}
          align="right"
        />
      ))}
      {ticks.map((_, i) => (
        <Line
          key={i}
          points={[labelWidth, pY + i * tickHeight, width, pY + i * tickHeight]}
          strokeWidth={1}
          stroke="#C0BBC5"
          dash={[5, 5]}
        />
      ))}
    </Layer>
  );
};

export default AxisY;

import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Space } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import QuestionHeader from "../QuestionHeader";
import { IQuestionMultiple } from "../../store/questions/questions.reducer";

interface MultipleTypeProps extends IQuestionMultiple {
  position: number;
  onSave: (values: object) => Promise<boolean>;
}

const MultipleType: React.VFC<MultipleTypeProps> = (question) => {
  const { onSave, position } = question;
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  /**
   * Функция отправляет данные формы на сохранение,
   * в случае успеха - закрывает редактирование
   * @param values Значения полей формы
   */
  const handleSave = (values: any) => {
    onSave(values).then((status) => setIsEditable(!status));
  };

  return (
    <div className="question">
      <QuestionHeader
        position={position}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        question={question}
      />
      <div className="question__body">
        {isEditable ? (
          <Form layout="vertical" initialValues={question} onFinish={handleSave}>
            <Form.Item label={t("templates.title")} name="text">
              <Input placeholder={t("templates.title")} />
            </Form.Item>
            <Form.Item label={t("templates.hint")} name={["parameters", "hint"]}>
              <Input placeholder={t("templates.hint")} />
            </Form.Item>
            <Form.List name={["parameters", "options"]}>
              {(fields, { add, remove, move }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline" style={{ display: "flex" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "label"]}
                        fieldKey={field.key}
                        rules={[{ required: true, message: t("app.fill") }]}
                      >
                        <Input placeholder={`${field.key}. ${t("templates.variants_answer")}`} />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, "value"]} fieldKey={field.key}>
                        <Input placeholder={t("templates.value")} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "require_comment"]}
                        fieldKey={field.key}
                        valuePropName="checked"
                      >
                        <Checkbox>{t("templates.need_comment")}</Checkbox>
                      </Form.Item>
                      <Button
                        danger
                        type="text"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                      />
                      <Button
                        disabled={fields.indexOf(field) === 0}
                        type="text"
                        size="small"
                        icon={<ArrowUpOutlined />}
                        onClick={() => move(fields.indexOf(field), fields.indexOf(field) - 1)}
                      />
                      <Button
                        disabled={fields.length === fields.indexOf(field) + 1}
                        type="text"
                        size="small"
                        icon={<ArrowDownOutlined />}
                        onClick={() => move(fields.indexOf(field), fields.indexOf(field) + 1)}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t("templates.new_variant")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Space>
                <Button type="default" onClick={() => setIsEditable(false)}>
                  {t("app.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("app.save")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <div className="question__body-preview">
            <p>{question.text}</p>
            <p>{question.parameters.hint}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default MultipleType;

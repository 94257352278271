import styled from "styled-components";

export const Grid = styled.div<{
  gap: string;
  columns: string;
  areas?: string[];
}>`
  display: grid;
  grid-gap: ${({ gap }) => gap};
  grid-template-columns: ${({ columns }) => columns};
  grid-template-areas: ${({ areas }) => areas?.map((area) => `"${area}" `)};
`;

export const Cell = styled.div<{ area?: string }>`
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 15px;
  grid-area: ${({ area }) => area};
`;

import styled from "styled-components";
import { ISurvey } from "../../store/surveys/surveys.reducer";
import SelectStatus from "../company/SelectStatus";
import StatusInformation from "./StatusInformation";

type StatusMessageProps = {
  survey: ISurvey;
};

const StatusMessageWrapper = styled.div``;

const StatusMessageHeader = styled.div`
  color: #200e32;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
`;

const StatusMessage: React.FC<StatusMessageProps> = ({ survey }) => {
  return (
    <StatusMessageWrapper>
      <StatusMessageHeader>
        Статус исследования: <SelectStatus survey_id={survey.id} status={survey.status} readOnly />
      </StatusMessageHeader>
      <StatusInformation survey={survey} />
    </StatusMessageWrapper>
  );
};

export default StatusMessage;

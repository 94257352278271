import axios from "axios";
import { IFilledSurvey } from "../store/surveys/surveys.reducer";

const fetch = async (survey_id: string) => {
  try {
    const response = await axios.get<IFilledSurvey>(`/filled_survey/${survey_id}`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось создать новую анкету");
  }
};

const create = async (survey_id: string, department_id: number) => {
  try {
    const response = await axios.post<IFilledSurvey>(`/filled_survey`, {
      survey_id,
      department_id,
    });
    return response.data;
  } catch (error) {
    throw new Error("Не удалось создать новую анкету");
  }
};

export const FilledSurveyAPI = { fetch, create };

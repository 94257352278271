import React from "react";
import { useTypedDispatch } from "store";
import { message } from "antd";

import { IQuestion, updateQuestion } from "../store/questions/questions.reducer";
import { QuestionsAPI } from "../api/questions.api";

import DistributionType from "./templates/DistributionType";
import HtmlType from "./templates/HtmlType";
import MultipleType from "./templates/MultipleType";
import RadioType from "./templates/RadioType";
import ScaleType from "./templates/ScaleType";
import TextType from "./templates/TextType";
import CommentType from "./templates/CommentType";

type QuestionProps = {
  question: IQuestion;
  position: number;
};

const Question: React.FC<QuestionProps> = ({ question, position }) => {
  const dispatch = useTypedDispatch();

  /**
   * Функция сохранения изменений вопроса
   * @param values поля формы редактирования вопроса
   */
  const handleSave = async (values: object): Promise<boolean> => {
    try {
      const response = await QuestionsAPI.update(question, values);
      if (response) {
        dispatch(updateQuestion({ ...question, ...values }));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      message.error("Что-то пошло не так...");
      return false;
    }
  };

  const options = {
    onSave: handleSave,
    position,
  };

  // Отображаем вопрос в зависимости от типа
  switch (question.type) {
    case "text":
      return <TextType {...options} {...question} />;
    case "scale":
      return <ScaleType {...options} {...question} />;
    case "radio":
      return <RadioType {...options} {...question} />;
    case "multiple":
      return <MultipleType {...options} {...question} />;
    case "html":
      return <HtmlType {...options} {...question} />;
    case "distribution":
      return <DistributionType {...options} {...question} />;
    case "comment":
      return <CommentType {...options} {...question} />;
    default:
      return null;
  }
};

export default Question;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountsAPI } from "../../api/accounts.api";

export interface IAccount {
  id: number;
  username: string;
  email: string;
  key?: string;
}

export const fetchAccounts = createAsyncThunk("accounts/fetch", async (company_id: number) => {
  try {
    const accounts = await AccountsAPI.fetch(company_id);
    return accounts;
  } catch (error) {}
});

const initialState: IAccount[] = [];

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    addAccount(state, action: PayloadAction<IAccount>) {
      return [...state, action.payload];
    },
    setAccounts(_, action: PayloadAction<IAccount[]>) {
      return action.payload;
    },
    updateAccount(state, action) {
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    },
    removeAccount(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer: accountsReducer } = accountsSlice;

export const { addAccount, setAccounts, removeAccount, updateAccount } = actions;

export default accountsReducer;

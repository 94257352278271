import { useState } from "react";
import { Button, Form, Input, message, Modal, Switch } from "antd";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "store";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table/interface";

import { CompaniesAPI } from "../api/companies.api";

import ContentContainer from "../containers/ContentContainer";
import TableContainer from "../containers/TableContainer";
import { selectSurveys } from "../store/surveys/surveys.selector";
import { selectCompanies } from "../store/companies/companies.selector";
import { addCompany, ICompany, updateCompany } from "../store/companies/companies.reducer";

const Companies = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useTypedDispatch();
  const surveys = useTypedSelector(selectSurveys);
  const companies = useTypedSelector(selectCompanies);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (company: ICompany) => {
    const updated_company = await CompaniesAPI.update(company);
    updated_company && dispatch(updateCompany(updated_company));
  };

  const extra = [
    <Button
      key="new"
      className="extra-button"
      type="primary"
      size="small"
      onClick={() => setIsVisibleModal(true)}
    >
      Новая компания
    </Button>,
  ];

  const columns: ColumnsType<ICompany> = [
    {
      title: "Название компании",
      dataIndex: "name",
      render: (_, company) => {
        return <Link to={`/companies/${company.id}`}>{company.name}</Link>;
      },
    },
    {
      title: "Количество сотрудников",
      dataIndex: "employee_count",
    },
    {
      title: "Количество исследований",
      key: "researches_count",
      render: (_, company) => {
        const researches = surveys.filter((survey) => survey.company_id === company.id);
        return researches.length;
      },
    },
    {
      title: "Показать сомнительные анкеты",
      key: "show_liars",
      render: (_, company) => {
        return (
          <Switch
            checked={company.show_liars}
            onChange={(checked) => handleUpdate({ ...company, show_liars: checked })}
          />
        );
      },
    },
    {
      title: "Целевая культура",
      key: "use_target_corporate_culture",
      render: (_, company) => {
        return (
          <Switch
            checked={company.use_target_corporate_culture}
            onChange={(checked) =>
              handleUpdate({
                ...company,
                use_target_corporate_culture: checked,
              })
            }
          />
        );
      },
    },
    {
      title: "",
      key: "action",
    },
  ];

  /**
   * Функция отправки полей формы на сервер
   * @param values Значения полей формы в виде объекта
   */
  const handleCreateCompany = async (values: any) => {
    try {
      setIsLoading(true);
      const company = await CompaniesAPI.create(values);
      if (company) {
        dispatch(addCompany(company));
        message.success("Новая компания успешно добавлена");
        setIsVisibleModal(false);
      }
    } catch (error) {
      message.error("Что-то пошло не так...");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentContainer title="Компании" extra={extra} className="companies">
      <TableContainer
        dataSource={companies}
        columns={columns}
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 0px"
      />
      <Modal
        title="Новая компания"
        visible={isVisibleModal}
        onCancel={() => setIsVisibleModal(false)}
        onOk={() => form.submit()}
        cancelText="Отмена"
        okText="Создать"
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleCreateCompany}>
          <Form.Item
            name="name"
            label={t("company.name")}
            rules={[{ required: true, message: t("company.fill_name") }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </ContentContainer>
  );
};

export default Companies;

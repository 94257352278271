import styled from "styled-components";

const ShapeColorCircle = styled.span<{ size: number; color: string }>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  vertical-align: middle;
`;

export default ShapeColorCircle;

import React from "react";
import { Navigate, RouteProps, useLocation } from "react-router-dom";

import { useTypedSelector } from "store";
import { selectAuth } from "../store/auth/auth.selector";
import { PageContainer } from "../containers/PageContainer";

interface IProtectedRouteProps extends RouteProps {
  allowed: string[];
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ children, allowed }) => {
  const { isLoggedIn, role } = useTypedSelector(selectAuth);
  const location = useLocation();

  // Проверяем авторизацию
  if (!isLoggedIn) return <Navigate to="/" state={{ from: location }} />;

  // Проверяем роль юзера
  if (!allowed.includes(role)) {
    return <Navigate to="/researches" />;
  }

  return PageContainer(children)({ isLoggedIn });
};

export default ProtectedRoute;

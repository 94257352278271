import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomcolor from "randomcolor";
import { DepartmentsAPI } from "../../api/departments.api";

export interface IInterpretation {
  department_id: number;
  description: string;
  high: number;
  low: number;
  median: number;
  q1: number;
  q3: number;
  value: number | undefined;
  x_bottom: string;
  x_top: string;
  x_type: string;
}

const initialState = [] as IInterpretation[];

const interpretationSlice = createSlice({
  name: "interpretation",
  initialState,
  reducers: {
    setInterpretation(_, action: PayloadAction<IInterpretation[]>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {},
});

const { actions, reducer: interpretationReducer } = interpretationSlice;

export const { setInterpretation } = actions;

export default interpretationReducer;

import { Button, Form, Input, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IQuestionComment } from "../../store/questions/questions.reducer";

import QuestionHeader from "../QuestionHeader";

interface CommentTypeProps extends IQuestionComment {
  position: number;
  onSave: (values: object) => Promise<boolean>;
}

const CommentType: React.VFC<CommentTypeProps> = (question) => {
  const { onSave, position } = question;
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  /**
   * Функция отправляет данные формы на сохранение,
   * в случае успеха - закрывает редактирование
   * @param values Значения полей формы
   */
  const handleSave = (values: any) => {
    onSave(values).then((status) => setIsEditable(!status));
  };

  return (
    <div className="question">
      <QuestionHeader
        position={position}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        question={question}
      />
      <div className="question__body">
        {isEditable ? (
          <Form layout="vertical" initialValues={question} onFinish={handleSave}>
            <Form.Item label={t("templates.title")} name="text">
              <Input placeholder={t("templates.title")} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="default" onClick={() => setIsEditable(false)}>
                  {t("app.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("app.save")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <div className="question__body-preview">
            <p>{question.text}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentType;

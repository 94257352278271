import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MacrolevelsAPI } from "../../api/macrolevels.api";

export interface IMacrolevel {
  id: number;
  name: string;
  description: string;
  checked: boolean;
}

export const fetchMacrolevels = createAsyncThunk("macrolevels/fetch", async () => {
  const macrolevels = await MacrolevelsAPI.fetch();
  return macrolevels;
});

const initialState: IMacrolevel[] = [];

const macrolevelsSlice = createSlice({
  name: "macrolevels",
  initialState,
  reducers: {
    selectMacrolevel: (state, { payload }: PayloadAction<number>) => {
      return state.map((item) =>
        item.id === payload ? { ...item, checked: true } : { ...item, checked: false }
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMacrolevels.fulfilled, (_, { payload }: PayloadAction<IMacrolevel[]>) => {
      return payload.map((item, i) => (i === 0 ? { ...item, checked: true } : item));
    });
  },
});

const { actions, reducer: macrolevelsReducer } = macrolevelsSlice;

export const { selectMacrolevel } = actions;
export default macrolevelsReducer;

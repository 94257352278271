import Result from "antd/lib/result";
import { useTranslation } from "react-i18next";

function Fail() {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title={t("code.incorrect_uuid.title")}
      subTitle={t("code.incorrect_uuid.subtitle")}
    />
  );
}

export default Fail;

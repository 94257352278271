import React, { useCallback, useEffect, useMemo } from "react";
import { Slider } from "antd";
import { useTypedDispatch } from "store";

import { marksSlider } from "../../utils/marksSlider";
import {
  QuestionBody,
  QuestionBoldText,
  QuestionTitle,
  QuestionWrapper,
} from "./questions.styled";
import { WarningAlert } from "../../shared/alert.styled";
import {
  IQuestionScale,
  updateQuestion,
} from "../../store/questions/questions.reducer";

const ScaleType: React.FC<IQuestionScale> = (question) => {
  const dispatch = useTypedDispatch();
  const { id, text, answer } = question;

  const answerLabel = useMemo(() => {
    switch (answer) {
      case -2:
        return "Абсолютно не согласен";
      case -1:
        return "Скорее не согласен";
      case 0:
        return "И согласен, и не согласен";
      case 1:
        return "Скорее согласен";
      case 2:
        return "Абсолютно согласен";
    }
  }, [answer]);

  const handleKeyDown = useCallback(
    (ev) => {
      let value;
      switch (ev.code) {
        case "Digit1":
          value = -2;
          break;
        case "Digit2":
          value = -1;
          break;
        case "Digit3":
          value = 0;
          break;
        case "Digit4":
          value = 1;
          break;
        case "Digit5":
          value = 2;
          break;
        default:
          break;
      }
      typeof value === "number" &&
        dispatch(updateQuestion({ ...question, answer: value }));
    },
    [dispatch, question]
  );

  // Ловим ответы с клавиатуры
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <QuestionWrapper data-testid="scale">
      <QuestionTitle>{text}</QuestionTitle>
      <WarningAlert>
        Выберите один из вариантов ответа, передвинув бегунок в нужную позицию.
      </WarningAlert>
      <QuestionBody>
        <QuestionBoldText>Ваш ответ:</QuestionBoldText>
        <Slider
          key={id}
          value={typeof answer === "number" ? answer : 0}
          onChange={(value) =>
            dispatch(updateQuestion({ ...question, answer: value }))
          }
          min={-2}
          max={2}
          step={1}
          dots
          included
          tooltipVisible={false}
          marks={marksSlider(false)}
          //reverse={question.parameters.reverse_scale}
        />
        <QuestionBoldText color="#200E32">{answerLabel}</QuestionBoldText>
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default ScaleType;

import React from "react";
import { IQuestion } from "../../store/questions/questions.reducer";

import CommentType from "./CommentType";
import DistributionType from "./DistributionType";
import HtmlType from "./HtmlType";
import MultipleType from "./MultipleType";
import RadioType from "./RadioType";
import ScaleType from "./ScaleType";
import TextType from "./TextType";

/**
 * Простая фабрика
 * https://habr.com/en/post/650719/
 */

const QuestionForm: React.VFC<IQuestion> = (props) => {
  // Отображаем вопрос в зависимости от типа
  switch (props.type) {
    case "text":
      return <TextType {...props} />;
    case "scale":
      return <ScaleType {...props} />;
    case "radio":
      return <RadioType {...props} />;
    case "multiple":
      return <MultipleType {...props} />;
    case "html":
      return <HtmlType {...props} />;
    case "distribution":
      return <DistributionType {...props} />;
    case "comment":
      return <CommentType {...props} />;
    default:
      return null;
  }
};

export default QuestionForm;

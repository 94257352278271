import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesAPI } from "../../api/companies.api";

export interface ICompany {
  id: number;
  name: string;
  employee_count: number;
  liars_count: number;
  show_liars: boolean;
  use_target_corporate_culture: boolean;
  is_archived: boolean;
}

export const fetchCompanies = createAsyncThunk("companies/fetch", async () => {
  try {
    const companies = await CompaniesAPI.fetch();
    return companies;
  } catch (error) {}
});

const initialState: ICompany[] = [];

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    addCompany(state, action: PayloadAction<ICompany>) {
      return [...state, action.payload];
    },
    setCompanies(_, action: PayloadAction<ICompany[]>) {
      return action.payload;
    },
    updateCompany(state, action) {
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    removeCompany(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer: companiesReducer } = companiesSlice;

export const { addCompany, setCompanies, updateCompany, removeCompany } =
  actions;

export default companiesReducer;

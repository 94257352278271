import { useEffect, useState } from "react";

import { useTypedDispatch, useTypedSelector } from "store";
import { Button, Col, message, Row, Space, TreeSelect, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { selectDepartments } from "store/departments/departments.selector";
import { selectGuest } from "../../store/guest/guest.selector";
import {
  setDepartmentId,
  setFilledSurveyId,
} from "../../store/guest/guest.reducer";
import { loading } from "../../store/app/app.reducer";
import { FilledSurveyAPI } from "../../api/filled_survey.api";
import { treeDepartments } from "../../utils/treeDepartments";
import { useParams } from "react-router";
import { WarningAlert } from "../../shared/alert.styled";
import { SurveysAPI } from "../../api/surveys.api";
import { fetchSurveyDepartments } from "../../store/departments/departments.reducer";
import { fetchQuestions } from "../../store/questions/questions.reducer";
import { useSearchParams } from "react-router-dom";

const Start = () => {
  const { uuid } = useParams<"uuid">();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const departmentId = searchParams.get("department");

  const dispatch = useTypedDispatch();
  const departments = useTypedSelector(selectDepartments);
  const { department_id, filled_survey_id } = useTypedSelector(selectGuest);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    filled_survey_id && navigate(`/survey/${uuid}/0`);
  }, [filled_survey_id, navigate, uuid]);

  /**
   * Проверяем корректность uuid при инициализации
   */
  useEffect(() => {
    if (filled_survey_id) return;
    (async () => {
      if (uuid) {
        dispatch(loading(true));
        try {
          const survey = await SurveysAPI.fetchById(uuid);
          if (survey) {
            // Загружаем департаменты компании по ID
            dispatch(fetchSurveyDepartments(uuid));
            // Загружаем вопросы
            dispatch(fetchQuestions({ survey_id: survey.id }));
            // Выведем сообщение о том, что
            if (survey.status === "not_started") {
              setIsDisabled(true);
              message.warning("Это исследование ещё не началось", 30);
            }
            if (survey.status === "stopped") {
              setIsDisabled(true);
              message.warning("Это исследование уже завершилось", 30);
            }
          }
        } catch (error) {
          navigate("/survey/fail");
        } finally {
          dispatch(loading(false));
        }
      } else {
        navigate("/survey/fail");
      }
    })();
  }, [dispatch, filled_survey_id, navigate, uuid]);

  /**
   * Соглашаемся получить кодовое слово
   */
  const handleAgree = async () => {
    if (!department_id || !uuid) return;
    dispatch(loading(true));
    try {
      const filledSurvey = await FilledSurveyAPI.create(uuid, department_id);
      if (filledSurvey) {
        // Сохраняем в браузер
        localStorage.setItem("filled_survey_id", filledSurvey.id);
        // Устанавливаем код
        dispatch(setFilledSurveyId(filledSurvey.id));
        // Переходим в опрос
        navigate(`/survey/${uuid}/0`);
      } else {
        message.error(t("app.wrong"));
      }
    } catch (error: any) {
      if (error.response) {
        const { data } = error.response;
        message.error(data.result);
      }
    } finally {
      // Убираем загрузчик
      dispatch(loading(false));
    }
  };

  /**
   * Изменяем выбранный департамент
   * @param id департамента
   */
  const handleChangeDepartmentId = (id: number) => {
    if (id) {
      dispatch(setDepartmentId(id));
      localStorage.setItem("department_id", id.toString());
    } else {
      dispatch(setDepartmentId(null));
      localStorage.removeItem("department_id");
    }
  };

  // departments, преобразованные для TreeSelect
  const departmentsTree = treeDepartments(departments);

  // найдём выбранный департамент
  const selectedDepartment = departments.find(
    (department) => department.id === Number(departmentId)
  );

  // определим, parent это или child
  const isChild = !!selectedDepartment?.parent_id;

  const treeDataFromChild = departmentsTree.find(
    (department) => department.id === selectedDepartment?.parent_id
  )?.children;

  const treeDataFromParent = departmentsTree.find(
    (department) => department.id === Number(departmentId)
  )?.children;

  const treeData = isChild ? treeDataFromChild : treeDataFromParent;

  return (
    <Row className="login">
      <Col
        xs={{ span: 24 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 16, offset: 4 }}
      >
        <Row className="login__card">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            className="login__card-about"
          >
            {/* <img width="77" src={unti} alt="Университет 2035" /> */}
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 11 }}
            className="login__card-auth"
          >
            <Typography.Title level={3} className="login__card-title">
              Вход
            </Typography.Title>
            <WarningAlert>
              Чтобы принять участие в исследовании необходимо выбрать ваш
              департамент нажать кнопку &laquo;Войти&raquo;
            </WarningAlert>
            <Space style={{ width: "100%" }} direction="vertical">
              {departments && (
                <TreeSelect
                  style={{ width: "100%" }}
                  showSearch
                  value={department_id as number}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={t("templates.choose-department")}
                  allowClear
                  treeDefaultExpandAll
                  onChange={handleChangeDepartmentId}
                  treeData={treeData}
                />
              )}
              <Button
                onClick={handleAgree}
                type="primary"
                size="large"
                block
                disabled={!department_id || isDisabled}
              >
                Войти
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Start;

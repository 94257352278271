import styled from "styled-components";

const BoldP = styled.p`
  color: #6f6f6f;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const LightP = styled.p`
  color: #939393;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const BoldParagraph: React.FC = ({ children }) => {
  return <BoldP>{children}</BoldP>;
};

export const LightParagraph: React.FC = ({ children }) => {
  return <LightP>{children}</LightP>;
};

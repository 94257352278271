import { useEffect } from "react";

import styled from "styled-components";
import { Layout, Spin, Empty } from "antd";

import { useTypedDispatch, useTypedSelector } from "store";
import { fetchCompanyGoal } from "store/goal/goal.reducer";
import { selectCompanyGoal } from "store/goal/goal.selector";

import { Grid } from "../shared/Grid";
import { DeclaredCultureScale } from "./DeclaredCultureScale";

interface ICorporateCulture {
  company_id: number;
}

const StyledGrid = styled(Grid)`
  padding: 1rem 0;
  min-height: 500px;
`;

const SpinWrapper = styled(Layout)`
  background: #fff;
  min-height: 500px;
`;

const NoAnswers = styled(Empty)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
`;


export const CorporateCulture: React.FC<ICorporateCulture> = ({
  company_id,
}) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    // Запросим данные по цели с сервера
    dispatch(fetchCompanyGoal(company_id));
  }, [dispatch, company_id]);

  const companyGoal = useTypedSelector(selectCompanyGoal);

  if (companyGoal.loading)
    return (
      <SpinWrapper>
        <Spin size="large" />
      </SpinWrapper>
    );

  if (companyGoal.entities.length === 0)
    return <NoAnswers description={"Нет ни одного ответа"} />;


  return (
    <StyledGrid
      gap="1rem 3rem"
      columns="1fr 1fr"
    >
      {companyGoal.entities.map((goal) => (
        <DeclaredCultureScale key={goal.indicator_id} goal={goal} />
      ))}
    </StyledGrid>
  );
};

import axios from "axios";
import { IUser } from "../store/auth/auth.reducer";

const whoami = async (): Promise<IUser> => {
  try {
    const response = await axios.get<IUser>("/users/whoami");
    return response.data;
  } catch (error) {
    localStorage.removeItem("corpgen-token");
    throw new Error("Не удалось авторизоваться");
  }
};

const signin = async (email: string, password: string): Promise<IUser> => {
  try {
    const response = await axios.post<IUser>("/login", { email, password });
    return response.data;
  } catch (error) {
    throw new Error("Ошибка авторизации");
  }
};

export const AuthAPI = { signin, whoami };

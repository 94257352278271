import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "store";
import { Button, Pagination } from "antd";
import { useTranslation } from "react-i18next";

import { selectQuestionsByPage } from "../store/questions/questions.selector";
import { fetchIndicators } from "../store/indicators/indicators.reducer";
import { fetchSocdems } from "../store/socdem/socdem.reducer";
import { fetchQuestions } from "../store/questions/questions.reducer";

import Question from "../components/Question";
import ContentContainer from "../containers/ContentContainer";
import ModalCreateQuestion from "../components/modals/ModalCreateQuestion";

const Template = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const { survey_id } = useParams<{ survey_id: string }>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { questions, nextPosition, questionsByPage } = useTypedSelector(
    selectQuestionsByPage(page, pageSize)
  );

  // Подгружаем справочники
  useEffect(() => {
    dispatch(fetchIndicators());
    dispatch(fetchSocdems());
  }, [dispatch]);

  // Загружаем вопросы компании, либо шаблонные
  useEffect(() => {
    dispatch(fetchQuestions({ survey_id }));
  }, [dispatch, survey_id]);

  const extra = [
    <Button type="primary" size="small" key="new" onClick={() => setIsVisibleModal(true)}>
      Добавить вопрос
    </Button>,
    survey_id && (
      <Link to={`/preview/${survey_id}`}>
        <Button type="default" size="small" key="preview">
          Предпросмотр
        </Button>
      </Link>
    ),
  ];

  return (
    <ContentContainer
      title={t("surveys.edit_template")}
      extra={extra}
      className="report"
      onBack={() => navigate(-1)}
      headerContent={
        <Pagination
          key="pagination"
          hideOnSinglePage
          current={page}
          total={questions.length}
          pageSize={pageSize}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        />
      }
    >
      {questionsByPage.map((question, key) => (
        <Question key={key} position={question.order_number} question={question} />
      ))}
      <ModalCreateQuestion
        survey_id={survey_id}
        isVisible={isVisibleModal}
        nextPosition={nextPosition}
        onClose={() => setIsVisibleModal(false)}
      />
    </ContentContainer>
  );
};

export default Template;

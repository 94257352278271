import { Button, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTypedDispatch, useTypedSelector } from "store";
import { useNavigate, useParams } from "react-router-dom";
import { SurveysAPI } from "../../api/surveys.api";
import QuestionForm from "../../components/questions/QuestionForm";
import {
  SurveyFooter,
  SurveyInfo,
  SurveyProgress,
  SurveyWrapper,
} from "../../shared/survey.styled";
import { selectGuest } from "../../store/guest/guest.selector";
import {
  fetchQuestions,
  IQuestion,
  setQuestions,
} from "../../store/questions/questions.reducer";

import { selectQuestionsByPage } from "../../store/questions/questions.selector";

const Survey = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { uuid, page } = useParams<"uuid" | "page">();
  const {
    questions,
    isAnswered,
    progress,
    isLastPage,
    answeredCount,
    index,
    questionsByPage,
    answers,
  } = useTypedSelector(selectQuestionsByPage(page));
  const { filled_survey_id } = useTypedSelector(selectGuest);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Если не указан uuid, редирект на ошибку
  useEffect(() => {
    if (uuid) {
      const localQuestions = localStorage.getItem("questions");
      if (localQuestions) {
        const parsedLocalQuestions: IQuestion[] = JSON.parse(localQuestions);
        dispatch(setQuestions(parsedLocalQuestions));
        // получаем последнюю страницу
        const lastPage = parsedLocalQuestions.findIndex(
          (a) => a.answer === undefined
        );
        if (lastPage > 0) {
          navigate(`/survey/${uuid}/${lastPage}`);
        } else {
          navigate(`/survey/${uuid}/0`);
        }
      } else {
        dispatch(fetchQuestions({ survey_id: uuid }));
      }
    } else {
      return navigate("/survey/fail");
    }
  }, [uuid, navigate, questions.length, dispatch]);

  /**
   * Отправляем ответы на сервер
   */
  const handleSubmit = useCallback(async () => {
    if (!filled_survey_id || !uuid) return;
    // Включаем отправку
    setIsSubmitting(true);
    try {
      const isSent = await SurveysAPI.saveAnswers(
        uuid,
        filled_survey_id,
        answers
      );
      if (isSent) {
        localStorage.removeItem("questions");
        localStorage.removeItem("filled_survey_id");
        localStorage.removeItem("department_id");
        navigate(`/survey/thanks`);
      }
    } catch (error) {
      message.error("Не удалось отправить вашу ответы. Попробуйте позднее.");
    } finally {
      setIsSubmitting(false);
    }
  }, [filled_survey_id, uuid, answers, navigate]);

  // Перехватываем Enter и Backspace
  const handleKeyDown = useCallback(
    (ev) => {
      if (ev.code === "Enter" && isAnswered) {
        if (isLastPage) {
          handleSubmit();
        } else {
          navigate(`/survey/${uuid}/${index + 1}`);
        }
      }
      if (ev.code === "Backspace" && index > 0) {
        navigate(`/survey/${uuid}/${index - 1}`);
      }
    },
    [isAnswered, uuid, index, isLastPage, handleSubmit, navigate]
  );
  // Ловим ответы с клавиатуры
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  // Записываем прогресс в локальное хранилище
  useEffect(() => {
    questions.length > 0 &&
      localStorage.setItem("questions", JSON.stringify(questions));
  }, [questions]);

  // Проверяем чтобы ответы на предыдущих страницах были заполненные
  useEffect(() => {
    if (questions.length > 0 && index > 1 && answeredCount === 0) {
      navigate(`/survey/${uuid}/0`);
    }
  }, [uuid, navigate, answeredCount, questions.length, index]);

  const handleBack = () => navigate(`/survey/${uuid}/${index - 1}`);
  const handleNext = () => navigate(`/survey/${uuid}/${index + 1}`);

  return (
    <SurveyWrapper>
      {questionsByPage.map((question, key) => (
        <QuestionForm key={key} {...question} />
      ))}
      <SurveyFooter>
        <SurveyProgress percent={progress} />
        {/* <Space
          direction="vertical"
          // style={{ display: "flex", width: "100%" }}
          align="center"
        > */}
        <Button disabled={index === 0} onClick={handleBack}>
          Предыдущий
        </Button>
        <SurveyInfo>
          Вопрос {index + 1} из {questions.length}
          <br />
          <b>Заполнено на {progress}%</b>
        </SurveyInfo>
        {isLastPage ? (
          <Button loading={isSubmitting} type="primary" onClick={handleSubmit}>
            Отправить
          </Button>
        ) : (
          <Button type="primary" disabled={!isAnswered} onClick={handleNext}>
            Следующий
          </Button>
        )}
        {/* </Space> */}
      </SurveyFooter>
    </SurveyWrapper>
  );
};

export default Survey;

import React from "react";

import styled from "styled-components";
import { device } from "utils/mediaQueries";
import { useTypedDispatch } from "store";
import { Slider } from "antd";

import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";
import {
  DistributionOptions,
  IQuestionDistribution,
  updateQuestion,
} from "../../store/questions/questions.reducer";

const DistributionRow = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    max-width: 600px;
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.laptop} {
    max-width: 800px;
  }
`;

const DistributionColumn = styled.div``;

const DistributionType: React.VFC<IQuestionDistribution> = (question) => {
  const dispatch = useTypedDispatch();
  const { parameters } = question;
  const { score: max, options } = parameters;

  const handleChange = (item: DistributionOptions, value: number) => {
    // Обновляем значения
    const updatedOptions = options.map((option) =>
      option.key === item.key ? { ...option, score: value } : option
    );

    // Считаем общую сумму и собираем ответ
    const answer = updatedOptions.reduce<{ [key: string]: number }>(
      (acc, option) => {
        if (option.score) {
          acc[option.key] = option.score;
          acc.SUM += option.score;
        } else {
          acc[option.key] = 0;
        }
        return acc;
      },
      { SUM: 0 }
    );

    // Не выходим за пределы максимума
    if (answer.SUM > max) return;

    // Обновляем ответ и значения опций
    dispatch(
      updateQuestion({
        ...question,
        parameters: {
          ...question.parameters,
          options: updatedOptions,
        },
        answer: answer.SUM === max ? answer : undefined,
      })
    );
  };

  const renderSlider = (item: DistributionOptions) => {
    return (
      <DistributionRow key={item.key}>
        <DistributionColumn>{item.text}</DistributionColumn>
        <DistributionColumn>
          <Slider
            key={item.key + item.text}
            value={item.score}
            tooltipVisible
            min={0}
            max={max}
            onChange={(value) => handleChange(item, value)}
          />
        </DistributionColumn>
      </DistributionRow>
    );
  };

  return (
    <QuestionWrapper data-testid="distribution">
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>{options.map(renderSlider)}</QuestionBody>
    </QuestionWrapper>
  );
};

export default DistributionType;

import React, { useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, message, Modal } from "antd";
import { useTypedDispatch } from "store";
import { ColumnsType } from "antd/lib/table/interface";

import { IAccount, removeAccount, updateAccount } from "../../store/accounts/accounts.reducer";
import { AccountsAPI } from "../../api/accounts.api";
import TableContainer from "../../containers/TableContainer";

type AccountsTableProps = {
  accounts: IAccount[];
};

const { confirm } = Modal;

const AccountsTable: React.FC<AccountsTableProps> = ({ accounts }) => {
  const dispatch = useTypedDispatch();
  const [changeableName, setChangeableName] = useState<number | undefined>();

  const handleDelete = async (account_id: number) => {
    const isDeleted = await AccountsAPI.remove(account_id);
    isDeleted && dispatch(removeAccount(account_id));
  };

  const dropdownMenu = (account_id: number) => (
    <Menu>
      <Menu.Item key="changeName" onClick={() => setChangeableName(account_id)}>
        Изменить имя
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() =>
          confirm({
            title: "Вы уверены, что хотите удалить этот аккаунт",
            okText: "Да",
            cancelText: "Нет",
            onOk: () => handleDelete(account_id),
          })
        }
      >
        Удалить
      </Menu.Item>
    </Menu>
  );

  const handleChangeName = async (id: number, username: string) => {
    try {
      const updatedAccount = await AccountsAPI.update(id, { username });
      if (updatedAccount) {
        dispatch(updateAccount(updatedAccount));
        message.success(`Имя пользователя успешно изменено`);
      }
    } catch (error) {}
  };

  const handleBlurInputName = (username: string, account: IAccount) => {
    setChangeableName(undefined);
    return account.username !== username && handleChangeName(account.id, username);
  };

  const columns: ColumnsType<IAccount> = [
    {
      title: "Имя пользователя",
      dataIndex: "username",
      render: (_, account) => {
        return changeableName === account.id ? (
          <Input
            defaultValue={account.username}
            bordered={false}
            onBlur={(e) => handleBlurInputName(e.target.value, account)}
            onPressEnter={(e) => handleBlurInputName(e.currentTarget.value, account)}
          />
        ) : (
          account.username
        );
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "",
      key: "action",
      render: (_, account) => {
        return (
          <Dropdown
            overlay={dropdownMenu(account.id)}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <Button shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return <TableContainer dataSource={accounts} columns={columns} />;
};

export default AccountsTable;

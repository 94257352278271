import Layout from "antd/lib/layout";
import React from "react";
import Header from "../components/Header";

const { Content } = Layout;

export const PageContainer = (WrappedComponent: React.ReactElement) => {
  return ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    return (
      <>
        {isLoggedIn && <Header />}
        <Content className="content">{WrappedComponent}</Content>
      </>
    );
  };
};

import { Modal, Checkbox, Slider } from "antd";
import { useState } from "react";
import { useTypedDispatch } from "store";
import styled from "styled-components";
import { Cell, Grid } from "../../shared/Grid";
import { IReportFilter, updateFilter } from "../../store/reports/reports.reducer";

type ModalFilterReportProps = {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const MyCell = styled(Cell)`
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 15px;
  grid-area: ${({ area }) => area};
  h5 {
    color: #2c2c2c;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;

const initialFilter: IReportFilter = {
  range: [1, 5],
  accordance: [],
  gender: [],
  age: [],
  experience: [],
};

const ModalFilterReport: React.FC<ModalFilterReportProps> = ({ isVisible, onOk, onCancel }) => {
  const dispatch = useTypedDispatch();
  const [filters, setFilters] = useState<IReportFilter>(initialFilter);

  const accordanceOptions = ["Похожие", "Непохожие"];

  const genderOptions = ["Мужчины", "Женщины"];

  const ageOptions = ["До 18", "18-25", "26-35", "36-45", "46-55", "Старше 55"];

  const experienceOptions = ["Менее 1 года", "1-2 года", "3-5 лет", "6-10 лет", "Свыше 10 лет"];

  const marks = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };

  const handleUpdateFilter = () => {
    dispatch(updateFilter(filters));
    onOk();
  };

  const handleResetFilter = () => {
    dispatch(updateFilter(initialFilter));
    setFilters(initialFilter);
    onCancel();
  };

  return (
    <Modal
      width={800}
      title="Фильтры"
      visible={isVisible}
      onCancel={handleResetFilter}
      onOk={handleUpdateFilter}
      okText="Применить"
      cancelText="Очистить фильтры"
    >
      <Grid
        gap="1rem"
        columns="1fr 1fr 1fr 1fr"
        areas={["similar gender age exp", "range range age exp"]}
      >
        <MyCell area="similar">
          <h5>Соответствие декларируемой культуре</h5>
          <Checkbox.Group
            options={accordanceOptions}
            value={filters.accordance}
            onChange={(values) => setFilters({ ...filters, accordance: values as string[] })}
          />
        </MyCell>
        <MyCell area="gender">
          <h5>Пол</h5>
          <Checkbox.Group
            options={genderOptions}
            value={filters.gender}
            onChange={(values) => setFilters({ ...filters, gender: values as string[] })}
          />
        </MyCell>
        <MyCell area="age">
          <h5>Возраст</h5>
          <Checkbox.Group
            options={ageOptions}
            value={filters.age}
            onChange={(values) => setFilters({ ...filters, age: values as string[] })}
          />
        </MyCell>
        <MyCell area="exp">
          <h5>Стаж работы</h5>
          <Checkbox.Group
            options={experienceOptions}
            value={filters.experience}
            onChange={(values) => setFilters({ ...filters, experience: values as string[] })}
          />
        </MyCell>
        <MyCell area="range">
          <h5>Задать диапазон</h5>
          <Slider
            range
            min={1}
            max={5}
            step={1}
            marks={marks}
            value={filters.range}
            onChange={(values) => setFilters({ ...filters, range: values })}
          />
        </MyCell>
      </Grid>
    </Modal>
  );
};

export default ModalFilterReport;

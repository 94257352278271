import React from "react";
import { Modal, Form, Input, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { useTypedDispatch } from "store";

import { addQuestion, IQuestion } from "../../store/questions/questions.reducer";
import { QuestionsAPI } from "../../api/questions.api";
import { SurveysAPI } from "../../api/surveys.api";

type ModalCreateQuestionProps = {
  isVisible: boolean;
  nextPosition: number;
  survey_id?: string;
  onClose: () => void;
};

const ModalCreateQuestion: React.FC<ModalCreateQuestionProps> = ({
  isVisible,
  nextPosition,
  survey_id,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useTypedDispatch();

  const questionTypes = [
    { value: "text", label: t("questions.types.text") },
    { value: "scale", label: t("questions.types.scale") },
    { value: "radio", label: t("questions.types.radio") },
    { value: "multiple", label: t("questions.types.multiple") },
    { value: "distribution", label: t("questions.types.distribution") },
    { value: "html", label: t("questions.types.html") },
    { value: "comment", label: t("questions.types.comment") },
  ];

  const setDefaultParameters = (type: IQuestion["type"]) => {
    switch (type) {
      case "text":
        return {
          validation: "string",
        };
      case "radio":
        return {
          options: [],
        };
      case "scale":
        return {
          reverse_scale: false,
        };
      case "distribution":
        return {
          score: 100,
          options: [],
        };
      case "html":
        return {
          html: "",
        };
      default:
        return {};
    }
  };

  const handleAddQuestion = async (values: any) => {
    const data = {
      type: values.type,
      text: values.text,
      order_number: nextPosition,
      parameters: setDefaultParameters(values.type),
    };
    try {
      if (survey_id) {
        const question = await QuestionsAPI.create(survey_id, data);
        question && dispatch(addQuestion(question));
      } else {
        const template = await SurveysAPI.fetchTemplate();
        if (template) {
          const question = await QuestionsAPI.create(template.id, data);
          question && dispatch(addQuestion(question));
        }
      }
    } catch (error) {
      message.error(t("app.wrong"));
    } finally {
      form.resetFields();
      onClose();
    }
  };

  return (
    <Modal
      visible={isVisible}
      title="Новый вопрос"
      okText="Создать"
      cancelText="Отмена"
      onOk={() => form.submit()}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" onFinish={handleAddQuestion}>
        <Form.Item label="Тип вопроса" name="type">
          <Select options={questionTypes} />
        </Form.Item>
        <Form.Item label="Текст вопроса" name="text">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateQuestion;

import { useState } from "react";
import { Button } from "antd";

import { useTranslation } from "react-i18next";

import { useTypedSelector } from "store";
import { selectCompanies } from "../store/companies/companies.selector";
import { selectSurveys } from "../store/surveys/surveys.selector";
import { selectAuth } from "../store/auth/auth.selector";

import ContentContainer from "../containers/ContentContainer";
import ModalCreateResearch from "../components/modals/ModalCreateResearch";
import ResearchesTable from "../components/tables/ResearchesTable";

import img from "../assets/EmptyResearches.svg";

const Researches = () => {
  const { t } = useTranslation();
  const companies = useTypedSelector(selectCompanies);
  const surveys = useTypedSelector(selectSurveys);
  const { company_id } = useTypedSelector(selectAuth);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  if (surveys.length === 0) {
    return (
      <ContentContainer title="Исследования" className="empty-researches">
        <h3>Вы еще не провели ни одного исследования.</h3>
        <img src={img} alt="Вы еще не провели ни одного исследования" />
        <Button
          size="large"
          type="primary"
          onClick={() => setIsVisibleModal(true)}
        >
          Провести исследование
        </Button>
        {companies.length > 0 && (
          <ModalCreateResearch
            company_id={company_id}
            isVisible={isVisibleModal}
            onCancel={() => setIsVisibleModal(false)}
          />
        )}
      </ContentContainer>
    );
  }

  const extra = [
    <Button
      key="new"
      className="extra-button"
      type="primary"
      size="small"
      onClick={() => setIsVisibleModal(true)}
    >
      Новое исследование
    </Button>,
  ];

  return (
    <ContentContainer
      title={t("dashboard.title")}
      extra={extra}
      className="researches"
    >
      <ResearchesTable surveys={surveys} />
      {companies.length > 0 && (
        <ModalCreateResearch
          company_id={company_id}
          isVisible={isVisibleModal}
          onCancel={() => setIsVisibleModal(false)}
        />
      )}
    </ContentContainer>
  );
};

export default Researches;

import React from "react";
import { Group, Layer, Rect, Stage, Text } from "react-konva";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import Konva from "konva";

import { store } from "../../store";
import { IOcaiPlot } from "../../store/ocai/ocai.reducer";
import { ICompany } from "../../store/companies/companies.reducer";

import Axis from "./Axis";
import Additional from "./Additional";
import Plot from "./Plot";

type PlatformProps = {
  data: IOcaiPlot[];
  company?: ICompany;
  size?: number;
  stageRef: React.RefObject<Konva.Stage>;
};

const OcaiRadar: React.FC<PlatformProps> = ({ data, size = 600, stageRef }) => {
  const { t } = useTranslation();

  // Максимальный процент
  let max = data.reduce((acc, item) => {
    const maxValue = Math.max(...item.graph.map((g) => g.value));
    if (acc < maxValue) {
      acc = maxValue;
    }
    return acc;
  }, 0);

  // Округляем до целого
  max = Math.ceil(max / 10) * 10;
  // Количество квадратов
  const count = 9;
  // Отступ между квадратами
  const offset = 40;
  // Массив из количества квадратов
  const rects = new Array(count).fill(null);

  const renderRect = (_: any[], i: number) => {
    const coord = size / 2 - (offset / 2) * i;
    const wh = i * offset;
    return (
      <Rect key={i} x={coord} y={coord} width={wh} height={wh} stroke="#B7B7B7" strokeWidth={1} />
    );
  };

  return (
    <Stage ref={stageRef} width={size} height={size}>
      <Provider store={store}>
        <Layer id="radar">
          <Group id="rects">
            {rects.map(renderRect)}
            <Axis size={size} offset={offset} count={count} max={max} />
            <Text text={t("ocai.keys.K")} x={size / 5} y={size / 5} />
            <Text text={t("ocai.keys.A")} x={size - 150} y={size / 5} />
            <Text text={t("ocai.keys.R")} x={size - 150} y={size - 130} />
            <Text text={t("ocai.keys.I")} x={size / 5} y={size - 130} />
          </Group>
          <Additional size={size} offset={offset} count={count} />
        </Layer>
        <Layer id="series">
          {data.map((item, i) => (
            <Plot key={i} {...item} max={max} />
          ))}
        </Layer>
      </Provider>
    </Stage>
  );
};

export default OcaiRadar;

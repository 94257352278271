import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Role = "guest" | "admin" | "hr";

export interface IUser {
  id: number | null;
  token: string | null;
  isLoggedIn: boolean;
  username: string;
  role: Role;
  email: string;
  company_id?: number;
}

const initialState: IUser = {
  id: null,
  email: "",
  token: localStorage.getItem("corpgen-token"),
  isLoggedIn: false,
  username: "",
  role: "guest",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(_, action: PayloadAction<IUser>) {
      return { ...action.payload, isLoggedIn: true };
    },
    changeRole(state, action: PayloadAction<Role>) {
      return { ...state, role: action.payload };
    },
    logout() {
      return { ...initialState, token: null };
    },
  },
});

const { actions, reducer: authReducer } = authSlice;

export const { login, logout, changeRole } = actions;

export default authReducer;

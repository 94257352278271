import React, { useMemo, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Radio,
  TreeSelect,
  AutoComplete,
  message,
} from "antd";
import styled from "styled-components";

import { useTypedDispatch, useTypedSelector } from "store";
import { useTranslation } from "react-i18next";

import { treeDepartments } from "../../utils/treeDepartments";
import { addDepartment } from "../../store/departments/departments.reducer";
import { DepartmentsAPI } from "../../api/departments.api";
import { selectDepartments } from "../../store/departments/departments.selector";
import { SInputNumber } from "shared/form.styled";
import { MyRadioGroup } from "shared/Filter";

type ModalCreateDepartmentProps = {
  company_id: number;
  isVisible: boolean;
  onOk?: (values: any) => void;
  onCancel?: () => void;
};

const RadioGroup = styled(MyRadioGroup)`
  padding: 0 !important;

  .ant-radio-button-wrapper {
    display: flex;
    width: 33%;
    height: 2.3rem;
    justify-content: center;
    align-items: center;

    :first-child {
      border-radius: 8px 0 0 8px;
    }
    :last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

// в ином случае, Input смещён кверху
const HintInput = styled(AutoComplete)`
  .ant-select-selector {
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 100%;
      }
    }
  }
`;

const ModalCreateDepartment: React.FC<ModalCreateDepartmentProps> = ({
  company_id,
  isVisible,
  onCancel,
}) => {
  const dispatch = useTypedDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const departments = useTypedSelector(selectDepartments);
  const tree = useMemo(() => treeDepartments(departments), [departments]);
  const [selectedParent, setSelectedParent] = useState<string>();
  const [hints, setHints] = useState<(string | undefined)[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateParentId = (id: string) => {
    form.setFieldsValue({ parent_id: id });
    setSelectedParent(id);
  };

  const handleAddDepartment = async (values: any) => {
    try {
      setIsLoading(true);
      const department = await DepartmentsAPI.create(company_id, values);
      department && dispatch(addDepartment(department));
    } catch (error) {
      message.error("Что-то пошло не так...");
    } finally {
      form.resetFields();
      setIsLoading(false);
      setSelectedParent(undefined);
      onCancel && onCancel();
    }
  };

  const uniqueDepartmentFunctions = useMemo(() => {
    // получим массив значений func департаментов
    const departmentFunctions = departments.map(
      (department) => department.func
    );

    // оставим уникальные значения и удалим null
    return departmentFunctions.filter(
      (func, index, array) => !!func && array.indexOf(func) === index
    );
  }, [departments]);

  const handleSearch = (value: string) => {
    if (!value) return;

    // отфильтруем массив func по значению Input
    const hints = uniqueDepartmentFunctions.filter((func) => {
      return func?.toLowerCase().includes(value.toLowerCase());
    });
    setHints(hints);
  };

  const { Option } = AutoComplete;

  return (
    <Modal
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      title="Новый департамент"
      okText="Создать"
      cancelText="Отмена"
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ employee_count: 0, type: "department" }}
        onFinish={handleAddDepartment}
      >
        <Form.Item hidden={true} name="parent_id">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label={t("department.parent")}>
          <TreeSelect
            style={{ width: "100%" }}
            showSearch
            value={selectedParent}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder={t("department.choose_parent")}
            allowClear
            treeDefaultExpandAll
            onChange={handleUpdateParentId}
            treeData={tree}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={t("department.name")}
          rules={[{ required: true, message: t("department.fill_name") }]}
        >
          <Input placeholder={t("department.name")} />
        </Form.Item>
        <Form.Item
          name="type"
          label={t("department.type")}
          rules={[{ required: true, message: t("department.fill_type") }]}
        >
          <RadioGroup>
            <Radio.Button value="department">
              {t("department.types.department")}
            </Radio.Button>
            <Radio.Button value="group">
              {t("department.types.group")}
            </Radio.Button>
            <Radio.Button value="region">
              {t("department.types.region")}
            </Radio.Button>
          </RadioGroup>
        </Form.Item>
        <Form.Item
          name="employee_count"
          label={t("department.employee_count")}
          rules={[{ required: true, message: t("department.fill_employees") }]}
        >
          <SInputNumber
            placeholder={t("department.employee_count")}
            min={0}
            max={Infinity}
          />
        </Form.Item>
        <Form.Item name="head" label={t("department.head_fullname")}>
          <Input type="text" placeholder={t("department.head_fullname")} />
        </Form.Item>
        <Form.Item name="func" label={t("department.function")}>
          <HintInput
            placeholder={t("department.function")}
            onSearch={handleSearch}
          >
            {hints.map((func) => (
              <Option key={func}>{func}</Option>
            ))}
          </HintInput>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateDepartment;

import React from "react";
import { Group, Line, Text } from "react-konva";
import Arrow from "./Arrow";
type AdditionalProps = {
  size?: number;
  offset?: number;
  count?: number;
  stroke?: string;
  strokeWidth?: number;
  rotation?: number;
};

const Additional: React.FC<AdditionalProps> = ({
  size = 600,
  stroke = "#B7B7B7",
  strokeWidth = 1,
}) => {
  return (
    <Group id="additional">
      <Line
        points={[100, size / 2, size - 100, size / 2]}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <Arrow x={110} y={size / 2 + 5} rotation={180} />
      <Arrow x={size - 110} y={size / 2 - 5} />
      <Line
        points={[size / 2, 100, size / 2, size - 100]}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <Arrow x={size / 2 - 5} y={110} rotation={-90} />
      <Arrow x={size / 2 + 5} y={size - 110} rotation={90} />
      <Text
        text="Внутренний фокус и интеграция"
        x={80}
        y={size}
        width={size}
        align="center"
        rotation={-90}
      />
      <Text text="Гибкость и индивидуальность" x={0} width={size} align="center" y={80} />
      <Text
        text="Внешний фокус и дифференциация"
        x={size - 80}
        y={0}
        rotation={90}
        align="center"
        width={size}
      />
      <Text text="Стабильность и контроль" x={0} width={size} align="center" y={size - 90} />
    </Group>
  );
};

export default Additional;

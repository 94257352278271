import React, { useState, useEffect } from "react";
import { Checkbox, Input, Space } from "antd";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { useTypedDispatch } from "store";

import {
  IQuestionMultiple,
  updateQuestion,
} from "../../store/questions/questions.reducer";
import {
  QuestionBody,
  QuestionTitle,
  QuestionWrapper,
} from "./questions.styled";

const MultipleType: React.VFC<IQuestionMultiple> = (question) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { text, parameters } = question;

  const options = parameters.options;

  const handleChanges = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;
    const index = options.findIndex((o) => o.label === value);
    // Формируем массив опций с параметрами
    const updatedOptions = options.map((item, i) =>
      i === index ? { ...item, checked } : item
    );
    // Получаем количество чекнутых
    const checkedOptions = updatedOptions.filter((o) => o.checked);
    // Из чекнутых выпиливаем те что с пустым value
    const filledOptions = checkedOptions.filter((o) => o.value !== undefined);
    // Проверяем чтобы чекнутых было достаточно
    if (checkedOptions.length > 0) {
      // Получаем параметры текущего чекнутого
      const current = updatedOptions.find((_, i) => i === index);
      // Если комментарий не требуется, записываем ответ сразу
      if (!current?.require_comment) {
        dispatch(
          updateQuestion({
            ...question,
            parameters: {
              ...question.parameters,
              options: updatedOptions,
            },
            answer: JSON.stringify(checkedOptions),
          })
        );
      } else {
        dispatch(
          updateQuestion({
            ...question,
            parameters: {
              ...question.parameters,
              options: updatedOptions,
            },
            answer:
              filledOptions.length > 0
                ? JSON.stringify(filledOptions)
                : undefined,
          })
        );
      }
    } else {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: updatedOptions,
          },
          answer: undefined,
        })
      );
    }
  };

  const handleComment = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const updatedOptions = options.map((item, key) =>
      key === index ? { ...item, value } : item
    );
    // Получаем количество чекнутых
    const checkedOptions = updatedOptions.filter((o) => o.checked);
    // Из чекнутых выпиливаем те что с пустым value
    const filledOptions = checkedOptions.filter((o) => o.value === undefined);
    // Если поле не пустое
    if (value) {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: options.map((item, key) =>
              key === index ? { ...item, value: e.target.value } : item
            ),
          },
          answer: JSON.stringify(checkedOptions),
        })
      );
    } else {
      dispatch(
        updateQuestion({
          ...question,
          parameters: {
            ...question.parameters,
            options: options.map((item, key) =>
              key === index ? { ...item, value: undefined } : item
            ),
          },
          answer:
            filledOptions.length > 0
              ? JSON.stringify(filledOptions)
              : undefined,
        })
      );
    }
  };

  // Логика смены направления отрисовки Input
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <QuestionWrapper data-testid="multiple">
      <QuestionTitle>{text}</QuestionTitle>
      <QuestionBody>
        {options.map((option, key: number) => (
          <Checkbox
            key={key}
            checked={option.checked}
            value={option.label}
            onChange={handleChanges}
          >
            <Space direction={isDesktop ? "horizontal" : "vertical"}>
              <span>{option.label}</span>
              {option.require_comment && option.checked && (
                <Input
                  value={option.value}
                  onChange={(e) => handleComment(e, key)}
                  placeholder={t("questions.types.comment")}
                />
              )}
            </Space>
          </Checkbox>
        ))}
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default MultipleType;


export const marksSlider = (reverse: boolean | undefined) => {
  if (!reverse) {
    return {
      "-2": { label: "-2" },
      "-1": { label: "-1" },
      "0": { label: "0" },
      "1": { label: "1" },
      "2": { label: "2" },
    };
  } else {
    return {
      "-2": { label: "2" },
      "-1": { label: "1" },
      "0": { label: "0" },
      "1": { label: "-1" },
      "2": { label: "-2" },
    };
  }
};

import { selectIndicators } from "store/indicators/indicators.selector";
import { selectDepartments } from "store/departments/departments.selector";
import { useTypedSelector } from "store";
import { IIndicator } from "store/indicators/indicators.reducer";

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Empty, Layout, Select, Typography } from "antd";
import { IDepartment } from "store/departments/departments.reducer";
import { selectInterpretation } from "store/interpretation/interpretation.selector";
import { IInterpretation } from "store/interpretation/interpretation.reducer";

export interface IInterp extends IIndicator {
  high_value: number;
  low_value: number;
  low_text: string;
  middle_text: string;
  high_text: string;
  median: number;
  department_id: number;
}

interface InterpretationProps {
  title: string;
  text: string;
  color: string;
}

const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const InterpretationSettings = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

const InterpretationWrapper = styled.div`
	display: grid;
  width: 100%;
	grid-template-columns: 1fr 1fr;
	gap: 2rem 1rem;
  padding-bottom: 1rem;
}}
`;

const InterpretationContent = styled.div<{ color: string }>`
display: flex: 
flex-direction: column;
overflow: hidden;
box-shadow:
      0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075),
      0 4px 4px hsl(0deg 0% 0% / 0.075)
    ;
		border-radius: 0 0 8px 8px ;
  border: ${({ color }) => `1px solid ${color}50`};
`;

const InterpretationTitle = styled.h5<{ color: string }>`
  display: flex;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  background: ${({ color }) => `${color}50`};
`;

const InterpretationText = styled.p`
  display: flex;
  padding: 1rem;
  height: 100%;
`;

const Interpretation: React.FC<InterpretationProps> = ({
  title,
  text,
  color,
}) => {
  return (
    <InterpretationContent color={color}>
      <InterpretationTitle color={color}>{title}</InterpretationTitle>
      <InterpretationText>{text}</InterpretationText>
    </InterpretationContent>
  );
};

export const Interpretations = () => {
  const indicators: IIndicator[] = useTypedSelector(selectIndicators);
  const departments: IDepartment[] = useTypedSelector(selectDepartments);
  const interpretations: IInterpretation[] =
    useTypedSelector(selectInterpretation);

  // оставим выбранные департаменты
  const checkedDepartments = useMemo(() => {
    return departments.concat().filter((department) => department.checked);
  }, [departments]);

  const [dept, setDept] = useState(checkedDepartments[0].id);
  const [levels, setLevels] = useState(["Низкий", "Средний", "Высокий"]);

  const handleSelect = (value: string) => {
    setDept(+value);
  };

  const handleMultiSelect = (value: string[]) => {
    setLevels(value);
  };

  // конвертируем медиану из (-2)-2 в 0-5
  const convertToZeroFive = (value: number) => {
    if (value < 0) return Number(((5 * (2 - Math.abs(value))) / 4).toFixed(2));
    if (value > 0) return Number(((5 * (2 + value)) / 4).toFixed(2));
    return 5 * 0.5; // if 0
  };

  const indicatedGoals = useMemo(() => {
    return interpretations
      .map((interpretation) => {
        const relevantIndicator = indicators.find(
          (indicator) =>
            indicator.general_axis.toLowerCase() ===
            interpretation.description.toLowerCase()
        );
        const andjustedMedian = convertToZeroFive(interpretation.median);
        return {
          department_id: interpretation.department_id,
          general_axis: relevantIndicator?.general_axis,
          high_value: relevantIndicator?.high_value,
          low_value: relevantIndicator?.low_value,
          low_text: relevantIndicator?.low_text,
          middle_text: relevantIndicator?.middle_text,
          high_text: relevantIndicator?.high_text,
          median: andjustedMedian,
        };
      })
      .filter((goal) => goal.general_axis);
  }, [interpretations, indicators]);

  // интерпретируем значение медианы
  const interpret = ({
    general_axis,
    high_value,
    low_value,
    low_text,
    middle_text,
    high_text,
    median,
    department_id,
  }: IInterp) => {
    if (median < low_value)
      return {
        department_id: department_id,
        title: general_axis,
        text: low_text,
        color: "#FAAD14",
        level: "Низкий",
      };
    if (median > high_value)
      return {
        department_id: department_id,
        title: general_axis,
        text: high_text,
        color: "#B3FF66",
        level: "Высокий",
      };
    return {
      department_id: department_id,
      title: general_axis,
      text: middle_text,
      color: "#1BA1E2",
      level: "Средний",
    };
  };

  const textInterpretations = useMemo(() => {
    return indicatedGoals
      .map((goal: any) => interpret(goal))
      .filter((goal) => {
        return goal.department_id === dept && levels.includes(goal.level);
      });
  }, [indicatedGoals, dept, levels]);

  return (
    <ComponentWrapper>
      <InterpretationSettings>
        <SettingWrapper>
          <Typography.Title level={5}>Департаменты</Typography.Title>
          <Select value={dept.toString()} onChange={handleSelect}>
            {checkedDepartments.map((department) => (
              <Select.Option
                key={department.id} // это значение будет уходить в onSelect
                onSelect={handleSelect}
              >
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </SettingWrapper>

        <SettingWrapper>
          <Typography.Title level={5}>Уровни</Typography.Title>
          <Select
            mode="multiple"
            allowClear
            placeholder="Выберите необходимые уровни"
            defaultValue={levels}
            onChange={handleMultiSelect}
          >
            <Select.Option key={"Низкий"}>Низкий</Select.Option>
            <Select.Option key={"Средний"}>Средний</Select.Option>
            <Select.Option key={"Высокий"}>Высокий</Select.Option>
          </Select>
        </SettingWrapper>
      </InterpretationSettings>
      {textInterpretations.length ? (
        <InterpretationWrapper>
          {textInterpretations.map((int: any) => {
            return (
              <Interpretation
                key={`${int.department_id}-${int.title}`}
                title={int.title}
                text={int.text}
                color={int.color}
              />
            );
          })}
        </InterpretationWrapper>
      ) : (
        <Empty
          description={
            <>
              <Typography.Title level={5}>Нет данных</Typography.Title>
              <Typography.Text>
                Удостоверьтесь, что выбрано отображение деларируемой культуры и
                установлены необходимые фильтры
              </Typography.Text>
            </>
          }
        />
      )}
    </ComponentWrapper>
  );
};

import React, { useState } from "react";
import { Form, Input, Switch } from "antd";
import { useTypedDispatch } from "store";
import { useTranslation } from "react-i18next";

import { QuestionBody, QuestionTitle, QuestionWrapper } from "./questions.styled";

import { IQuestionComment, updateQuestion } from "../../store/questions/questions.reducer";

const CommentType: React.VFC<IQuestionComment> = (question) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isUncommentable, setIsUncommentable] = useState(false);

  const handleChange = (changedValues: any) => {
    const { text } = changedValues;
    if (text !== "") {
      dispatch(updateQuestion({ ...question, answer: text }));
    } else {
      dispatch(updateQuestion({ ...question, answer: undefined }));
    }
  };

  const toggleComment = () => {
    if (!isUncommentable) {
      handleChange({ text: t("preview.no_comment") });
      setIsUncommentable(true);
    } else {
      handleChange({ text: "" });
      setIsUncommentable(false);
    }
  };

  return (
    <QuestionWrapper data-testid="comments">
      <QuestionTitle>{question.text}</QuestionTitle>
      <QuestionBody>
        <Form form={form} onValuesChange={handleChange} layout="vertical">
          <Form.Item name="text" label={t("questions.types.comment")}>
            <Input.TextArea
              disabled={isUncommentable}
              autoSize={{ minRows: 3, maxRows: 23 }}
              placeholder={t("preview.fill_comment")}
            />
          </Form.Item>
          <Form.Item label={t("preview.no_comment")}>
            <Switch checked={isUncommentable} onChange={toggleComment} />
          </Form.Item>
        </Form>
      </QuestionBody>
    </QuestionWrapper>
  );
};

export default CommentType;

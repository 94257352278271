import React, { useState } from "react";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useTypedDispatch } from "store";

import { LockOutlined } from "@ant-design/icons";
import { addAccount } from "../../store/accounts/accounts.reducer";
import { AccountsAPI } from "../../api/accounts.api";

function generatePassword() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

type ModalCreateAccountProps = {
  company_id: number;
  isVisible: boolean;
  onOk?: (values: any) => void;
  onCancel: () => void;
};

const ModalCreateAccount: React.FC<ModalCreateAccountProps> = ({
  isVisible,
  company_id,
  onCancel,
}) => {
  const dispatch = useTypedDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddAccount = async (values: any) => {
    try {
      setIsLoading(true);
      const account = await AccountsAPI.create(company_id, values);
      if (account) {
        dispatch(addAccount(account));
        form.resetFields();
        onCancel();
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRandomPassword = () => {
    const password = generatePassword();
    form.setFieldsValue({ password });
  };

  return (
    <Modal
      visible={isVisible}
      okText="Создать"
      cancelText="Отмена"
      onOk={() => form.submit()}
      onCancel={onCancel}
      title="Новый аккаунт"
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical" onFinish={handleAddAccount}>
        <Form.Item
          name="username"
          label={t("accounts.username")}
          rules={[
            {
              required: true,
              message: t("errors.required", { field: t("accounts.username") }),
            },
          ]}
        >
          <Input placeholder={t("accounts.username")} />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: t("errors.required", { field: "E-mail" }),
            },
            { type: "email", message: t("errors.email_is_incorrect") },
          ]}
        >
          <Input type="email" placeholder="tvoy_email@email.com" />
        </Form.Item>
        <Form.Item label={t("auth.password")}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("errors.required", { field: t("auth.password") }),
              },
              { min: 8, message: t("errors.min_password") },
            ]}
          >
            <Input.Password
              placeholder="Пароль"
              addonAfter={
                <Button onClick={handleRandomPassword} size="small">
                  <Tooltip title={t("auth.generate_password")}>
                    <LockOutlined />
                  </Tooltip>
                </Button>
              }
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateAccount;

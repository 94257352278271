import React from "react";
import { Checkbox } from "antd";
import { useTypedDispatch } from "store";

import { IDepartment, updateDepartment } from "../../store/departments/departments.reducer";
import { FilterForm, FilterFormItem } from "../../shared/Filter";
import ShapeColorCircle from "../../shared/Shapes";
import { treeDepartments } from "../../utils/treeDepartments";
import { EFilterMode } from "../../store/reports/reports.reducer";

type DepartmentFilterProps = {
  departments: IDepartment[];
  filterMode: EFilterMode;
};

const DepartmentFilter: React.FC<DepartmentFilterProps> = ({ departments, filterMode }) => {
  const dispatch = useTypedDispatch();

  const getName = (department: IDepartment) => {
    if (filterMode === "head" && department.head) {
      return department.head;
    }
    if (filterMode === "func" && department.func) {
      return department.func;
    }

    return department.name;
  };

  const handleSelectDepartment = (department: IDepartment, e: any) => {
    const { checked } = e.target;
    dispatch(updateDepartment({ ...department, checked }));
  };

  const CheckboxDepartment: React.VFC<IDepartment> = (department) => {
    const nestedDepartments = (department.children || []).map((dep) => {
      return (
        <CheckboxDepartment
          key={dep.id}
          {...dep}
          checked={department.checked || dep.checked}
          color={department.checked ? department.color : dep.color}
        />
      );
    });
    return (
      <FilterFormItem key={department.id}>
        <Checkbox
          key={department.id}
          value={department.id}
          checked={department.checked}
          disabled={department.disabled}
          onChange={(e: any) => handleSelectDepartment(department, e)}
        >
          <ShapeColorCircle size={18} color={department.color} /> {getName(department)}
        </Checkbox>
        {nestedDepartments}
      </FilterFormItem>
    );
  };

  const tree = treeDepartments(departments);

  return (
    <FilterForm>
      {tree.map((department) => (
        <CheckboxDepartment key={department.id} {...department} />
      ))}
    </FilterForm>
  );
};

export default DepartmentFilter;

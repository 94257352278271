import styled from "styled-components";
import React, { useState } from "react";
import { Button } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

import { ISurveyByDepartment } from "../../store/surveys/surveys.reducer";

const CarouselWrapper = styled.div`
  width: 100%;
  margin: 1rem 0 2rem;
`;

const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const CarouselBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CarouselHeaderTitle = styled.div`
  color: #200e32;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
`;

const CarouselHeaderExtra = styled.div`
  color: #c0bbc5;
  button {
    border: none;
    margin-left: 15px;
    &:last-child {
      margin-left: 5px;
    }
    &:disabled {
      color: #200e32;
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 15%;
`;

const CarouselItemTitle = styled.div`
  flex-basis: 100%;
  height: 60px;
  color: #616161;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  margin: 0 5px;
`;

const CarouselItemProgress = styled.div<{ color: string; percent: number }>`
  height: 140px;
  width: 70px;
  background: #ededed;
  border-radius: 8px;
  position: relative;
  margin: 5px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &::before {
    width: 70px;
    content: "${({ percent }) => Math.round(percent * 100)}%";
    position: absolute;
    bottom: -20px;
    color: #200e32;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  &::after {
    content: "";
    border-radius: 8px;
    position: absolute;
    bottom: 0;
    width: 70px;
    height: ${({ percent }) => percent * 140}px;
    max-height: 140px;
    background: ${({ color }) => color};
  }
`;

type DepartmentReportProps = {
  title: string;
  data: ISurveyByDepartment[];
};

const DepartmentReport: React.FC<DepartmentReportProps> = ({ title, data }) => {
  // Максимальное количество
  const itemsMaxCount = data.length;
  // Количество элементов в одном слайде
  const itemsLimitCount = 6;
  // Текущий шаг
  const [page, setPage] = useState(1);
  // Общее количество слайдов
  const pagesCount = Math.round(itemsMaxCount / itemsLimitCount);

  const Slide: React.FC<{ item: ISurveyByDepartment }> = ({ item }) => {
    // процент начатых опросников от общего числа сотрудников
    const startedPercent =
      item.employee_count > item.started_count ? item.started_count / item.employee_count : 1;
    // процент заполненных опросников от общего числа сотрудников
    const finishedPercent =
      item.employee_count > item.finished_count ? item.finished_count / item.employee_count : 1;

    return (
      <CarouselItem>
        <CarouselItemTitle>{item.name}</CarouselItemTitle>
        <CarouselItemProgress color="#2C75F7" percent={startedPercent} />
        <CarouselItemProgress color="#25BA84" percent={finishedPercent} />
      </CarouselItem>
    );
  };

  // Элементы из слайда
  const items = data.slice((page - 1) * itemsLimitCount, page * itemsLimitCount);

  return (
    <CarouselWrapper>
      <CarouselHeader>
        <CarouselHeaderTitle>{title}</CarouselHeaderTitle>
        <CarouselHeaderExtra>
          {page} / {pagesCount}
          <Button
            disabled={page <= 1}
            type="text"
            size="small"
            icon={
              <LeftCircleOutlined
                style={{ fontSize: "1.2rem", color: page <= 1 ? "#C0BBC5" : "#200E32" }}
              />
            }
            onClick={() => setPage(page - 1)}
          />
          <Button
            disabled={page >= pagesCount}
            type="text"
            size="small"
            icon={
              <RightCircleOutlined
                style={{ fontSize: "1.2rem", color: page >= pagesCount ? "#C0BBC5" : "#200E32" }}
              />
            }
            onClick={() => setPage(page + 1)}
          />
        </CarouselHeaderExtra>
      </CarouselHeader>
      <CarouselBody>
        {items.map((item) => (
          <Slide key={item.name} item={item} />
        ))}
      </CarouselBody>
    </CarouselWrapper>
  );
};

export default DepartmentReport;

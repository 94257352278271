import React, { useState } from "react";
import moment from "moment";
import { Button, Dropdown, Input, message } from "antd";
import { Link } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";

import { statusOptions } from "../../utils/statusOptions";
import { SurveysAPI } from "../../api/surveys.api";

import { useTypedDispatch } from "store";
import { ISurvey } from "../../store/surveys/surveys.reducer";
import { removeSurvey, updateSurvey } from "../../store/surveys/surveys.reducer";
import SelectStatus from "../company/SelectStatus";
import TableContainer from "../../containers/TableContainer";
import ResearchDropdown from "../dropdown/ResearchDropdown";

type ResearchesTableProps = {
  surveys: ISurvey[];
};

const ResearchesTable: React.FC<ResearchesTableProps> = ({ surveys }) => {
  const dispatch = useTypedDispatch();
  const [changableName, setChangableName] = useState("");

  const handleDelete = async (id: string) => {
    const isDeleted = await SurveysAPI.remove(id);
    isDeleted && dispatch(removeSurvey(id));
  };

  const handleChangeName = async (id: string, name: string) => {
    try {
      const updatedSurvey = await SurveysAPI.update(id, { name });
      if (updatedSurvey) {
        dispatch(updateSurvey(updatedSurvey));
        message.success(`Название исследования успешно изменено`);
      }
    } catch (error) {}
  };

  const handleBlurInput = (value: string, survey: ISurvey) => {
    setChangableName("");
    return survey.name !== value && handleChangeName(survey.id, value);
  };

  const columns: ColumnsType<ISurvey> = [
    {
      title: "Название исследования",
      key: "name",
      render: (_, survey) => {
        return changableName === survey.id ? (
          <Input
            defaultValue={survey.name}
            bordered={false}
            onBlur={(e) => handleBlurInput(e.target.value, survey)}
            onPressEnter={(e) => handleBlurInput(e.currentTarget.value, survey)}
          />
        ) : (
          <Link to={`/researches/${survey.id}`}>{survey.name}</Link>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      render: (_, survey) => {
        return <SelectStatus survey_id={survey.id} status={survey.status} />;
      },
      filters: statusOptions.map((s) => {
        return { text: s.label, value: s.value };
      }),
      onFilter: (value, survey) => value.toString() === survey.status.toString(),
    },
    {
      title: "Участников",
      key: "employee_count",
      render: (_, survey) => {
        return `${survey.finished_count}/${survey.employee_count}`;
      },
    },
    {
      title: "Старт",
      dataIndex: "start_date",
      sorter: (a, b) => (moment(a.start_date) > moment(b.start_date) ? 1 : -1),
    },
    {
      title: "Окончание",
      dataIndex: "finish_date",
    },
    {
      title: "Отчет сформирован",
      dataIndex: "report_created_date",
      sorter: (a, b) => (moment(a.report_created_date) > moment(b.report_created_date) ? 1 : -1),
    },
    {
      title: "",
      key: "action",
      render: (_, survey) => {
        return (
          <Dropdown
            overlay={
              <ResearchDropdown
                surveyId={survey.id}
                onDelete={handleDelete}
                onChangeName={setChangableName}
              />
            }
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <Button shape="circle" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <TableContainer
      dataSource={surveys}
      columns={columns}
      gridTemplateColumns="1fr 175px 150px 150px 150px 175px 60px"
    />
  );
};

export default ResearchesTable;

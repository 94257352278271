import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomcolor from "randomcolor";
import { DepartmentsAPI } from "../../api/departments.api";

export interface IDepartment {
  id: number;
  parent_id: number | null;
  name: string;
  employee_count: number;
  head?: string;
  func?: string;
  type: "department" | "group" | "region";
  color: string;
  checked?: boolean;
  disabled?: boolean;
  children?: IDepartment[];
}

export interface IReportFilterDepartments extends IDepartment {
  value?: number | string;
  disabled?: boolean;
}

export const fetchDepartments = createAsyncThunk(
  "departments/fetch",
  async (company_id: number) => {
    const departments = await DepartmentsAPI.fetch({ company_id });
    if (departments) {
      const colors = randomcolor({
        luminosity: "dark",
        count: departments.length,
      });
      return departments.map((department, i) => {
        return { ...department, color: colors[i], checked: i === 0 };
      });
    } else {
      return [];
    }
  }
);

export const fetchSurveyDepartments = createAsyncThunk(
  "departments/survey/fetch",
  async (survey_id: string) => {
    const departments = await DepartmentsAPI.fetch({ survey_id });
    if (departments) {
      const colors = randomcolor({
        luminosity: "dark",
        count: departments.length,
      });
      return departments.map((department, i) => {
        return {
          ...department,
          color: colors[i],
          checked: i === 0 && department.parent_id === null,
        };
      });
    } else {
      return [];
    }
  }
);

const initialState = [] as IDepartment[];

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    addDepartment(state, action: PayloadAction<IDepartment>) {
      return [...state, action.payload];
    },
    setDepartments(_, action: PayloadAction<IDepartment[]>) {
      return action.payload;
    },
    updateDepartment(state, action: PayloadAction<IDepartment>) {
      let ids = [] as number[];
      // Формируем список айдишников потомков
      const getSliceIds = (id: number) => {
        const parents = state.filter((d) => d.parent_id === id);
        for (const dep of parents) {
          ids.push(dep.id);
          getSliceIds(dep.id);
        }
      };

      getSliceIds(action.payload.id);

      return state.map((item) => {
        // Обновляем указанный элемент
        if (item.id === action.payload.id) {
          return action.payload;
        }

        // Если элемент не относится к потомкам, просто его возвращаем
        if (ids.includes(item.id)) {
          if (item.checked) {
            return { ...item, checked: undefined };
          }
        }

        return item;
      });
    },
    removeDepartment(state, action) {
      return state.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(fetchSurveyDepartments.fulfilled, (_, { payload }) => {
        return payload;
      });
  },
});

const { actions, reducer: departmentsReducer } = departmentsSlice;

export const { addDepartment, setDepartments, updateDepartment, removeDepartment } = actions;

export default departmentsReducer;

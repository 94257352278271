import { useEffect } from "react";
import { Layout, Spin } from "antd";
import { Routes, Route } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "store";

import { selectApp } from "./store/app/app.selector";
import { selectAuth } from "./store/auth/auth.selector";
import { fetchIndicators } from "./store/indicators/indicators.reducer";
import { fetchCompanies } from "./store/companies/companies.reducer";
import { fetchSurveys } from "./store/surveys/surveys.reducer";

import { useAuth } from "./hooks/useAuth";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Template from "./pages/Template";
import Researches from "./pages/Researches";
import Research from "./pages/Research";
import Companies from "./pages/Companies";
import Company from "./pages/Company";
import Preview from "./pages/Preview";
import Goal from "./pages/Goal";
import Start from "./pages/guest/Start";
import Thanks from "./pages/guest/Thanks";
import Survey from "./pages/guest/Survey";
import Fail from "./pages/guest/Fail";

import ProtectedRoute from "./components/ProtectedRoute";

import "./sass/index.sass";

const App = () => {
  const dispatch = useTypedDispatch();
  const { isLoading } = useTypedSelector(selectApp);
  const { isLoggedIn, role, company_id } = useTypedSelector(selectAuth);
  const { isChecking } = useAuth();

  /**
   * Загружаем справочники: аккаунты, департамент, компании, итд
   */
  useEffect(() => {
    if (!isLoggedIn) return;
    // Загружаем список индикаторов
    dispatch(fetchIndicators());
    // Список компаний
    dispatch(fetchCompanies());
    // Список исследований
    dispatch(fetchSurveys());
  }, [isLoggedIn, role, dispatch, company_id]);

  if (isChecking) return <Spin tip="Проверка авторизации" />;

  return (
    <Spin tip="Загрузка данных..." spinning={isLoading}>
      <Layout hasSider={false}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="goal">
            <Route
              index
              element={
                <ProtectedRoute allowed={["admin", "hr"]} children={<Goal />} />
              }
            />
            <Route
              path=":company_id"
              element={
                <ProtectedRoute allowed={["admin", "hr"]} children={<Goal />} />
              }
            />
          </Route>
          <Route path="template">
            <Route
              index
              element={
                <ProtectedRoute allowed={["admin"]} children={<Template />} />
              }
            />
            <Route
              path=":survey_id"
              element={
                <ProtectedRoute allowed={["admin"]} children={<Template />} />
              }
            />
          </Route>
          <Route path="preview">
            <Route
              index
              element={
                <ProtectedRoute
                  allowed={["admin", "hr"]}
                  children={<Preview />}
                />
              }
            />
            <Route
              path=":survey_id"
              element={
                <ProtectedRoute
                  allowed={["admin", "hr"]}
                  children={<Preview />}
                />
              }
            />
          </Route>
          <Route path="companies">
            <Route
              index
              element={
                <ProtectedRoute allowed={["admin"]} children={<Companies />} />
              }
            />
            <Route
              path=":company_id"
              element={
                <ProtectedRoute allowed={["admin"]} children={<Company />} />
              }
            />
          </Route>
          <Route path="researches">
            <Route
              index
              element={
                <ProtectedRoute
                  allowed={["admin", "hr"]}
                  children={<Researches />}
                />
              }
            />
            <Route
              path=":survey_id"
              element={
                <ProtectedRoute
                  allowed={["admin", "hr"]}
                  children={<Research />}
                />
              }
            />
          </Route>
          <Route path="survey">
            <Route path=":uuid" element={<Start />} />
            <Route path="thanks" element={<Thanks />} />
            <Route path="fail" element={<Fail />} />
            <Route path=":uuid/:page" element={<Survey />} />
          </Route>
          <Route element={<NotFound />} />
        </Routes>
      </Layout>
    </Spin>
  );
};

export default App;

import { Group, Line, Rect, Text } from "react-konva";
import { IDepartment } from "../../store/departments/departments.reducer";
import { IReportBoxPlot } from "../../store/reports/reports.reducer";

import Box from "./Box";

type BoxGroupProps = {
  departments: IDepartment[];
  group: IReportBoxPlot[];
  groupsWidth: number[];
  groupKey: number;
  tickCount: number;
  min: number;
  max: number;
  pY1: number;
  pY2: number;
  boxWidth: number;
  height: number;
  deskHeight: number;
  isOdd: boolean;
};

const BoxGroup: React.FC<BoxGroupProps> = ({
  departments,
  group,
  groupKey,
  pY1,
  pY2,
  boxWidth,
  groupsWidth,
  deskHeight,
  min,
  max,
  isOdd,
}) => {
  // Верхняя надпись
  const x_top = group.find((x) => x.x_top)?.x_top;
  // Нижняя надпись
  const x_bottom = group.find((x) => x.x_bottom)?.x_bottom;
  // Считаем длинну всех предыдущих блоков для правильного отступа
  const offsetX = groupsWidth.slice(0, groupKey - 1).reduce((acc: number, item: number) => {
    return (acc += item * boxWidth * 2 + boxWidth);
  }, 0);
  // Рассчитываем общую ширину группы
  const groupWidth = group.length * boxWidth * 2 + boxWidth;
  // Расставляем надписи на разных уровнях
  const offsetYLabel = () => {
    const a = groupKey;
    const b = 3;
    const quotient = (a - (a % b)) / b;
    const remainder = a >= b ? a - quotient * b : a;

    if (remainder === 2) {
      return -30;
    } else if (remainder === 0) {
      return -60;
    } else {
      return 0;
    }
  };

  const maxTextWidth = (): number | undefined => {
    if (group.length > 0) {
      return groupWidth * 3 - 10;
    } else {
      return undefined;
    }
  };

  const getTextWidth = (text: string | undefined): number => {
    // 8.4 длина буквы
    const width = text ? text.length * 8.4 : 0;
    const maxWidth = maxTextWidth();

    if (maxWidth && maxWidth < width) {
      return maxWidth;
    } else {
      return width;
    }
  };

  return (
    <Group x={offsetX} y={0} width={groupWidth}>
      <Text
        text={x_top?.toUpperCase()}
        x={0}
        y={0}
        offsetY={offsetYLabel()}
        width={getTextWidth(x_top)}
        wrap="none"
        ellipsis
        color="#72677D"
        font="10px Source Sans Pro"
        fontStyle="600"
        fill="#72677D"
      />
      <Line
        points={[0, 20 - offsetYLabel(), 0, pY2 + 70 + offsetYLabel()]}
        stroke="#C0BBC5"
        strokeWidth={1}
      />
      <Line
        points={[0, 20 - offsetYLabel(), getTextWidth(x_top), 20 - offsetYLabel()]}
        stroke="#C0BBC5"
        strokeWidth={1}
      />
      <Line
        points={[0, pY2 + 70 + offsetYLabel(), getTextWidth(x_bottom), pY2 + 70 + offsetYLabel()]}
        stroke="#C0BBC5"
        strokeWidth={1}
      />
      <Text
        text={x_bottom?.toUpperCase()}
        width={getTextWidth(x_bottom)}
        x={0}
        y={pY2 + 80 + offsetYLabel()}
        wrap="none"
        ellipsis
        color="#72677D"
        font="10px Source Sans Pro"
        fontStyle="600"
        fill="#72677D"
      />
      {isOdd && (
        <Rect fill="#dedede" opacity={0.4} x={0} y={pY1} height={deskHeight} width={groupWidth} />
      )}
      {departments &&
        group.map((box, key) => (
          <Box
            key={key}
            boxWidth={boxWidth}
            position={key + 1}
            box={box}
            pY1={pY1}
            min={min}
            max={max}
            deskHeight={deskHeight}
            departments={departments}
          />
        ))}
    </Group>
  );
};

export default BoxGroup;

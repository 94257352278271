import React from "react";
import { Group, Line, Text } from "react-konva";

type AxisProps = {
  size?: number;
  offset?: number;
  count?: number;
  stroke?: string;
  strokeWidth?: number;
  rotation?: number;
  max?: number;
};

const Axis: React.FC<AxisProps> = ({
  size = 600,
  offset = 40,
  count = 9,
  stroke = "#B7B7B7",
  strokeWidth = 1,
  max = 40,
}) => {
  const a = size / 2 - (offset / 2) * (count - 1);
  const b = size / 2 + (offset / 2) * (count - 1);
  const labels = new Array(4);
  const step = max / 4;
  for (let i = 0; i < labels.length; i++) {
    labels[i] = max - step * i;
  }
  return (
    <Group>
      <Line points={[a, a, b, b]} stroke={stroke} strokeWidth={strokeWidth} />
      <Line points={[a, b, b, a]} stroke={stroke} strokeWidth={strokeWidth} />
      {labels.reverse().map((label, i) => (
        <Text
          fontSize={10}
          key={i}
          text={label}
          x={size / 2 + 10}
          y={size / 2 - 5 - offset - offset * i}
        />
      ))}
    </Group>
  );
};

export default Axis;

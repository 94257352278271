import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "store";
import { Button } from "antd";

import { selectQuestionsByPage } from "../store/questions/questions.selector";
import { fetchQuestions } from "../store/questions/questions.reducer";
import ContentContainer from "../containers/ContentContainer";
import QuestionForm from "../components/questions/QuestionForm";
import {
  SurveyFooter,
  SurveyInfo,
  SurveyProgress,
  SurveyWrapper,
} from "../shared/survey.styled";

const Preview = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { survey_id } = useParams<"survey_id">();
  const [page, setPage] = useState(0);
  const { questions, isAnswered, progress, questionsByPage } = useTypedSelector(
    selectQuestionsByPage(page)
  );

  /**
   * Подгружаем все вопросы опросника
   */
  useEffect(() => {
    dispatch(fetchQuestions({ survey_id }));
  }, [dispatch, survey_id]);

  return (
    <ContentContainer
      title="Предпросмотр опросника"
      onBack={() => navigate(-1)}
    >
      {questions.length > 0 && (
        <SurveyWrapper>
          {questionsByPage.map((question, key) => (
            <QuestionForm key={key} {...question} />
          ))}
          <SurveyFooter>
            <SurveyProgress percent={progress} />
            <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
              Предыдущий
            </Button>
            <SurveyInfo>
              Вопрос {page + 1} из {questions.length}
              <br />
              <b>Заполнено на {progress}%</b>
            </SurveyInfo>
            <Button
              type="primary"
              disabled={!isAnswered}
              onClick={() => setPage(page + 1)}
            >
              Следующий
            </Button>
          </SurveyFooter>
        </SurveyWrapper>
      )}
    </ContentContainer>
  );
};

Preview.whyDidYouRender = true;

export default Preview;

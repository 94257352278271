import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveysAPI } from "../../api/surveys.api";

interface IOcaiGraph {
  name: string;
  value: number;
}

export interface IOcaiPlot {
  name: string;
  company_id: number;
  graph: IOcaiGraph[];
}

export interface IOcaiState {
  plot: IOcaiPlot[];
  selectedGraph?: number;
}

const initialState: IOcaiState = {
  plot: [],
};

export const fetchOcai = createAsyncThunk(
  "ocai/fetch",
  async (options: { companyId: number; companyIds: number[] }) => {
    const { companyId, companyIds } = options;
    try {
      // Получаем данные отчета с сервера
      const ocai = await SurveysAPI.getOCAIReport(companyId, companyIds);
      return ocai.plot;
    } catch (error) {
      throw new Error("Error");
    }
  }
);

const ocaiSlice = createSlice({
  name: "ocai",
  initialState,
  reducers: {
    setPlots(state, action: PayloadAction<IOcaiPlot[]>) {
      return { ...state, plot: action.payload };
    },
    setOcai(_, action: PayloadAction<IOcaiState>) {
      return action.payload;
    },
    setSelectedGraph(state, action: PayloadAction<number | undefined>) {
      return { ...state, selectedGraph: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOcai.fulfilled, (state, action) => {
      return { ...state, plot: action.payload };
    });
  },
});

const { actions, reducer: reportReducer } = ocaiSlice;

export const { setPlots, setOcai, setSelectedGraph } = actions;

export default reportReducer;

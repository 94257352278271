import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Row, Col, Typography, message } from "antd";
import { useNavigate } from "react-router";

import { useTypedDispatch, useTypedSelector } from "store";
import { useTranslation } from "react-i18next";

import { selectAuth } from "../store/auth/auth.selector";
import { login } from "../store/auth/auth.reducer";
import { AuthAPI } from "../api/auth.api";

import logo from "../assets/Logotype.svg";
import unti from "../assets/UNTIlogo.png";

const Login = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { isLoggedIn } = useTypedSelector(selectAuth);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/researches" />;
  }

  const handleAuthorization = async (credentials: any) => {
    const { email, password } = credentials;
    try {
      setIsLoading(true);
      const user = await AuthAPI.signin(email, password);
      // Проверяем наличие токена в ответе
      if (user?.token) {
        // Сохраняем токен в хранилище
        localStorage.setItem("corpgen-token", user.token);
        // Сохраняем в стейт
        dispatch(login({ ...user, token: user.token }));
        // Выводим сообщение
        message.success(t("auth.success"));
        // Перекидываем в активные опросы
        navigate("/researches");
      } else {
        message.error(t("app.wrong"));
      }
    } catch (error: any) {
      // Получаем данные запроса
      const { status, data } = error.response;
      if (status === 401) {
        // Если логин и пароль не верный
        message.error(data.result);
      } else {
        // Если остальные проблемы на бэке
        message.error(t("app.wrong"));
      }
    } finally {
      // Выключаем спиннер
      setIsLoading(false);
    }
  };

  return (
    <Row className="login">
      <Col span={16} offset={4}>
        <Row className="login__card">
          <Col span={12} className="login__card-about">
            <img width="77" src={unti} alt="Университет 2035" />
          </Col>
          <Col span={10} offset={1} className="login__card-auth">
            <Typography.Title level={3} className="login__card-title">
              {t("auth.sign_in")}
            </Typography.Title>
            <Form
              layout="vertical"
              className="login-form"
              onFinish={handleAuthorization}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: t("form.fill", { field: "E-mail" }) }]}
              >
                <Input type="email" placeholder="E-mail" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: t("form.fill", { field: t("auth.password") }) }]}
              >
                <Input.Password visibilityToggle={false} placeholder={t("auth.password")} />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>{t("auth.remember_me")}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button block size="large" type="primary" htmlType="submit" loading={isLoading}>
                  {t("auth.submit_button")}
                </Button>
              </Form.Item>
            </Form>
            <div className="login__card-copyright">
              <p>
                <img width="120" src={logo} alt="Корпоративная генетика" />
              </p>
              <p>© Университет НТИ “20.35”, 2021</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveysAPI } from "../../api/surveys.api";

export enum ECompareMode {
  "all" = "all",
  "similar" = "similar",
  "dissimilar" = "dissimilar",
}

export enum EFilterMode {
  "department" = "department",
  "head" = "head",
  "func" = "func",
}

export interface IReportBoxPlot {
  department_id: number;
  high: number;
  low: number;
  median: number;
  q1: number;
  q3: number;
  x_bottom: string;
  x_top: string;
  x_type: string;
  value?: number;
}

export interface IReportFilter {
  range: [number, number];
  accordance: string[];
  gender: string[];
  age: string[];
  experience: string[];
}

export interface IReport {
  boxplots: IReportBoxPlot[];
  filters: IReportFilter;
  compareMode: ECompareMode;
  filterMode: EFilterMode;
}

const initialState: IReport = {
  compareMode: ECompareMode.all,
  filterMode: EFilterMode.department,
  boxplots: [],
  filters: {
    range: [1, 5],
    accordance: [],
    gender: [],
    age: [],
    experience: [],
  },
};

export const fetchReportData = createAsyncThunk(
  "report/fetch",
  async (options: any) => {
    const {
      survey_id,
      macrolevel_id,
      departments,
      indicators,
      filters,
      compareMode,
    } = options;

    try {
      // Получаем данные отчета с сервера
      const report = await SurveysAPI.getMacrolevelReport({
        survey_id,
        macrolevel_id,
        departments,
        indicators,
        filters,
        compareMode,
      });
      return report.boxplots;
    } catch (error) {
      throw new Error("Не загружен отчет");
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setPlots(state, action: PayloadAction<IReportBoxPlot[]>) {
      return { ...state, boxplots: action.payload };
    },
    updateFilterItem(
      state,
      action: PayloadAction<{ key: string; values: string[] }>
    ) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.values,
        },
      };
    },
    updateFilter(state, action: PayloadAction<IReportFilter>) {
      return {
        ...state,
        filters: action.payload,
      };
    },
    setCompareMode(state, action: PayloadAction<ECompareMode>) {
      return { ...state, compareMode: action.payload };
    },
    setFilterMode(state, action: PayloadAction<EFilterMode>) {
      return { ...state, filterMode: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportData.fulfilled, (state, action) => {
        return { ...state, boxplots: action.payload };
      })
      .addCase(fetchReportData.rejected, (state) => {
        return { ...state, boxplots: [] };
      });
  },
});

const { actions, reducer: reportReducer } = reportSlice;

export const {
  setPlots,
  updateFilterItem,
  updateFilter,
  setCompareMode,
  setFilterMode,
} = actions;

export default reportReducer;

import { WarningFilled } from "@ant-design/icons";
import { Alert, Col, Divider, Row } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useTypedSelector } from "store";
import { selectCompanyById } from "../store/companies/companies.selector";
import ContentContainer from "../containers/ContentContainer";
import GoalSurvey from "../components/goal/GoalSurvey";
import { BoldParagraph, LightParagraph } from "../shared/Text";

const SpaceAlert = styled(Alert)`
  margin: 1rem 0 2rem;
`;

const Goal = () => {
  const navigate = useNavigate();
  const { company_id } = useParams<"company_id">();
  const company = useTypedSelector(selectCompanyById(Number(company_id)));

  return (
    <ContentContainer title="Образ целевой культуры" onBack={() => navigate(-1)}>
      <Row gutter={[46, 46]}>
        <Col span={12} style={{ borderRight: "1px solid #E0DBD1" }}>
          <SpaceAlert
            message="Обратите внимание"
            description="Для того, чтобы система построила образ декларируемой корпоративной культуры, носитель такой культуры должен пройти опрос. Результаты будут использоваться для сопоставления с результатами исследования компании в целом."
            type="info"
            showIcon
            icon={<WarningFilled style={{ color: "#F1BE42" }} />}
          />
          <SpaceAlert
            type="warning"
            message=""
            description="Оцените, насколько вы согласны с каждым из утверждений по шкале от «-2» до «+2»: где «–2» - абсолютно не согласен, «+2» - абсолютно согласен."
          />
          <BoldParagraph>
            Справа приведен список утверждений, касающихся вашего представления о том как должно
            работать ваше подразделение и организация в целом
          </BoldParagraph>
          <Divider />
          <LightParagraph>
            Вы можете прервать заполнение анкеты в любой момент и продолжить позднее, система
            запомнит, на каком вопросе вы остановились.
          </LightParagraph>
        </Col>
        <Col span={12}>
          <GoalSurvey company={company} />
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default Goal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGuest {
  filled_survey_id: string | null;
  department_id: number | null;
}

const department_id = localStorage.getItem("department_id");
const filled_survey_id = localStorage.getItem("filled_survey_id");

const initialState: IGuest = {
  filled_survey_id: filled_survey_id,
  department_id: department_id ? Number(department_id) : null,
};

const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    setFilledSurveyId(state, action: PayloadAction<string>) {
      return { ...state, filled_survey_id: action.payload };
    },
    setDepartmentId(state, action: PayloadAction<number | null>) {
      return { ...state, department_id: action.payload };
    },
  },
});

const { actions, reducer: guestReducer } = guestSlice;

export const { setFilledSurveyId, setDepartmentId } = actions;

export default guestReducer;

import { Button, message, Popconfirm, Space } from "antd";
import React, { useMemo } from "react";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { IQuestion, removeQuestion, updateQuestion } from "../store/questions/questions.reducer";
import { selectQuestions } from "../store/questions/questions.selector";
import { QuestionsAPI } from "../api/questions.api";
import { useTypedDispatch, useTypedSelector } from "store";

type QuestionHeaderProps = {
  question: IQuestion;
  isEditable: boolean;
  position: number;
  setIsEditable: (value: boolean) => void;
};

const QuestionHeader: React.FC<QuestionHeaderProps> = ({
  position,
  question,
  isEditable,
  setIsEditable,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const questions = useTypedSelector(selectQuestions);

  const questionTypes = useMemo(
    () => [
      { value: "text", label: t("questions.types.text") },
      { value: "scale", label: t("questions.types.scale") },
      { value: "radio", label: t("questions.types.radio") },
      { value: "multiple", label: t("questions.types.multiple") },
      { value: "distribution", label: t("questions.types.distribution") },
      { value: "html", label: t("questions.types.html") },
      { value: "department", label: t("questions.types.department") },
      { value: "comment", label: t("questions.types.comment") },
    ],
    [t]
  );

  const handleDelete = async (id: number) => {
    try {
      const isRemoved = await QuestionsAPI.remove(id);
      isRemoved && dispatch(removeQuestion(id));
    } catch (error) {
      message.error(t("app.wrong"));
    }
  };

  /**
   * Передвигаем вопрос выше по списку
   */
  const handleUpPosition = async () => {
    // Если вопрос только один, ничего не делаем
    if (questions.length < 2) return;
    // Ищем предыдущий по списку вопрос, заранее делаем инверсию массива
    const prevQuestion = questions
      .slice()
      .reverse()
      .find((q) => question.order_number > q.order_number);
    // Если предыдущего нет, значит текущий на верхней позиции
    if (!prevQuestion) return;
    // Меняем позиции у текущего и предыдущего вопроса
    QuestionsAPI.update(question, { order_number: prevQuestion.order_number }).then((isUpdated) => {
      isUpdated &&
        dispatch(updateQuestion({ ...question, order_number: prevQuestion.order_number }));
    });
    // Меняем позицию у предыдущего и текущего вопроса
    QuestionsAPI.update(prevQuestion, { order_number: question.order_number }).then((isUpdated) => {
      isUpdated &&
        dispatch(updateQuestion({ ...prevQuestion, order_number: question.order_number }));
    });
  };

  /**
   * Передвигаем вопрос ниже по списку
   */
  const handleDownPosition = () => {
    // Если вопрос только один, ничего не делаем
    if (questions.length < 2) return;
    // Ищем следующий по списку вопрос
    const nextQuestion = questions.find((q) => question.order_number < q.order_number);
    // Если следующего нет, значит текущий на верхней позиции
    if (!nextQuestion) return;
    // Меняем позиции у текущего и следующего вопроса
    QuestionsAPI.update(question, { order_number: nextQuestion.order_number }).then((isUpdated) => {
      isUpdated &&
        dispatch(updateQuestion({ ...question, order_number: nextQuestion.order_number }));
    });
    // Меняем позицию у следующего и текущего вопроса
    QuestionsAPI.update(nextQuestion, { order_number: question.order_number }).then((isUpdated) => {
      isUpdated &&
        dispatch(updateQuestion({ ...nextQuestion, order_number: question.order_number }));
    });
  };

  const questionTypeLabel = useMemo(() => {
    const type = questionTypes.find((q) => q.value === question.type);
    return type ? type.label : "";
  }, [question.type, questionTypes]);

  return (
    <div className="question__header">
      <div className="question__header-name">
        {position}. {questionTypeLabel}
      </div>
      <div className="question__header-buttons">
        <Space size="small">
          <Button
            type="text"
            icon={<ArrowUpOutlined />}
            onClick={() => handleUpPosition()}
            disabled={isEditable}
          />
          <Button
            type="text"
            icon={<ArrowDownOutlined />}
            onClick={() => handleDownPosition()}
            disabled={isEditable}
          />
          <Button
            onClick={() => setIsEditable(!isEditable)}
            type={isEditable ? "primary" : "text"}
            icon={<FormOutlined />}
          />
          <Popconfirm
            title={t("questions.delete")}
            onConfirm={() => handleDelete(question.id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button type="text" danger icon={<DeleteOutlined />} disabled={isEditable} />
          </Popconfirm>
        </Space>
      </div>
    </div>
  );
};

export default QuestionHeader;

import React from "react";
import { useTypedDispatch } from "store";
import { useDebounceEffect } from "ahooks";
import { QuestionWrapper, QuestionBody } from "./questions.styled";
import { IQuestionHtml, updateQuestion } from "../../store/questions/questions.reducer";

const HtmlType: React.FC<IQuestionHtml> = (question) => {
  const dispatch = useTypedDispatch();

  /**
   * Устанавливаем пустой дефолтный ответ на вопрос для типа html
   */
  useDebounceEffect(() => {
    question.answer !== "" && dispatch(updateQuestion({ ...question, answer: "" }));
  });

  return (
    <QuestionWrapper data-testid="html">
      <QuestionBody dangerouslySetInnerHTML={{ __html: question.parameters.html }} />
    </QuestionWrapper>
  );
};

export default HtmlType;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IndicatorsAPI } from "../../api/indicators.api";

export interface IIndicator {
  id: number;
  description: string;
  pole: string;
  axis: string;
  general_axis: string;
  high_text: string | null;
  high_value: number | null;
  low_text: string | null;
  low_value: number | null;
  middle_text: string | null;
  slice: string;
  macro_level: string;
  checked?: boolean;
}

/* 
axis: "X"
checked: true
description: "Источник целей сотрудников. Самостоятельно поставленные цели будут легче приниматься и разделяться, а заданные извне цели могут быть более четкими и измеримыми."
general_axis: "цели ставятся сотрудниками самостоятельно VS цели предзаданы извне"
high_text: "Команда, как правило, ставит себе задачи самостоятельно. Они легко принимаются и разделяются всеми членами, но могут быть недостаточно точны и измеримы.\n\n\n\n"
high_value: 3
id: 1
low_text: "Команда часто получает цели и задачи извне. Обычно они достаточно точны и измеримы, хотя не все сотрудники их разделяют.\n\n\n\n"
low_value: 2.2
macro_level: "Структура и функции"
middle_text: "Сотрудники могут как получать задачи от других структурных подразделений, так и ставить их самостоятельно. Это обеспечивает определенный баланс между четкостью и измеримостью критериев оценки и готовностью команды принять задания \"извне\" как свои. \n\n\n\n"
pole: "Цели ставятся сотрудниками самостоятельно"
slice: "Цели сотрудников"
*/

export const fetchIndicators = createAsyncThunk(
  "indicators/fetch",
  async () => {
    const indicators = await IndicatorsAPI.fetch();
    return indicators.map((item) => ({ ...item, checked: true }));
  }
);

const initialState: IIndicator[] = [];

const indicatorsSlice = createSlice({
  name: "indicators",
  initialState,
  reducers: {
    toggleIndicator: (state, action: PayloadAction<number>) => {
      return state.map((item) =>
        item.id === action.payload ? { ...item, checked: !item.checked } : item
      );
    },
    checkAllIndicators: (state, action: PayloadAction<boolean>) => {
      return state.map((item) => {
        return { ...item, checked: action.payload };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIndicators.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer: indicatorsReducer } = indicatorsSlice;

export const { toggleIndicator, checkAllIndicators } = actions;
export default indicatorsReducer;

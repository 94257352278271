import React from "react";

import { useTypedDispatch, useTypedSelector } from "store";
import { Circle, Group, Line } from "react-konva";
import { RootState } from "../../store";
import { IOcaiPlot, setSelectedGraph } from "../../store/ocai/ocai.reducer";

interface PlotProps extends IOcaiPlot {
  size?: number;
  offset?: number;
  count?: number;
  max?: number;
}

const Plot: React.FC<PlotProps> = ({ company_id, graph, size = 600, max = 40 }) => {
  const dispatch = useTypedDispatch();
  const selectedGraph = useTypedSelector((state: RootState) => state.ocai.selectedGraph);
  const color = "#ff8b00";
  const points: number[] = graph.reduce((acc: number[], item) => {
    switch (item.name) {
      case "Клан":
        acc.push((size / 2) * (1 - item.value / (max * 2)));
        acc.push((size / 2) * (1 - item.value / (max * 2)));
        break;
      case "Адхократия":
        acc.push((size / 2) * (1 + item.value / (max * 2)));
        acc.push((size / 2) * (1 - item.value / (max * 2)));
        break;
      case "Рынок":
        acc.push((size / 2) * (1 + item.value / (max * 2)));
        acc.push((size / 2) * (1 + item.value / (max * 2)));
        break;
      case "Иерархия":
        acc.push((size / 2) * (1 - item.value / (max * 2)));
        acc.push((size / 2) * (1 + item.value / (max * 2)));
        break;
      default:
        return acc;
    }

    return acc;
  }, []);

  /**
   * Вычисляем прозрачность у всех кроме выбранного
   */
  const hoverOpacity = () => {
    let opacity = 1;
    if (selectedGraph) {
      if (selectedGraph !== company_id) {
        opacity = 0.2;
      }
    }
    return opacity;
  };

  return (
    <Group opacity={hoverOpacity()}>
      <Circle radius={3} x={points[0]} y={points[1]} fill={color} />
      <Circle radius={3} x={points[2]} y={points[3]} fill={color} />
      <Circle radius={3} x={points[4]} y={points[5]} fill={color} />
      <Circle radius={3} x={points[6]} y={points[7]} fill={color} />
      <Line
        onMouseEnter={() => dispatch(setSelectedGraph(company_id))}
        onMouseLeave={() => dispatch(setSelectedGraph())}
        points={points.concat([points[0], points[1]])}
        stroke={color}
        strokeWidth={2}
      />
    </Group>
  );
};

export default Plot;

import { Modal, Menu } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useTypedSelector } from "store";
import { selectAuth } from "store/auth/auth.selector";

import { ModalShareSurveyLink } from "../modals/ModalShareSurveyLink";

type ResearchDropdownProps = {
  surveyId: string;
  onDelete: (id: string) => void;
  onChangeName: (id: string) => void;
};

const { confirm } = Modal;

const ResearchDropdown: React.FC<ResearchDropdownProps> = ({
  surveyId,
  onDelete,
  onChangeName,
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const { isLoggedIn, role, company_id } = useTypedSelector(selectAuth);

  return (
    <>
      <ModalShareSurveyLink
        isVisible={isVisibleModal}
        surveyId={surveyId}
        onCancel={() => setIsVisibleModal(false)}
      />
      <Menu>
        <Menu.Item key="changeName" onClick={() => onChangeName(surveyId)}>
          Изменить имя
        </Menu.Item>
        <Menu.Item key="share" onClick={() => setIsVisibleModal(true)}>
          Открыть доступ по ссылке
        </Menu.Item>
        <Menu.Item key="edit">
          {role === "hr" ? (
            <Link to={`/preview/${surveyId}`}>Посмотреть вопросы</Link>
          ) : (
            <Link to={`/template/${surveyId}`}>Редактировать вопросы</Link>
          )}
        </Menu.Item>
        <Menu.Item
          key="remove"
          onClick={() =>
            confirm({
              title: "Вы уверены, что хотите удалить это исследование?",
              okText: "Да",
              cancelText: "Нет",
              onOk: () => onDelete(surveyId),
            })
          }
        >
          Удалить исследование
        </Menu.Item>
      </Menu>
    </>
  );
};

export default ResearchDropdown;
